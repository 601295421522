@charset "UTF-8";
/*
  Project: Press Plus
  Author: Xfive
 */
/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease;
}

#baguetteBox-overlay.visible {
  opacity: 1;
}

#baguetteBox-overlay .full-image {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

#baguetteBox-overlay .full-image figure {
  display: inline;
  margin: 0;
  height: 100%;
}

#baguetteBox-overlay .full-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

#baguetteBox-overlay .full-image figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  white-space: normal;
  color: #ccc;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: sans-serif;
}

#baguetteBox-overlay .full-image:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease;
}

#baguetteBox-slider.bounce-from-right {
  animation: bounceFromRight .4s ease-out;
}

#baguetteBox-slider.bounce-from-left {
  animation: bounceFromLeft .4s ease-out;
}

@keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color .4s ease;
}

.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
}

.baguetteBox-button#next-button {
  right: 2%;
}

.baguetteBox-button#previous-button {
  left: 2%;
}

.baguetteBox-button#close-button {
  top: 20px;
  right: 2%;
  right: calc(2% + 6px);
  width: 30px;
  height: 30px;
}

.baguetteBox-button svg {
  position: absolute;
  left: 0;
  top: 0;
}

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 28px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 16px;
  height: 28px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.disabled,
.flatpickr-months .flatpickr-next-month.disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6.16px 0 0 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/*!
 * FullCalendar v3.10.0
 * Docs & License: https://fullcalendar.io/
 * (c) 2018 Adam Shaw
 */
.fc {
  direction: ltr;
  text-align: left;
}

.fc-rtl {
  text-align: right;
}

body .fc {
  /* extra precedence to overcome jqui */
  font-size: 1em;
}

/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-highlight {
  /* when user is selecting cells */
  background: #bce8f1;
  opacity: .3;
}

.fc-bgevent {
  /* default look for background events */
  background: #8fdf82;
  opacity: .3;
}

.fc-nonbusiness {
  /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7;
}

/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
.fc button {
  /* force height to include the border and padding */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* dimensions */
  margin: 0;
  height: 2.1em;
  padding: 0 .6em;
  /* text & cursor */
  font-size: 1em;
  /* normalize */
  white-space: nowrap;
  cursor: pointer;
}

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}

.fc-state-default {
  /* non-theme */
  border: 1px solid;
}

.fc-state-default.fc-corner-left {
  /* non-theme */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fc-state-default.fc-corner-right {
  /* non-theme */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* icons in buttons */
.fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -0.05em;
  /* seems to be a good adjustment across browsers */
  margin: 0 .2em;
  vertical-align: middle;
}

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
.fc-state-default {
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  color: #333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6;
}

.fc-state-hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}

.fc-state-down,
.fc-state-active {
  background-color: #cccccc;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  box-shadow: none;
}

/* Buttons Groups
--------------------------------------------------------------------------------------------------*/
.fc-button-group {
  display: inline-block;
}

/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
.fc .fc-button-group > * {
  /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 0 0 -1px;
}

.fc .fc-button-group > :first-child {
  /* same */
  margin-left: 0;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.fc-popover .fc-header {
  /* TODO: be more consistent with fc-head/fc-body */
  padding: 2px 4px;
}

.fc-popover .fc-header .fc-title {
  margin: 0 2px;
}

.fc-popover .fc-header .fc-close {
  cursor: pointer;
}

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left;
}

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}

/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
.fc-divider {
  border-style: solid;
  border-width: 1px;
}

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  /* height is unreliable across browsers, so use padding */
  border-width: 1px 0;
}

.fc-clear {
  clear: both;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fc-bg {
  bottom: 0;
  /* strech bg to bottom edge */
}

.fc-bg table {
  height: 100%;
  /* strech bg to bottom edge */
}

/* Tables
--------------------------------------------------------------------------------------------------*/
.fc table {
  width: 100%;
  box-sizing: border-box;
  /* fix scrollbar issue in firefox */
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  /* normalize cross-browser */
}

.fc th {
  text-align: center;
}

.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}

.fc td.fc-today {
  border-style: double;
  /* overcome neighboring borders */
}

/* Internal Nav Links
--------------------------------------------------------------------------------------------------*/
a[data-goto] {
  cursor: pointer;
}

a[data-goto]:hover {
  text-decoration: underline;
}

/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
.fc .fc-row {
  /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0;
}

.fc-row table {
  /* don't put left/right border on anything within a fake row.
     the outer tbody will worry about this */
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  /* no bottom borders on rows */
  border-bottom: 0 hidden transparent;
}

.fc-row:first-child table {
  border-top: 0 hidden transparent;
  /* no top border on first row */
}

/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
.fc-row {
  position: relative;
}

.fc-row .fc-bg {
  z-index: 1;
}

/* highlighting cells & background event skeleton */
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
  /* stretch skeleton to bottom of row */
}

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
  /* stretch skeleton to bottom of row */
}

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
  border-color: transparent;
}

.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}

.fc-row .fc-highlight-skeleton {
  z-index: 3;
}

/*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
  /* matches the space above the events */
}

.fc-row .fc-helper-skeleton {
  z-index: 5;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  /* see-through to the background below */
  /* extra precedence to prevent theme-provided backgrounds */
  background: none;
  /* in case <td>s are globally styled */
  border-color: transparent;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  /* don't put a border between events and/or the day number */
  border-bottom: 0;
}

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  /* don't put a border between event cells */
  border-top: 0;
}

/* Scrolling Container
--------------------------------------------------------------------------------------------------*/
.fc-scroller {
  -webkit-overflow-scrolling: touch;
}

/* TODO: move to agenda/basic */
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative;
  /* re-scope all positions */
  width: 100%;
  /* hack to force re-sizing this inner element when scrollbars appear/disappear */
}

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
  position: relative;
  /* for resize handle and other inner positioning */
  display: block;
  /* make the <a> tag block */
  font-size: .85em;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #3a87ad;
  /* default BORDER color */
}

.fc-event,
.fc-event-dot {
  background-color: #3a87ad;
  /* default BACKGROUND color */
}

.fc-event,
.fc-event:hover {
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
}

.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
  /* give events with links and draggable events a hand mouse pointer */
}

.fc-not-allowed,
.fc-not-allowed .fc-event {
  /* to override an event's custom cursor */
  cursor: not-allowed;
}

.fc-event .fc-bg {
  /* the generic .fc-bg already does position */
  z-index: 1;
  background: #fff;
  opacity: .25;
}

.fc-event .fc-content {
  position: relative;
  z-index: 2;
}

/* resizer (cursor AND touch devices) */
.fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
}

/* resizer (touch devices) */
.fc-event .fc-resizer {
  display: none;
}

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  /* only show when hovering or selected (with touch) */
  display: block;
}

/* hit area */
.fc-event.fc-selected .fc-resizer:before {
  /* 40x40 touch area */
  content: "";
  position: absolute;
  z-index: 9999;
  /* user of this util can scope within a lower z-index */
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}

/* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/
.fc-event.fc-selected {
  z-index: 9999 !important;
  /* overcomes inline z-index */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}

/* Horizontal Events
--------------------------------------------------------------------------------------------------*/
/* bigger touch area when selected */
.fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  /* below resizers */
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
}

/* events that are continuing to/from another week. kill rounded corners and butt up against edge */
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* resizer (cursor AND touch devices) */
/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px;
  /* overcome border */
}

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px;
  /* overcome border */
}

/* resizer (mouse devices) */
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  /* overcome top border */
  bottom: -1px;
  /* overcome bottom border */
}

/* resizer (touch devices) */
.fc-h-event.fc-selected .fc-resizer {
  /* 8x8 little dot */
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* vertically center */
  top: 50%;
  margin-top: -4px;
}

/* left resizer  */
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px;
  /* centers the 8x8 dot on the left edge */
}

/* right resizer */
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px;
  /* centers the 8x8 dot on the right edge */
}

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-day-grid-event {
  margin: 1px 2px 0;
  /* spacing between events and edges */
  padding: 0 1px;
}

tr:first-child > td > .fc-day-grid-event {
  margin-top: 2px;
  /* a little bit more space before the first event */
}

.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  /* same z-index as fc-bg, behind text */
  /* overcome the borders */
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  /* darkening effect */
  background: #000;
  opacity: .25;
}

.fc-day-grid-event .fc-content {
  /* force events to be one-line tall */
  white-space: nowrap;
  overflow: hidden;
}

.fc-day-grid-event .fc-time {
  font-weight: bold;
}

/* resizer (cursor devices) */
/* left resizer  */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px;
  /* to the day cell's edge */
}

/* right resizer */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px;
  /* to the day cell's edge */
}

/* Event Limiting
--------------------------------------------------------------------------------------------------*/
/* "more" link that represents hidden events */
a.fc-more {
  margin: 1px 3px;
  font-size: .85em;
  cursor: pointer;
  text-decoration: none;
}

a.fc-more:hover {
  text-decoration: underline;
}

.fc-limited {
  /* rows and cells that are hidden because of a "more" link */
  display: none;
}

/* popover that appears when "more" link is clicked */
.fc-day-grid .fc-row {
  z-index: 1;
  /* make the "more" popover one higher than this */
}

.fc-more-popover {
  z-index: 2;
  width: 220px;
}

.fc-more-popover .fc-event-container {
  padding: 10px;
}

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-now-indicator {
  position: absolute;
  border: 0 solid red;
}

/* Utilities
--------------------------------------------------------------------------------------------------*/
.fc-unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
TODO: more distinction between this file and common.css
*/
/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #ddd;
}

.fc-unthemed .fc-popover {
  background-color: #fff;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background: #eee;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666;
}

.fc-unthemed td.fc-today {
  background: #fcf8e3;
}

.fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: .3;
}

/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
.fc-icon {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  /* don't allow browser text-selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
Acceptable font-family overrides for individual icons:
  "Arial", sans-serif
  "Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
.fc-icon:after {
  position: relative;
}

.fc-icon-left-single-arrow:after {
  content: "\2039";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}

.fc-icon-right-single-arrow:after {
  content: "\203A";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}

.fc-icon-left-double-arrow:after {
  content: "\AB";
  font-size: 160%;
  top: -7%;
}

.fc-icon-right-double-arrow:after {
  content: "\BB";
  font-size: 160%;
  top: -7%;
}

.fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%;
}

.fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%;
}

.fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%;
}

.fc-icon-x:after {
  content: "\D7";
  font-size: 200%;
  top: 6%;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: .9em;
  margin-top: 2px;
}

/* List View
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-list-item:hover td {
  background-color: #f5f5f5;
}

/* Colors
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-disabled-day {
  background-image: none;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;
  /* where they meet, let the header have the border */
}

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-event {
  /* overpower jqui's styles on <a> tags. TODO: more DRY */
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
  /* undo ui-widget-header bold */
  font-weight: normal;
}

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.ui-widget td.fc-axis {
  font-weight: normal;
  /* overcome bold */
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats .ui-widget-content {
  background: none;
  /* see through to fc-bg */
}

.fc.fc-bootstrap3 a {
  text-decoration: none;
}

.fc.fc-bootstrap3 a[data-goto]:hover {
  text-decoration: underline;
}

.fc-bootstrap3 hr.fc-divider {
  border-color: inherit;
}

.fc-bootstrap3 .fc-today.alert {
  border-radius: 0;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-popover .panel-body {
  padding: 0;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none;
}

.fc.fc-bootstrap4 a {
  text-decoration: none;
}

.fc.fc-bootstrap4 a[data-goto]:hover {
  text-decoration: underline;
}

.fc-bootstrap4 hr.fc-divider {
  border-color: inherit;
}

.fc-bootstrap4 .fc-today.alert {
  border-radius: 0;
}

.fc-bootstrap4 a.fc-event:not([href]):not([tabindex]) {
  color: #fff;
}

.fc-bootstrap4 .fc-popover.card {
  position: absolute;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap4 .fc-popover .card-body {
  padding: 0;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap4 .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none;
}

/* Toolbar
--------------------------------------------------------------------------------------------------*/
.fc-toolbar {
  text-align: center;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em;
}

.fc-toolbar.fc-footer-toolbar {
  margin-top: 1em;
}

.fc-toolbar .fc-left {
  float: left;
}

.fc-toolbar .fc-right {
  float: right;
}

.fc-toolbar .fc-center {
  display: inline-block;
}

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * {
  /* extra precedence to override button border margins */
  float: left;
  margin-left: .75em;
}

/* the first thing within each left/center/right section */
.fc .fc-toolbar > * > :first-child {
  /* extra precedence to override button border margins */
  margin-left: 0;
}

/* title text */
.fc-toolbar h2 {
  margin: 0;
}

/* button layering (for border precedence) */
.fc-toolbar button {
  position: relative;
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}

.fc-toolbar .fc-state-down {
  z-index: 3;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}

.fc-toolbar button:focus {
  z-index: 5;
}

/* View Structure
--------------------------------------------------------------------------------------------------*/
/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.fc-view,
.fc-view > table {
  /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1;
}

/* BasicView
--------------------------------------------------------------------------------------------------*/
/* day row structure */
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  /* there may be week numbers in these views, so no padding-top */
  padding-bottom: 1em;
  /* ensure a space at bottom of cell for user selecting/clicking */
}

.fc-basic-view .fc-body .fc-row {
  min-height: 4em;
  /* ensure that all rows are at least this tall */
}

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
.fc-row.fc-rigid {
  overflow: hidden;
}

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

/* week and day number styling */
.fc-day-top.fc-other-month {
  opacity: 0.3;
}

.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 2px;
}

.fc-basic-view th.fc-week-number,
.fc-basic-view th.fc-day-number {
  padding: 0 2px;
  /* column headers can't have as much v space */
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right;
}

.fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
  float: left;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
  float: left;
  border-radius: 0 0 3px 0;
}

.fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
  float: right;
  border-radius: 0 0 0 3px;
}

.fc-basic-view .fc-day-top .fc-week-number {
  min-width: 1.5em;
  text-align: center;
  background-color: #f2f2f2;
  color: #808080;
}

/* when week/day number have own column */
.fc-basic-view td.fc-week-number {
  text-align: center;
}

.fc-basic-view td.fc-week-number > * {
  /* work around the way we do column resizing and ensure a minimum width */
  display: inline-block;
  min-width: 1.25em;
}

/* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
  /* so the "more.." popover will be over the time grid */
}

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
  /* all-day section will never get shorter than this */
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 1em;
  /* give space underneath events for clicking/selecting days */
}

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.fc .fc-axis {
  /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap;
}

.fc-ltr .fc-axis {
  text-align: right;
}

.fc-rtl .fc-axis {
  text-align: left;
}

/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
.fc-time-grid-container,
.fc-time-grid {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1;
}

.fc-time-grid {
  min-height: 100%;
  /* so if height setting is 'auto', .fc-bg stretches to fill height */
}

.fc-time-grid table {
  /* don't put outer borders on slats/bg/content/etc */
  border: 0 hidden transparent;
}

.fc-time-grid > .fc-bg {
  z-index: 1;
}

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  /* the <hr> AgendaView injects when grid is shorter than scroller */
  position: relative;
  z-index: 2;
}

.fc-time-grid .fc-content-col {
  position: relative;
  /* because now-indicator lives directly inside */
}

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}

/* divs within a cell within the fc-content-skeleton */
.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1;
}

.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2;
}

.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3;
}

.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4;
}

.fc-time-grid .fc-now-indicator-line {
  z-index: 5;
}

.fc-time-grid .fc-helper-container {
  /* also is fc-event-container */
  position: relative;
  z-index: 6;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0;
  /* each cell is responsible for its top border */
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}

/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-highlight-container {
  /* a div within a cell within the fc-highlight-skeleton */
  position: relative;
  /* scopes the left/right of the fc-highlight to be in the column */
}

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
  /* top and bottom will be in by JS */
}

/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
.fc-ltr .fc-time-grid .fc-event-container {
  /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 2px;
}

.fc-rtl .fc-time-grid .fc-event-container {
  /* space on the sides of events for RTL */
  margin: 0 2px 0 2.5%;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  /* scope inner z-index's */
}

.fc-time-grid .fc-bgevent {
  /* background events always span full width */
  left: 0;
  right: 0;
}

/* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
.fc-v-event.fc-not-start {
  /* events that are continuing from another day */
  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;
  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;
  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-time-grid-event {
  overflow: hidden;
  /* don't let the bg flow over rounded corners */
}

.fc-time-grid-event.fc-selected {
  /* need to allow touch resizers to extend outside event's bounding box */
  /* common fc-selected styles hide the fc-bg, so don't need this anyway */
  overflow: visible;
}

.fc-time-grid-event.fc-selected .fc-bg {
  display: none;
  /* hide semi-white background, to appear darker */
}

.fc-time-grid-event .fc-content {
  overflow: hidden;
  /* for when .fc-selected */
}

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px;
}

.fc-time-grid-event .fc-time {
  font-size: .85em;
  white-space: nowrap;
}

/* short mode, where time and title are on the same line */
.fc-time-grid-event.fc-short .fc-content {
  /* don't wrap to second line (now that contents will be inline) */
  white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  /* put the time and title on the same line */
  display: inline-block;
  vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time span {
  display: none;
  /* don't display the full time text... */
}

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
  /* ...instead, display only the start time */
}

.fc-time-grid-event.fc-short .fc-time:after {
  content: "\A0-\A0";
  /* seperate with a dash, wrapped in nbsp's */
}

.fc-time-grid-event.fc-short .fc-title {
  font-size: .85em;
  /* make the title text the same size as the time */
  padding: 0;
  /* undo padding from above */
}

/* resizer (cursor device) */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "=";
}

/* resizer (touch device) */
.fc-time-grid-event.fc-selected .fc-resizer {
  /* 10x10 dot */
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* horizontally center */
  left: 50%;
  margin-left: -5px;
  /* center on the bottom edge */
  bottom: -5px;
}

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0;
}

/* arrow on axis */
.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px;
  /* vertically center on top coordinate */
}

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  /* triangle pointing right... */
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  /* triangle pointing left... */
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

/* List View
--------------------------------------------------------------------------------------------------*/
/* possibly reusable */
.fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

/* view wrapper */
.fc-rtl .fc-list-view {
  direction: rtl;
  /* unlike core views, leverage browser RTL */
}

.fc-list-view {
  border-width: 1px;
  border-style: solid;
}

/* table resets */
.fc .fc-list-table {
  table-layout: auto;
  /* for shrinkwrapping cell content */
}

.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px;
}

.fc-list-table tr:first-child td {
  border-top-width: 0;
}

/* day headings with the list */
.fc-list-heading {
  border-bottom-width: 1px;
}

.fc-list-heading td {
  font-weight: bold;
}

.fc-ltr .fc-list-heading-main {
  float: left;
}

.fc-ltr .fc-list-heading-alt {
  float: right;
}

.fc-rtl .fc-list-heading-main {
  float: right;
}

.fc-rtl .fc-list-heading-alt {
  float: left;
}

/* event list items */
.fc-list-item.fc-has-url {
  cursor: pointer;
  /* whole row will be clickable */
}

.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px;
}

/* make the dot closer to the event title */
.fc-ltr .fc-list-item-marker {
  padding-right: 0;
}

.fc-rtl .fc-list-item-marker {
  padding-left: 0;
}

.fc-list-item-title a {
  /* every event title cell has an <a> tag */
  text-decoration: none;
  color: inherit;
}

.fc-list-item-title a[href]:hover {
  /* hover effect only on titles with hrefs */
  text-decoration: underline;
}

/* message when no events */
.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table;
}

.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.fc-unthemed .fc-list-empty {
  /* theme will provide own background */
  background-color: #eee;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/* 01. Global settings
   ========================================================================== */
/* 02. Breakpoints
   ========================================================================== */
/* 03. Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
html, body {
  overflow-x: hidden;
  /* Cut off in case something is out of the page */
}

.body__fixed-header {
  padding-top: 7.5rem;
}

@media (max-width: 64rem) {
  .body__fixed-header {
    padding-top: 6.25rem;
  }
}

.body__fixed-header.body__floating-header {
  padding-top: 0;
}

@media (max-width: 48.0625rem) {
  .body__fixed-header.body__floating-header {
    padding-top: 5rem;
  }
}

.body__relative-header {
  padding-top: 0;
}

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.25rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

b,
strong {
  font-weight: bold;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.table-sortable {
  /* stylelint-disable-line selector-no-qualifying-type */
}

.table-sortable .tablesorter-icon {
  background-size: cover !important;
  background-position: 50% 0;
  width: 10px !important;
}

.table-sortable .tablesorter-icon-default {
  /* stylelint-disable-line selector-no-qualifying-type */
  background-image: url("../assets/images/sort.svg");
}

.c-x5__table--header-white .tablesorter-icon {
  background-position: 50% 100%;
}

.table-sortable .tablesorter-icon-asc {
  /* stylelint-disable-line selector-no-qualifying-type */
  background-image: url("../assets/images/sort-asc.svg");
}

.table-sortable .tablesorter-icon-desc {
  /* stylelint-disable-line selector-no-qualifying-type */
  background-image: url("../assets/images/sort-desc.svg");
}

.cform__loader {
  background-image: url("../assets/images/loader.svg");
}

.c-footer__logo,
.c-header__logo {
  /* stylelint-disable declaration-block-no-duplicate-properties */
  /* stylelint-enable */
}

.c-footer__logo img,
.c-header__logo img {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  /* stylelint-disable-line property-no-vendor-prefix */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
  /* stylelint-disable-line property-no-vendor-prefix */
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #FORM ELEMENTS
   ========================================================================== */
select, textarea, input[type=text], input[type=password],
input[type=datetime], input[type=datetime-local],
input[type=date], input[type=month], input[type=time],
input[type=week], input[type=number], input[type=email],
input[type=url], input[type=tel] {
  -webkit-appearance: none;
  /* stylelint-disable-line property-no-vendor-prefix */
  -moz-appearance: none;
  /* stylelint-disable-line property-no-vendor-prefix */
  appearance: none;
  width: 100%;
  height: 4.375rem;
  margin-bottom: 0.3125rem;
  padding: 0.9375rem;
  border: 0.1875rem solid #255cdd;
  color: #255cdd;
  vertical-align: top;
  font-size: 1.125rem;
  font-weight: 600;
  background-color: #fff;
  box-shadow: none;
  transition: all 300ms ease-in-out;
}

@media (max-width: 85.4375rem) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url], input[type=tel] {
    height: 4.125rem;
  }
}

@media (max-width: 64rem) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url], input[type=tel] {
    height: 3.875rem;
  }
}

@media (max-width: 48.0625rem) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url], input[type=tel] {
    height: 3.125rem;
    padding: 0.3125rem 0.625rem;
    font-size: 1rem;
    border-width: 0.125rem;
  }
}

select::placeholder, textarea::placeholder, input[type=text]::placeholder, input[type=password]::placeholder,
input[type=datetime]::placeholder, input[type=datetime-local]::placeholder,
input[type=date]::placeholder, input[type=month]::placeholder, input[type=time]::placeholder,
input[type=week]::placeholder, input[type=number]::placeholder, input[type=email]::placeholder,
input[type=url]::placeholder, input[type=tel]::placeholder {
  color: #255cdd;
  transition: all 300ms ease-in-out;
}

select:hover, select:focus, textarea:hover, textarea:focus, input[type=text]:hover, input[type=text]:focus, input[type=password]:hover, input[type=password]:focus,
input[type=datetime]:hover,
input[type=datetime]:focus, input[type=datetime-local]:hover, input[type=datetime-local]:focus,
input[type=date]:hover,
input[type=date]:focus, input[type=month]:hover, input[type=month]:focus, input[type=time]:hover, input[type=time]:focus,
input[type=week]:hover,
input[type=week]:focus, input[type=number]:hover, input[type=number]:focus, input[type=email]:hover, input[type=email]:focus,
input[type=url]:hover,
input[type=url]:focus, input[type=tel]:hover, input[type=tel]:focus {
  border: 0.1875rem solid #18409d;
}

@media (max-width: 48.0625rem) {
  select:hover, select:focus, textarea:hover, textarea:focus, input[type=text]:hover, input[type=text]:focus, input[type=password]:hover, input[type=password]:focus,
  input[type=datetime]:hover,
  input[type=datetime]:focus, input[type=datetime-local]:hover, input[type=datetime-local]:focus,
  input[type=date]:hover,
  input[type=date]:focus, input[type=month]:hover, input[type=month]:focus, input[type=time]:hover, input[type=time]:focus,
  input[type=week]:hover,
  input[type=week]:focus, input[type=number]:hover, input[type=number]:focus, input[type=email]:hover, input[type=email]:focus,
  input[type=url]:hover,
  input[type=url]:focus, input[type=tel]:hover, input[type=tel]:focus {
    border-width: 0.125rem;
  }
}

[type="checkbox"],
[type="radio"] {
  margin-right: 0.9375rem;
}

select {
  opacity: 0;
}

textarea {
  height: auto;
}

label {
  display: block;
  color: #255cdd;
  line-height: 1.6em;
}

/* Chrome yellow fields fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: 0 0 0 1000px #fff inset !important;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: #454545;
  line-height: 1.1;
  margin: 0.625rem 0;
}

.c-block__header h1, .c-block__header h2, .c-block__header h3, .c-block__header h4, .c-block__header h5, .c-block__header h6 {
  color: #255cdd;
}

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
  font-weight: 900;
}

/* stylelint-disable selector-no-qualifying-type */
h1, .h1 {
  font-size: 4.25rem;
}

h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
  font-size: 5.5rem;
}

h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
  font-size: 3rem;
}

h2, .h2 {
  font-size: 3rem;
}

h2 .u-text--enlarge, h2.u-text--enlarge, .h2 .u-text--enlarge, .h2.u-text--enlarge {
  font-size: 3.875rem;
}

h2 .u-text--ensmall, h2.u-text--ensmall, .h2 .u-text--ensmall, .h2.u-text--ensmall {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h3 .u-text--enlarge, h3.u-text--enlarge, .h3 .u-text--enlarge, .h3.u-text--enlarge {
  font-size: 1.875rem;
}

h3 .u-text--ensmall, h3.u-text--ensmall, .h3 .u-text--ensmall, .h3.u-text--ensmall {
  font-size: 1.0625rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h4 .u-text--enlarge, h4.u-text--enlarge, .h4 .u-text--enlarge, .h4.u-text--enlarge {
  font-size: 1.625rem;
}

h4 .u-text--ensmall, h4.u-text--ensmall, .h4 .u-text--ensmall, .h4.u-text--ensmall {
  font-size: 0.9375rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h5 .u-text--enlarge, h5.u-text--enlarge, .h5 .u-text--enlarge, .h5.u-text--enlarge {
  font-size: 1.375rem;
}

h5 .u-text--ensmall, h5.u-text--ensmall, .h5 .u-text--ensmall, .h5.u-text--ensmall {
  font-size: 0.875rem;
}

h6, .h6 {
  font-size: 1rem;
}

h6 .u-text--enlarge, h6.u-text--enlarge, .h6 .u-text--enlarge, .h6.u-text--enlarge {
  font-size: 1.3125rem;
}

h6 .u-text--ensmall, h6.u-text--ensmall, .h6 .u-text--ensmall, .h6.u-text--ensmall {
  font-size: 0.8125rem;
}

@media (max-width: 105.0625rem) {
  h1, .h1 {
    font-size: 3.625rem;
  }
  h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
    font-size: 4.75rem;
  }
  h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
    font-size: 2.625rem;
  }
  h2, .h2 {
    font-size: 2.625rem;
  }
  h2 .u-text--enlarge, h2.u-text--enlarge, .h2 .u-text--enlarge, .h2.u-text--enlarge {
    font-size: 3.375rem;
  }
  h2 .u-text--ensmall, h2.u-text--ensmall, .h2 .u-text--ensmall, .h2.u-text--ensmall {
    font-size: 1.75rem;
  }
  h3, .h3 {
    font-size: 1.3125rem;
  }
  h3 .u-text--enlarge, h3.u-text--enlarge, .h3 .u-text--enlarge, .h3.u-text--enlarge {
    font-size: 1.6875rem;
  }
  h3 .u-text--ensmall, h3.u-text--ensmall, .h3 .u-text--ensmall, .h3.u-text--ensmall {
    font-size: 1rem;
  }
  h4, .h4 {
    font-size: 1.125rem;
  }
  h4 .u-text--enlarge, h4.u-text--enlarge, .h4 .u-text--enlarge, .h4.u-text--enlarge {
    font-size: 1.375rem;
  }
  h4 .u-text--ensmall, h4.u-text--ensmall, .h4 .u-text--ensmall, .h4.u-text--ensmall {
    font-size: 0.9375rem;
  }
  h5, .h5 {
    font-size: 1rem;
  }
  h5 .u-text--enlarge, h5.u-text--enlarge, .h5 .u-text--enlarge, .h5.u-text--enlarge {
    font-size: 1.25rem;
  }
  h5 .u-text--ensmall, h5.u-text--ensmall, .h5 .u-text--ensmall, .h5.u-text--ensmall {
    font-size: 0.875rem;
  }
  h6, .h6 {
    font-size: 0.9375rem;
  }
  h6 .u-text--enlarge, h6.u-text--enlarge, .h6 .u-text--enlarge, .h6.u-text--enlarge {
    font-size: 1.125rem;
  }
  h6 .u-text--ensmall, h6.u-text--ensmall, .h6 .u-text--ensmall, .h6.u-text--ensmall {
    font-size: 0.8125rem;
  }
}

@media (max-width: 85.4375rem) {
  h1, .h1 {
    font-size: 3.125rem;
  }
  h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
    font-size: 4.0625rem;
  }
  h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
    font-size: 2.25rem;
  }
  h2, .h2 {
    font-size: 2.25rem;
  }
  h2 .u-text--enlarge, h2.u-text--enlarge, .h2 .u-text--enlarge, .h2.u-text--enlarge {
    font-size: 2.875rem;
  }
  h2 .u-text--ensmall, h2.u-text--ensmall, .h2 .u-text--ensmall, .h2.u-text--ensmall {
    font-size: 1.5rem;
  }
  h3, .h3 {
    font-size: 1.1875rem;
  }
  h3 .u-text--enlarge, h3.u-text--enlarge, .h3 .u-text--enlarge, .h3.u-text--enlarge {
    font-size: 1.4375rem;
  }
  h3 .u-text--ensmall, h3.u-text--ensmall, .h3 .u-text--ensmall, .h3.u-text--ensmall {
    font-size: 0.9375rem;
  }
}

@media (max-width: 80rem) {
  h1, .h1 {
    font-size: 2.5625rem;
  }
  h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
    font-size: 3.375rem;
  }
  h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
    font-size: 1.8125rem;
  }
  h2, .h2 {
    font-size: 1.75rem;
  }
  h2 .u-text--enlarge, h2.u-text--enlarge, .h2 .u-text--enlarge, .h2.u-text--enlarge {
    font-size: 2.375rem;
  }
  h2 .u-text--ensmall, h2.u-text--ensmall, .h2 .u-text--ensmall, .h2.u-text--ensmall {
    font-size: 1.25rem;
  }
}

@media (max-width: 64rem) {
  h1, .h1 {
    font-size: 2rem;
  }
  h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
    font-size: 2.625rem;
  }
  h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
    font-size: 1.4375rem;
  }
  h2, .h2 {
    font-size: 1.375rem;
  }
  h2 .u-text--enlarge, h2.u-text--enlarge, .h2 .u-text--enlarge, .h2.u-text--enlarge {
    font-size: 1.8125rem;
  }
  h2 .u-text--ensmall, h2.u-text--ensmall, .h2 .u-text--ensmall, .h2.u-text--ensmall {
    font-size: 1.125rem;
  }
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
  border: 0.0625rem solid #dddddd;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #000;
  font-size: 1rem;
  /* [1] */
  line-height: 1.35;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  vertical-align: middle;
  /* [3] */
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
}

.c-block__map img,
.js-google-map img {
  max-width: none;
  max-height: none;
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  /* [1] */
  max-width: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

img[data-src],
img[data-srcset] {
  display: block;
  min-height: 1px;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #255cdd;
  text-decoration: underline;
  word-break: break-word;
}

a:hover {
  color: #517de4;
  text-decoration: none;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
ul,
ol {
  margin-left: 1.875rem;
}

li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

li:last-child {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.video-wrap {
  opacity: 0;
}

.video-wrap.video-wrap-50 {
  width: 50%;
}

.video-wrap.video-wrap-50 video {
  height: auto;
}

.video-wrap.alignright {
  padding-left: 2.5rem;
}

.video-wrap.alignleft {
  padding-right: 2.5rem;
}

.mejs-container {
  background-color: transparent !important;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table:not(.c-events-calendar__table):not(.ui-datepicker-calendar) {
  width: 100% !important;
  /* [1] */
}

table:not(.c-events-calendar__table):not(.ui-datepicker-calendar) td, table:not(.c-events-calendar__table):not(.ui-datepicker-calendar) th {
  padding: 0.3125rem 0.625rem;
}

table:not(.c-events-calendar__table):not(.ui-datepicker-calendar) td {
  font-size: 1rem;
}

table:not(.c-events-calendar__table):not(.ui-datepicker-calendar) p {
  margin: 0;
}

.c-x5__table {
  display: block;
  width: 100% !important;
  /* [1] */
  height: auto !important;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
  border: none;
  background-color: #fff;
  border-collapse: separate !important;
  border-spacing: 0;
}

.c-x5__table--shadow {
  box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.3);
}

.c-x5__table--bordered {
  border: solid 0.0625rem;
}

.c-x5__table--border-white {
  border: none;
}

.c-x5__table--border-grey,
.c-x5__table--header-bordered.c-x5__table--border-grey th,
.c-x5__table-rows--bordered.c-x5__table--border-grey td,
.c-x5__table-cols--bordered.c-x5__table--border-grey th,
.c-x5__table-cols--bordered.c-x5__table--border-grey td,
.c-x5__table-rows--striped-bordered.c-x5__table--border-grey td,
.c-x5__table-rows--striped-bordered-reverse.c-x5__table--border-grey td,
.c-x5__table-cols--striped-bordered.c-x5__table--border-grey th,
.c-x5__table-cols--striped-bordered.c-x5__table--border-grey td,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-grey th,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-grey td {
  border-color: #454545;
}

.c-x5__table--border-black,
.c-x5__table--header-bordered.c-x5__table--border-black th,
.c-x5__table-rows--bordered.c-x5__table--border-black td,
.c-x5__table-cols--bordered.c-x5__table--border-black th,
.c-x5__table-cols--bordered.c-x5__table--border-black td,
.c-x5__table-rows--striped-bordered.c-x5__table--border-black td,
.c-x5__table-rows--striped-bordered-reverse.c-x5__table--border-black td,
.c-x5__table-cols--striped-bordered.c-x5__table--border-black th,
.c-x5__table-cols--striped-bordered.c-x5__table--border-black td,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-black th,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-black td {
  border-color: #000;
}

.c-x5__table.table-sortable {
  /* stylelint-disable-line selector-no-qualifying-type */
}

.c-x5__table.table-sortable th {
  cursor: pointer;
}

.c-x5__table.table-sortable .tablesorter-header-inner p {
  display: inline-block;
}

.c-x5__table.table-sortable .tablesorter-icon {
  /* stylelint-disable-line selector-no-qualifying-type */
  position: relative;
  top: 0.1875rem;
  margin-left: 0.3125rem;
  display: inline-block;
  font-size: 12px;
  width: 11px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
}

.c-x5__table thead,
.c-x5__table tbody,
.c-x5__table tr,
.c-x5__table td,
.c-x5__table th {
  display: block;
  height: auto !important;
  width: auto !important;
}

.c-x5__table thead {
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: -9;
  background-color: #000;
  color: #fff;
}

.c-x5__table--header-grey thead {
  background-color: #aaaaaa;
}

.c-x5__table--header-white thead {
  background-color: #fff;
  color: #000;
}

.c-x5__table th, .c-x5__table td {
  padding: 0.625rem;
  min-width: 5rem;
  text-align: left;
  vertical-align: top;
  border-color: inherit;
}

@media (max-width: 64rem) {
  .c-x5__table th, .c-x5__table td {
    padding: 0.3125rem;
  }
}

.c-x5__table th {
  padding: 1.25rem 0.625rem;
  font-weight: bold;
}

.c-x5__table--header-bordered th {
  border-bottom: solid 0.0625rem;
}

.c-x5__table td {
  padding: 1.25rem 0.625rem;
  font-size: 1rem;
}

.c-x5__table td:before {
  display: table;
  float: left;
  width: 100px;
  min-height: 1px;
  content: attr(data-col-label);
  font-weight: 600;
}

.c-x5__table td:after {
  content: '';
  display: table;
  clear: both;
}

.c-x5__table td .c-td__inner {
  overflow: hidden;
  line-height: 1.2em;
}

.c-x5__table-rows--bordered td,
.c-x5__table-rows--striped-bordered td,
.c-x5__table-rows--striped-bordered-reverse td {
  border-bottom: solid 0.0625rem;
}

@media (min-width: 48.0625rem) {
  .c-x5__table-rows--bordered tr:last-child td,
  .c-x5__table-rows--striped-bordered tr:last-child td,
  .c-x5__table-rows--striped-bordered-reverse tr:last-child td {
    border-bottom: none;
  }
}

@media (max-width: 48.0625rem) {
  .c-x5__table-rows--bordered tr:last-child td:last-child,
  .c-x5__table-rows--striped-bordered tr:last-child td:last-child,
  .c-x5__table-rows--striped-bordered-reverse tr:last-child td:last-child {
    border-bottom: none;
  }
}

.c-x5__table-cols--bordered th,
.c-x5__table-cols--bordered td,
.c-x5__table-cols--striped-bordered th,
.c-x5__table-cols--striped-bordered td,
.c-x5__table-cols--striped-bordered-reverse th,
.c-x5__table-cols--striped-bordered-reverse td {
  border-right: solid 0.0625rem;
}

.c-x5__table-cols--bordered th:last-child,
.c-x5__table-cols--bordered td:last-child,
.c-x5__table-cols--striped-bordered th:last-child,
.c-x5__table-cols--striped-bordered td:last-child,
.c-x5__table-cols--striped-bordered-reverse th:last-child,
.c-x5__table-cols--striped-bordered-reverse td:last-child {
  border-right: none;
}

.c-x5__table-rows--striped tbody tr:nth-child(odd) td,
.c-x5__table-rows--striped-bordered tbody tr:nth-child(odd) td,
.c-x5__table-rows--striped-reverse tbody tr:nth-child(even) td,
.c-x5__table-rows--striped-bordered-reverse tbody tr:nth-child(even) td {
  background-color: #eeeeee;
}

.c-x5__table-cols--striped th:nth-child(odd),
.c-x5__table-cols--striped td:nth-child(odd),
.c-x5__table-cols--striped-reverse th:nth-child(even),
.c-x5__table-cols--striped-reverse td:nth-child(even),
.c-x5__table-cols--striped-bordered th:nth-child(odd),
.c-x5__table-cols--striped-bordered td:nth-child(odd),
.c-x5__table-cols--striped-bordered-reverse th:nth-child(even),
.c-x5__table-cols--striped-bordered-reverse td:nth-child(even) {
  background-color: #eeeeee;
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-5 th:first-child,
  .c-x5__table--first-col-5 td:first-child {
    width: 5% !important;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-10 th:first-child,
  .c-x5__table--first-col-10 td:first-child {
    width: 10% !important;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-15 th:first-child,
  .c-x5__table--first-col-15 td:first-child {
    width: 15% !important;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-20 th:first-child,
  .c-x5__table--first-col-20 td:first-child {
    width: 20% !important;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-25 th:first-child,
  .c-x5__table--first-col-25 td:first-child {
    width: 25% !important;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-30 th:first-child,
  .c-x5__table--first-col-30 td:first-child {
    width: 30% !important;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-35 th:first-child,
  .c-x5__table--first-col-35 td:first-child {
    width: 35% !important;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-40 th:first-child,
  .c-x5__table--first-col-40 td:first-child {
    width: 40% !important;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-45 th:first-child,
  .c-x5__table--first-col-45 td:first-child {
    width: 45% !important;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--first-col-50 th:first-child,
  .c-x5__table--first-col-50 td:first-child {
    width: 50% !important;
  }
}

.c-x5__table--row-small td,
.c-x5__table--header-small th {
  padding: 0.625rem 0.625rem;
}

.c-x5__table--row-medium td,
.c-x5__table--header-medium th {
  padding: 0.9375rem 0.625rem;
}

.c-x5__table--row-large td,
.c-x5__table--header-large th {
  padding: 1.5625rem 0.625rem;
}

.c-x5__table--border-grey td,
.c-x5__table--border-grey th {
  border-color: #454545;
}

@media (min-width: 48.0625rem) {
  .c-x5__table--center td {
    text-align: center;
  }
}

@media (min-width: 48.0625rem) {
  .c-x5__table--center-header th {
    text-align: center;
  }
}

.c-x5__table--options-col td:first-child {
  font-weight: bold;
  text-align: left;
}

.c-x5__table.table--no-header td:before {
  content: '';
  width: 0;
}

@media (min-width: 48.0625rem) {
  .c-x5__table {
    display: table;
  }
  .c-x5__table thead {
    position: relative;
    display: table-header-group;
    top: auto;
    left: auto;
    z-index: 1;
  }
  .c-x5__table tbody {
    display: table-row-group;
  }
  .c-x5__table tr {
    display: table-row;
    border-bottom: none;
  }
  .c-x5__table th, .c-x5__table td {
    display: table-cell;
  }
  .c-x5__table th:before,
  .c-x5__table td:before {
    /* stylelint-disable-line max-nesting-depth */
    content: '';
    display: none;
  }
  .c-x5__table--striped tbody tr:nth-child(even),
  .c-x5__table--striped-reverse tbody tr:nth-child(odd),
  .c-x5__table--striped-cols td:nth-child(even),
  .c-x5__table--striped-cols.c-x5__table--header-white th:nth-child(even),
  .c-x5__table--striped-cols-reverse td:nth-child(odd),
  .c-x5__table--striped-cols-reverse.c-x5__table--header-white th:nth-child(odd) {
    background-color: #eeeeee;
  }
  .c-x5__table .c-td__inner {
    overflow: auto;
  }
}

table:not(.c-x5__table):not(.c-calendar__table):not(.c-events-calendar__table):not(.ui-datepicker-calendar),
.c-x5__table--editor {
  border: solid 1px #000;
  border-right: none;
  border-bottom: none;
}

table:not(.c-x5__table):not(.c-calendar__table):not(.c-events-calendar__table):not(.ui-datepicker-calendar) td, table:not(.c-x5__table):not(.c-calendar__table):not(.c-events-calendar__table):not(.ui-datepicker-calendar) th,
.c-x5__table--editor td,
.c-x5__table--editor th {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
.o-layout {
  max-width: 105rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.o-layout .o-layout {
  margin-right: -0.78125rem;
  margin-left: -0.78125rem;
}

@media print, screen and (min-width: 48.0625em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

@media print, screen and (min-width: 64em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

@media print, screen and (min-width: 72em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

@media screen and (min-width: 85.4375em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

.o-layout .o-layout.collapse {
  margin-right: 0;
  margin-left: 0;
}

.o-layout:not(.is-expanded) .o-layout {
  max-width: none;
}

.c-wrapper > .o-layout {
  padding-top: 0.9375rem;
}

.o-layout--center-vertically {
  align-items: center;
}

.o-layout--align-right {
  justify-content: flex-end;
}

.o-layout__item {
  flex: 1 1 0px;
  padding-right: 0.78125rem;
  padding-left: 0.78125rem;
  min-width: 0;
}

.o-layout__item.o-layout {
  float: none;
  display: block;
}

.o-layout .o-layout__item.o-layout {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.o-layout__item--small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.o-layout__item--small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.o-layout__item--small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.o-layout__item--small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.o-layout__item--small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.o-layout__item--small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.o-layout__item--small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.o-layout__item--small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.o-layout__item--small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.o-layout__item--small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.o-layout__item--small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.o-layout__item--small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.o-layout__item--small-20 {
  flex: 20%;
  max-width: 20%;
}

@media print, screen and (min-width: 48.0625em) {
  .o-layout__item--medium-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .o-layout__item--medium-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .o-layout__item--medium-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .o-layout__item--medium-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .o-layout__item--medium-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .o-layout__item--medium-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .o-layout__item--medium-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .o-layout__item--medium-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .o-layout__item--medium-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .o-layout__item--medium-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .o-layout__item--medium-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .o-layout__item--medium-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .o-layout__item--medium-sm-20 {
    flex: 20%;
    max-width: 20%;
  }
}

@media print, screen and (min-width: 64em) {
  .o-layout__item--medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .o-layout__item--medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .o-layout__item--medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .o-layout__item--medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .o-layout__item--medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .o-layout__item--medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .o-layout__item--medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .o-layout__item--medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .o-layout__item--medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .o-layout__item--medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .o-layout__item--medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .o-layout__item--medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .o-layout__item--medium-20 {
    flex: 20%;
    max-width: 20%;
  }
}

@media print, screen and (min-width: 72em) {
  .o-layout__item--large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .o-layout__item--large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .o-layout__item--large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .o-layout__item--large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .o-layout__item--large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .o-layout__item--large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .o-layout__item--large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .o-layout__item--large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .o-layout__item--large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .o-layout__item--large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .o-layout__item--large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .o-layout__item--large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .o-layout__item--large-20 {
    flex: 20%;
    max-width: 20%;
  }
}

@media screen and (min-width: 85.4375em) {
  .o-layout__item--xlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .o-layout__item--xlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .o-layout__item--xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .o-layout__item--xlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .o-layout__item--xlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .o-layout__item--xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .o-layout__item--xlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .o-layout__item--xlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .o-layout__item--xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .o-layout__item--xlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .o-layout__item--xlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .o-layout__item--xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .o-layout__item--xlarge-20 {
    flex: 20%;
    max-width: 20%;
  }
}

.o-layout__item--full {
  width: 100%;
  flex-basis: 100%;
}

.o-layout__item--grow {
  flex: 1 0 auto;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media::before, .o-media::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.o-media::after {
  clear: both;
}

.o-media__img {
  float: left;
  margin-right: 0.9375rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 0.9375rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 105rem;
  position: relative;
}

.o-wrapper::before, .o-wrapper::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.o-wrapper::after {
  clear: both;
}

/* ==========================================================================
   #ADD TO CALENDAR
   custom styles for: http://addtocalendar.com/
   using custom naming for classes in order to sync with plugin
   ========================================================================== */
.addtocalendar {
  position: relative;
  display: block;
  max-width: 21.875rem;
  width: 100%;
  padding-left: 1.25rem;
}

.addtocalendar:hover .atcb-link {
  color: #006ce5;
}

.atcb-list {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 1.25rem;
  right: 0;
  z-index: 900;
  list-style: none;
  margin: 0;
  padding: 0.625rem 0.625rem 0.5rem;
  background-color: #006ce5;
  border-radius: 0 0 0.1875rem 0.1875rem;
  box-shadow: 0 0.3125rem 1.25rem #bfbfbf;
  text-align: left;
}

.atcb-list::before {
  content: '';
  width: 0.625rem;
  height: 0.625rem;
  background-color: #fff;
  position: absolute;
  top: -0.375rem;
  right: 0.625rem;
  transform: rotate(45deg);
}

.atcb-link {
  color: #fff;
  text-decoration: none;
  border-radius: 0.1875rem;
  border: 1px solid #006ce5;
  display: block;
  padding: 0.5rem 0.625rem 0.5rem 2.5rem;
  cursor: pointer;
  background: #006ce5 url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNzkyIDE3OTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE5MiAxNjY0aDI4OHYtMjg4aC0yODh2Mjg4em0zNTIgMGgzMjB2LTI4OGgtMzIwdjI4OHptLTM1Mi0zNTJoMjg4di0zMjBoLTI4OHYzMjB6bTM1MiAwaDMyMHYtMzIwaC0zMjB2MzIwem0tMzUyLTM4NGgyODh2LTI4OGgtMjg4djI4OHptNzM2IDczNmgzMjB2LTI4OGgtMzIwdjI4OHptLTM4NC03MzZoMzIwdi0yODhoLTMyMHYyODh6bTc2OCA3MzZoMjg4di0yODhoLTI4OHYyODh6bS0zODQtMzUyaDMyMHYtMzIwaC0zMjB2MzIwem0tMzUyLTg2NHYtMjg4cTAtMTMtOS41LTIyLjV0LTIyLjUtOS41aC02NHEtMTMgMC0yMi41IDkuNXQtOS41IDIyLjV2Mjg4cTAgMTMgOS41IDIyLjV0MjIuNSA5LjVoNjRxMTMgMCAyMi41LTkuNXQ5LjUtMjIuNXptNzM2IDg2NGgyODh2LTMyMGgtMjg4djMyMHptLTM4NC0zODRoMzIwdi0yODhoLTMyMHYyODh6bTM4NCAwaDI4OHYtMjg4aC0yODh2Mjg4em0zMi00ODB2LTI4OHEwLTEzLTkuNS0yMi41dC0yMi41LTkuNWgtNjRxLTEzIDAtMjIuNSA5LjV0LTkuNSAyMi41djI4OHEwIDEzIDkuNSAyMi41dDIyLjUgOS41aDY0cTEzIDAgMjIuNS05LjV0OS41LTIyLjV6bTM4NC02NHYxMjgwcTAgNTItMzggOTB0LTkwIDM4aC0xNDA4cS01MiAwLTkwLTM4dC0zOC05MHYtMTI4MHEwLTUyIDM4LTkwdDkwLTM4aDEyOHYtOTZxMC02NiA0Ny0xMTN0MTEzLTQ3aDY0cTY2IDAgMTEzIDQ3dDQ3IDExM3Y5NmgzODR2LTk2cTAtNjYgNDctMTEzdDExMy00N2g2NHE2NiAwIDExMyA0N3Q0NyAxMTN2OTZoMTI4cTUyIDAgOTAgMzh0MzggOTB6IiBmaWxsPSIjZmZmIi8+PC9zdmc+") no-repeat 10px center;
}

.atcb-link:focus, .atcb-link:hover {
  color: #006ce5;
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNzkyIDE3OTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE5MiAxNjY0aDI4OHYtMjg4aC0yODh2Mjg4em0zNTIgMGgzMjB2LTI4OGgtMzIwdjI4OHptLTM1Mi0zNTJoMjg4di0zMjBoLTI4OHYzMjB6bTM1MiAwaDMyMHYtMzIwaC0zMjB2MzIwem0tMzUyLTM4NGgyODh2LTI4OGgtMjg4djI4OHptNzM2IDczNmgzMjB2LTI4OGgtMzIwdjI4OHptLTM4NC03MzZoMzIwdi0yODhoLTMyMHYyODh6bTc2OCA3MzZoMjg4di0yODhoLTI4OHYyODh6bS0zODQtMzUyaDMyMHYtMzIwaC0zMjB2MzIwem0tMzUyLTg2NHYtMjg4cTAtMTMtOS41LTIyLjV0LTIyLjUtOS41aC02NHEtMTMgMC0yMi41IDkuNXQtOS41IDIyLjV2Mjg4cTAgMTMgOS41IDIyLjV0MjIuNSA5LjVoNjRxMTMgMCAyMi41LTkuNXQ5LjUtMjIuNXptNzM2IDg2NGgyODh2LTMyMGgtMjg4djMyMHptLTM4NC0zODRoMzIwdi0yODhoLTMyMHYyODh6bTM4NCAwaDI4OHYtMjg4aC0yODh2Mjg4em0zMi00ODB2LTI4OHEwLTEzLTkuNS0yMi41dC0yMi41LTkuNWgtNjRxLTEzIDAtMjIuNSA5LjV0LTkuNSAyMi41djI4OHEwIDEzIDkuNSAyMi41dDIyLjUgOS41aDY0cTEzIDAgMjIuNS05LjV0OS41LTIyLjV6bTM4NC02NHYxMjgwcTAgNTItMzggOTB0LTkwIDM4aC0xNDA4cS01MiAwLTkwLTM4dC0zOC05MHYtMTI4MHEwLTUyIDM4LTkwdDkwLTM4aDEyOHYtOTZxMC02NiA0Ny0xMTN0MTEzLTQ3aDY0cTY2IDAgMTEzIDQ3dDQ3IDExM3Y5NmgzODR2LTk2cTAtNjYgNDctMTEzdDExMy00N2g2NHE2NiAwIDExMyA0N3Q0NyAxMTN2OTZoMTI4cTUyIDAgOTAgMzh0MzggOTB6IiBmaWxsPSIjMDA2Y2U1Ii8+PC9zdmc+");
}

.atcb-link:focus {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.atcb-link:focus ~ ul,
.atcb-link:active ~ ul,
.atcb-list:hover {
  visibility: visible;
}

.atcb-item-link {
  color: #fff;
  display: block;
}

.atcb-item-link:hover {
  color: #fff;
  text-decoration: none;
}

/* ==========================================================================
   #ALERT
   ========================================================================== */
.c-alert {
  padding: 0.625rem;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
  border-right: 1px solid;
  border-left: 5px solid;
}

.c-alert--error {
  border-left-color: #cc0000;
  border-right-color: #cc0000;
  background-color: #ff9999;
  color: maroon;
}

.c-alert--info {
  border-left-color: #2b80cb;
  border-right-color: #2b80cb;
  background-color: #cde2f5;
  color: #1e588c;
}

.c-book {
  padding-top: 0.625rem;
  padding-bottom: 1.25rem;
}

.c-book a {
  text-decoration: none;
  color: inherit;
  transition: all 300ms ease-in-out;
}

.c-book a:hover {
  color: #255cdd;
}

.c-book__inner {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0.3125rem;
  border: 1px solid #eeeeee;
}

@media (max-width: 27.5rem) {
  .c-book {
    text-align: center;
  }
  .c-book__inner {
    max-width: 21.875rem;
    margin: 0 auto;
  }
}

.c-book__single {
  padding-top: 1.875rem;
}

.c-book__cover {
  padding-right: 0.9375rem;
  width: 50%;
  max-width: 11.875rem;
  float: left;
}

@media (max-width: 27.5rem) {
  .c-book__cover {
    width: 100%;
    float: none;
    padding-right: 0;
    margin: 0 auto 0.625rem;
  }
}

.c-book__single .c-book__cover {
  float: none;
  width: 100%;
  max-width: 21.875rem;
  margin-bottom: 1.875rem;
}

.c-book__info {
  overflow: hidden;
}

.c-book__title {
  margin-top: 0;
  margin-bottom: 0.9375rem;
  color: #2c2c2c;
}

.c-book__authors h6 {
  margin: 0 0 0.9375rem;
  display: inline-block;
  color: #aaaaaa;
}

.c-book__authors a {
  font-weight: 700;
  color: #454545;
}

.c-book__publisher,
.c-book__isbn {
  margin: 0 0 0.3125rem;
  font-size: 1rem;
}

.c-book__single .c-book__isbn {
  margin: 0 0 1.5625rem;
}

.c-books__orderby {
  padding-bottom: 1.875rem;
}

.c-books__orderby label {
  display: block;
  padding-bottom: 0.3125rem;
  color: #454545;
}

.c-books__orderby select {
  max-width: 18.75rem;
}

.c-books__dropdown {
  max-width: 18.75rem;
}

/* ==========================================================================
   #BREADCRUMB
   ========================================================================== */
.c-breadcrumb {
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
}

.c-breadcrumb__item:not(:last-child) {
  position: relative;
  padding-right: 0.625rem;
  margin-right: 1rem;
}

.c-breadcrumb__item:not(:last-child)::after {
  content: '';
  width: 0.125rem;
  height: 100%;
  background-color: #454545;
  position: absolute;
  right: -0.3125rem;
  top: 0;
  transform: rotate(15deg);
}

.c-breadcrumb__link,
.c-breadcrumb__item a {
  color: #2c2c2c;
  text-decoration: underline;
}

.c-breadcrumb__link:hover,
.c-breadcrumb__item a:hover {
  color: #2c2c2c;
  text-decoration: none;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn, .c-content__restricted form input[type=submit] {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  font-size: 1.375rem;
  font-weight: 400;
  text-align: center;
  /* [4] */
  margin: 0.625rem 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.75rem 2.8125rem;
  transition: all 300ms ease-in-out;
  border: 1px solid #006ce5;
  text-decoration: none;
}

.c-btn:hover, .c-content__restricted form input:hover[type=submit], .c-btn:active, .c-content__restricted form input:active[type=submit], .c-btn:focus, .c-content__restricted form input:focus[type=submit] {
  text-decoration: none;
}

.c-btn--expand {
  display: block;
}

.c-btn--full-width {
  width: 100%;
}

.c-btn--radius {
  border-radius: 0.1875rem;
}

.c-btn__icon {
  fill: currentColor;
}

.c-btn .c-icon, .c-content__restricted form input[type=submit] .c-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 1.875rem;
  height: 1.25rem;
  transition: all 300ms ease-in-out;
}

@media (max-width: 85.4375rem) {
  .c-btn, .c-content__restricted form input[type=submit] {
    padding: 0.625rem 2.5rem;
    font-size: 1.25rem;
  }
}

@media (max-width: 72rem) {
  .c-btn, .c-content__restricted form input[type=submit] {
    padding: 0.5rem 2.1875rem;
    font-size: 1.125rem;
    border-width: 2px;
  }
}

@media (max-width: 48.0625rem) {
  .c-btn, .c-content__restricted form input[type=submit] {
    padding: 0.375rem 1.875rem;
    font-size: 1.0625rem;
  }
}

.c-btn--small, .c-content__restricted form input[type=submit] {
  padding: 0.5rem 1.875rem;
  font-size: 1.25rem;
}

@media (max-width: 85.4375rem) {
  .c-btn--small, .c-content__restricted form input[type=submit] {
    padding: 0.4375rem 1.5625rem;
    font-size: 1.125rem;
  }
}

.c-btn--small .c-icon, .c-content__restricted form input[type=submit] .c-icon {
  width: 1.5625rem;
  height: 0.9375rem;
}

.c-btn--medium {
  padding: 0.9375rem 3.75rem;
}

@media (max-width: 85.4375rem) {
  .c-btn--medium {
    padding: 0.8125rem 3.4375rem;
  }
}

@media (max-width: 72rem) {
  .c-btn--medium {
    padding: 0.6875rem 3.125rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-btn--medium {
    padding: 0.625rem 2.5rem;
  }
}

.c-btn--medium .c-icon {
  width: 2.1875rem;
  height: 1.5625rem;
}

.c-btn--large {
  padding: 1.25rem 4.6875rem;
}

@media (max-width: 85.4375rem) {
  .c-btn--large {
    padding: 1rem 4.375rem;
  }
}

@media (max-width: 72rem) {
  .c-btn--large {
    padding: 0.875rem 4.0625rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-btn--large {
    padding: 0.75rem 3.4375rem;
  }
}

.c-btn--large .c-icon {
  width: 2.5rem;
  height: 1.875rem;
}

/* Style variants
   ========================================================================== */
.c-btn--primary, .c-content__restricted form input[type=submit] {
  background-color: transparent;
  color: #ff005c;
  border: 0.1875rem solid #ff005c;
}

.c-btn--primary:hover, .c-content__restricted form input:hover[type=submit], .c-btn--primary:active, .c-content__restricted form input:active[type=submit], .c-btn--primary:focus, .c-content__restricted form input:focus[type=submit] {
  background-color: #ff005c;
  color: #fff;
  border: 0.1875rem solid #ff005c;
}

.c-btn--primary-filled {
  background-color: #ff005c;
  color: #fff;
  border: 0.1875rem solid #ff005c;
}

.c-btn--primary-filled:hover, .c-btn--primary-filled:active, .c-btn--primary-filled:focus {
  background-color: transparent;
  color: #ff005c;
  border: 0.1875rem solid #ff005c;
}

.c-btn--secondary {
  background-color: transparent;
  color: #255cdd;
  border: 0.1875rem solid #255cdd;
}

.c-btn--secondary:hover, .c-btn--secondary:active, .c-btn--secondary:focus {
  background-color: #255cdd;
  color: #fff;
  border: 0.1875rem solid #255cdd;
}

.c-btn--secondary-filled {
  background-color: #255cdd;
  color: #fff;
  border: 0.1875rem solid #255cdd;
}

.c-btn--secondary-filled:hover, .c-btn--secondary-filled:active, .c-btn--secondary-filled:focus {
  background-color: transparent;
  color: #255cdd;
  border: 0.1875rem solid #255cdd;
}

.c-btn--link {
  background-color: transparent;
  color: #006ce5;
  border: 0.1875rem solid #006ce5;
}

.c-btn--link:hover, .c-btn--link:active, .c-btn--link:focus {
  background-color: #006ce5;
  color: #fff;
  border: 0.1875rem solid #006ce5;
}

.c-btn--link-filled {
  background-color: #006ce5;
  color: #fff;
  border: 0.1875rem solid #006ce5;
}

.c-btn--link-filled:hover, .c-btn--link-filled:active, .c-btn--link-filled:focus {
  background-color: transparent;
  color: #006ce5;
  border: 0.1875rem solid #006ce5;
}

.c-btn--link-underline {
  background-color: transparent;
  color: #2c2c2c;
  border: none;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 0.1875rem solid #255cdd;
}

.c-btn--link-underline:hover, .c-btn--link-underline:active, .c-btn--link-underline:focus {
  background-color: transparent;
  color: #255cdd;
  border: none;
}

.c-btn--link-underline:hover, .c-btn--link-underline:active, .c-btn--link-underline:focus {
  border-bottom: 0.1875rem solid #255cdd;
}

.c-btn--primary-light {
  background-color: #ff4d8d;
  color: #fff;
  border: 0.1875rem solid #ff4d8d;
}

.c-btn--primary-light:hover, .c-btn--primary-light:active, .c-btn--primary-light:focus {
  background-color: #ff005c;
  color: #fff;
  border: 0.1875rem solid #ff005c;
}

.c-btn--secondary-light {
  background-color: #678de7;
  color: #fff;
  border: 0.1875rem solid #678de7;
}

.c-btn--secondary-light:hover, .c-btn--secondary-light:active, .c-btn--secondary-light:focus {
  background-color: #255cdd;
  color: #fff;
  border: 0.1875rem solid #255cdd;
}

.c-btn--arrow-primary,
.c-btn--arrow-secondary {
  padding: 0.3125rem 0.9375rem;
  border: none !important;
}

.c-btn--arrow-primary {
  color: #2c2c2c;
}

.c-btn--arrow-primary .c-icon {
  fill: #ff005c;
}

.c-btn--arrow-primary:hover, .c-btn--arrow-primary:active, .c-btn--arrow-primary:focus {
  color: #ff005c;
}

.c-btn--arrow-primary:hover .c-icon, .c-btn--arrow-primary:active .c-icon, .c-btn--arrow-primary:focus .c-icon {
  transform: translateX(5px);
}

.c-btn--arrow-secondary {
  color: #2c2c2c;
}

.c-btn--arrow-secondary .c-icon {
  fill: #255cdd;
}

.c-btn--arrow-secondary:hover, .c-btn--arrow-secondary:active, .c-btn--arrow-secondary:focus {
  color: #255cdd;
}

.c-btn--arrow-secondary:hover .c-icon, .c-btn--arrow-secondary:active .c-icon, .c-btn--arrow-secondary:focus .c-icon {
  transform: translateX(5px);
}

/* ==========================================================================
   #CALENDAR
   ========================================================================== */
.c-calendar__table {
  display: table;
}

@media (max-width: 64rem) {
  .c-calendar__table {
    display: none;
  }
}

.c-calendar__heading {
  position: relative;
  display: block;
  margin-bottom: 2.5rem;
}

@media (max-width: 48.0625rem) {
  .c-calendar__heading {
    margin-bottom: 1.875rem;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.c-calendar__pager {
  background-color: #dddddd;
  border-radius: 0.1875rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  height: 1.875rem;
  margin-top: 1.875rem;
}

@media (min-width: 48.0625rem) {
  .c-calendar__pager {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0;
  }
}

.c-calendar__pager-prev,
.c-calendar__pager-next {
  float: left;
  background-color: #fff;
  border-radius: 0 0.1875rem 0.1875rem 0;
  border: 1px solid #bfbfbf;
  height: 2.25rem;
  top: -0.25rem;
  position: relative;
}

.c-calendar__pager-prev:hover,
.c-calendar__pager-next:hover {
  border-color: #777777;
}

.c-calendar__pager-prev {
  border-radius: 0.1875rem 0 0 0.1875rem;
  right: -0.0625rem;
}

.c-calendar__pager-prev:hover {
  z-index: 1;
}

.c-calendar__pager-link {
  display: block;
  font-size: 0.875rem;
  line-height: 2.25rem;
  padding: 0 0.625rem;
  color: #555555;
  text-decoration: none;
}

.c-calendar__pager-link:hover {
  color: #555555;
  text-decoration: none;
}

.c-calendar__pager-link--inactive {
  color: #dddddd;
}

.c-calendar__pager-link--inactive:hover {
  color: #dddddd;
}

.c-calendar__header {
  background-color: #eeeeee;
  color: #777777;
  font-weight: bold;
  border: 1px solid #bfbfbf;
}

.c-calendar__date-box,
.c-calendar__single-day {
  width: 14%;
  border: 1px solid #bfbfbf;
  color: #777777;
  text-align: right;
  vertical-align: top;
  margin: 0;
  padding: 0.125rem;
}

.c-calendar__date-box {
  border-bottom: none;
}

.c-calendar__single-day {
  border-top: none;
}

.c-calendar__date-box--past,
.c-calendar__date-box--future,
.c-calendar__single-day--past,
.c-calendar__single-day--future {
  background-color: #f4f4f4;
  color: #aaaaaa;
}

.c-calendar__date-box--today {
  border: 0.125rem solid #255cdd;
  border-bottom-width: 0;
}

.c-calendar__single-day--today {
  border: 0.125rem solid #255cdd;
  border-top-width: 0;
}

.c-calendar__single-day .c-calendar__inner {
  height: 9.375rem;
  line-height: 2.75rem;
  overflow: auto;
}

@media (max-width: 80rem) {
  .c-calendar__single-day .c-calendar__inner {
    height: 7.5rem;
  }
}

.c-calendar__single-day .c-calendar__inner--full {
  line-height: normal;
  height: 12.8125rem;
  font-size: 0.8125rem;
  padding: 0.3125rem;
}

@media (max-width: 80rem) {
  .c-calendar__single-day .c-calendar__inner--full {
    height: 10rem;
  }
}

.c-calendar__inner hr {
  margin: 0.625rem 0;
}

@media (min-width: 64rem) {
  .c-calendar__list {
    display: none;
  }
}

.c-calendar__event-title {
  margin-bottom: 0.3125rem;
  text-align: left;
}

.c-calendar__event-title a {
  color: inherit;
  text-decoration: none;
}

.c-calendar__event-title a:hover {
  color: #255cdd;
}

.c-calendar__list-item {
  border-bottom: 1px solid #bfbfbf;
  padding: 0.3125rem 0.625rem;
  margin-bottom: 0;
}

.c-calendar__list-item ul {
  margin-left: 0.625rem;
}

.c-calendar__list-item ul li {
  list-style-type: none;
}

.c-calendar__list-item ul li p {
  margin-bottom: 0.3125rem;
}

.c-calendar__list-item--today {
  background-color: #aaaaaa;
}

/* ==========================================================================
   #CARD
   ========================================================================== */
.c-card {
  box-shadow: 0 0 0 1px #bfbfbf inset;
  padding: 0.625rem;
}

.c-newsletters-page .c-card {
  max-width: 25.625rem;
}

.c-card--primary {
  box-shadow: 0 0 0 4px #255cdd inset;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

/* ==========================================================================
   #COURSES
   ========================================================================== */
.c-courses__header {
  font-size: 1.25rem;
}

.c-courses__meta {
  border-bottom: 0.0625rem solid #777777;
  color: #777777;
  display: inline-block;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}

.c-courses__teaser {
  color: #555555;
}

.c-courses__filter {
  border: 0.125rem solid #dddddd;
  border-left-width: 0;
  border-right-width: 0;
  padding-top: 0.78125rem;
}

.c-courses__filter-reset {
  color: #2c2c2c;
  text-decoration: underline;
}

.c-courses__filter-reset:hover {
  color: #2c2c2c;
  text-decoration: none;
}

.c-courses__item {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
  border-bottom: 0.125rem solid #dddddd;
}

@media (max-width: 64rem) {
  .c-courses__item--left-col {
    text-align: right;
  }
}

@media (max-width: 64rem) {
  .c-courses__item--left-col, .c-courses__item--right-col {
    order: -1;
    padding-top: 0.3125rem;
    padding-bottom: 0.625rem;
  }
}

@media (max-width: 27.5rem) {
  .c-courses__item--left-col, .c-courses__item--right-col {
    flex-basis: 100%;
    max-width: 100%;
    text-align: center !important;
  }
}

.c-courses__title a {
  color: inherit;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.c-courses__title a:hover {
  color: #517de4;
}

.c-courses__title-single {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

.c-courses__details {
  margin-bottom: 0;
}

@media (max-width: 27.5rem) {
  .c-courses__details {
    max-width: 11.25rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-courses__details-single {
  margin-bottom: 0.625rem;
  text-align: center;
}

.c-courses__detail {
  display: flex;
  align-items: center;
}

.c-courses__detail:last-child {
  margin-bottom: 0;
}

@media (max-width: 40rem) {
  .c-courses__detail {
    font-size: 0.875rem;
    margin-bottom: 0.3125rem;
  }
  .c-courses__detail svg {
    margin-right: 0.625rem;
  }
}

.c-courses__details-single .c-courses__detail {
  display: inline-block;
  margin-right: 0.3125rem;
  margin-left: 0.3125rem;
}

.c-courses__details-single .c-courses__detail svg {
  position: relative;
  top: -0.125rem;
  margin-right: 0;
  display: inline-block;
  vertical-align: middle;
}

.c-courses__thumbnail {
  display: inline-block;
}

@media (max-width: 64rem) {
  .c-courses__thumbnail img {
    max-height: 7.5rem;
  }
}

.c-courses__thumbnail-single {
  display: block;
}

.c-courses__thumbnail-single img {
  display: block;
  margin: 0 auto 1.25rem;
  max-height: 37.5rem;
}

@media (max-width: 64rem) {
  .c-courses__thumbnail-single img {
    max-height: 25rem;
  }
}

.flatpickr-calendar {
  font-size: 0.875rem;
  line-height: 1.125rem;
  border-radius: 0.3125rem;
  width: 15.625rem;
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  max-height: 14.6875rem;
}

.flatpickr-calendar.open.hasTime, .flatpickr-calendar.inline.hasTime {
  max-height: 15.625rem;
}

.flatpickr-calendar .dayContainer,
.flatpickr-calendar .flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-calendar .dayContainer {
  padding: 0;
  text-align: left;
  width: 15.625rem;
  min-width: 15.625rem;
  max-width: 15.625rem;
  max-height: 11.25rem;
}

.flatpickr-month {
  height: 1.75rem;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  line-height: 1.125rem;
  height: 1.75rem;
  padding: 0.4375rem calc(3.57% - 1.5px);
}

.flatpickr-prev-month svg, .flatpickr-next-month svg {
  width: 0.875rem;
}

.flatpickr-prev-month:hover svg, .flatpickr-next-month:hover svg {
  fill: #255cdd !important;
}

.flatpickr-current-month {
  font-size: 1rem;
  width: 75%;
  left: 12.5%;
  padding: 0.375rem 0 0 0;
  line-height: 1;
  height: 1.75rem;
}

.flatpickr-current-month input.cur-year {
  /* stylelint-disable-line selector-no-qualifying-type */
  padding: 0 0 0 0.3125rem;
  margin: 0;
  display: inline-block;
  font-weight: 300;
  line-height: inherit;
  height: initial;
  border: none;
}

.flatpickr-weekdays {
  width: 100%;
  height: 1.75rem;
  font-size: 0.8125rem;
}

.flatpickr-days {
  width: 15.625rem;
}

.flatpickr-day {
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 2.1875rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
  margin: 0;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: #eeeeee;
  border-color: #eeeeee;
}

.flatpickr-day.today {
  border-color: #255cdd !important;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #255cdd !important;
  background: #255cdd !important;
  color: #fff !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #255cdd;
  border-color: #255cdd;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  box-shadow: -10px 0 0 #255cdd;
}

.flatpickr-day.startRange, .flatpickr-day.prevMonthDay.startRange, .flatpickr-day.nextMonthDay.startRange, .flatpickr-day.endRange, .flatpickr-day.prevMonthDay.endRange, .flatpickr-day.nextMonthDay.endRange {
  border-color: #255cdd;
}

.flatpickr-time {
  padding: 0.1875rem 0.3125rem 0.3125rem;
}

.flatpickr-time input {
  border: solid 0.0625rem #eeeeee !important;
  height: 1.75rem;
  font-size: 1rem;
}

.flatpickr-time .flatpickr-am-pm {
  display: none;
}

/* ==========================================================================
   #ENTRY
   ========================================================================== */
.c-entry__header {
  background-color: #eeeeee;
  padding: 2.1875rem;
  margin-bottom: 3.125rem;
}

.c-entry__title {
  font-size: 1.625rem;
  font-weight: bold;
}

@media (min-width: 48.0625rem) {
  .c-entry__title {
    font-size: 1.875rem;
  }
}

@media (min-width: 64rem) {
  .c-entry__title {
    font-size: 2.25rem;
  }
}

.c-entry__meta {
  font-size: 1.125rem;
}

.c-entry__meta-item {
  border-left: 0.125rem solid #000;
  margin-right: 0.375rem;
  padding-left: 0.5rem;
}

.c-entry__meta-item:first-child {
  padding-left: 0;
  border-left: none;
}

.c-entry__author {
  font-size: 1.125rem;
}

.c-entry__author-bio {
  line-height: 2rem;
}

.c-entry__content {
  font-size: 1.125rem;
  margin: 0.625rem 0;
}

@media (min-width: 48.0625rem) {
  .c-entry__content {
    margin: 1.875rem 0 3.125rem;
  }
}

.c-entry__main-img {
  margin-bottom: 1.25rem;
}

.c-entry__lead {
  font-size: 1.5rem;
  line-height: 2.625rem;
  font-style: italic;
  margin-bottom: 3.125rem;
}

.c-events-calendar {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 72rem) {
  .c-events-calendar--size-75 {
    width: 75%;
  }
}

@media (min-width: 80rem) {
  .c-events-calendar--size-66 {
    width: 66%;
  }
}

@media (min-width: 80rem) {
  .c-events-calendar--size-50 {
    width: 50%;
  }
}

.c-events-calendar__loader {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 3.75rem;
  z-index: 9999;
  transform: translateY(-50%);
}

.c-events-calendar .fc-header-toolbar h2 {
  position: relative;
  font-size: 2rem;
}

@media (max-width: 72rem) {
  .c-events-calendar .fc-header-toolbar h2 {
    top: 0.3125rem;
    font-size: 1.75rem;
  }
}

@media (max-width: 64rem) {
  .c-events-calendar .fc-header-toolbar h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-events-calendar .fc-header-toolbar h2 {
    font-size: 1.125rem;
  }
}

.c-events-calendar .fc-header-toolbar .fc-button {
  background-color: #255cdd;
  background-image: none;
  color: #fff;
  text-shadow: none;
  transition: all 300ms ease-in-out;
}

@media (max-width: 72rem) {
  .c-events-calendar .fc-header-toolbar .fc-button {
    font-size: 1rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-events-calendar .fc-header-toolbar .fc-button {
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0 0.3125rem;
  }
}

.c-events-calendar .fc-header-toolbar .fc-button:hover {
  background-color: #517de4;
}

.c-events-calendar .fc-header-toolbar .fc-state-disabled,
.c-events-calendar .fc-header-toolbar .fc-today-button {
  background-color: #ff005c;
  opacity: 1;
}

.c-events-calendar .fc-header-toolbar .fc-state-disabled:hover,
.c-events-calendar .fc-header-toolbar .fc-today-button:hover {
  background-color: #ff4d8d;
}

.c-events-calendar.fc table {
  margin: 0;
}

@media (max-width: 48.0625rem) {
  .c-events-calendar .fc-day-header {
    font-size: 0.875rem;
  }
}

.c-events-calendar td.fc-today {
  /* stylelint-disable-line selector-no-qualifying-type */
  background-color: #eeeeee;
}

.c-events-calendar .fc-event-container .fc-event {
  position: relative;
  padding: 0.0625rem 0.3125rem;
  background-color: #ff005c;
  border: none;
  z-index: 999;
  transition: all 300ms ease-in-out;
}

.c-events-calendar .fc-event-container .fc-event:hover {
  background-color: #ff4d8d;
}

.c-events-calendar .fc-event-container .fc-title,
.c-events-calendar .fc-event-container .fc-content {
  white-space: normal !important;
  overflow: auto !important;
}

.c-events-calendar .addtocalendar {
  position: absolute;
  top: 0.3125rem;
  left: auto;
  display: block;
  z-index: 999;
  padding-left: 0;
}

.c-events-calendar .addtocalendar .atcb-link {
  position: absolute;
  right: 0.9375rem;
  padding: 0.75rem 0;
  width: 26px;
  background-position: center center;
}

.c-events-calendar .addtocalendar .atcb-list {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 99;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  background-color: #fffcfd;
}

.page-template-front-page .c-footer {
  margin-top: 0;
}

@media (max-width: 64rem) {
  .c-footer__column {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.c-footer__column-header {
  padding: 0.625rem 0 1.25rem;
}

.c-footer__column-header h3 {
  margin: 0;
  border-color: #255cdd;
}

.c-footer .c-footer__inner {
  max-width: 366px;
  margin: 0 auto;
  padding: 2.5rem 0 1.25rem;
  text-align: center;
}

@media (min-width: 64rem) {
  .c-footer .c-footer__inner {
    max-width: 100%;
    text-align: left;
  }
}

.c-footer .c-footer__inner .u-text-left {
  text-align: center;
}

@media (min-width: 48.0625rem) {
  .c-footer .c-footer__inner .u-text-left {
    text-align: left;
  }
}

.c-footer .c-footer__inner .u-text-right {
  text-align: center;
}

@media (min-width: 48.0625rem) {
  .c-footer .c-footer__inner .u-text-right {
    text-align: right;
  }
}

.c-footer__copyright {
  align-items: center;
  background-color: #fffcfd;
  padding: 2.5rem 0;
  font-size: 1.0625rem;
  border-top: 1px solid #eeeeee;
}

@media (max-width: 64rem) {
  .c-footer__copyright {
    padding: 1.5625rem 0;
  }
  .c-footer__copyright * {
    text-align: center !important;
  }
}

.c-footer__copyright .o-layout {
  align-items: center;
}

.c-footer__copyright .c-footer__logo {
  max-width: 10rem;
}

@media (max-width: 64rem) {
  .c-footer__copyright .c-footer__logo {
    margin: 0 auto 1.25rem;
  }
}

.c-footer__copyright p {
  margin: 0;
}

.c-footer__copyright .c-social-media {
  float: right;
  margin: 0;
}

@media (max-width: 64rem) {
  .c-footer__copyright .c-social-media {
    float: none;
    margin-top: 1.25rem;
  }
}

.cform__entry {
  padding: 3.125rem 0 3.75rem;
}

@media (max-width: 64rem) {
  .cform__entry {
    padding: 1.875rem 0 2.5rem;
  }
}

@media (max-width: 48.0625rem) {
  .cform__entry {
    padding: 1.25rem 0 1.875rem;
  }
}

.cform__entry * {
  font-weight: 600;
}

.c-form-field--small {
  height: 3.125rem;
  padding: 0.3125rem 0.625rem;
  font-size: 1rem;
  border-width: 0.125rem !important;
}

select.c-form-field--small {
  /* stylelint-disable-line selector-no-qualifying-type */
  background-size: 20px auto;
}

.c-form-group {
  margin-bottom: 0.625rem;
}

.c-form-group .select2-container {
  margin-bottom: 0.3125rem;
}

.c-form-group label,
.c-form-group legend {
  margin-bottom: 0.3125rem;
}

.c-form-group label span,
.c-form-group legend span {
  position: relative;
  top: -3px;
  font-size: 13px;
  color: #cc0000;
}

.c-form-group .c-field__label {
  position: relative;
  display: block;
  font-size: 1.125rem;
  font-weight: 600;
}

@media (max-width: 48.0625rem) {
  .c-form-group .c-field__label {
    font-size: 1rem;
  }
}

.c-form-group .c-field__label--checkbox {
  min-height: 1.875rem;
  margin: 0.625rem 0;
  padding: 0 0 0 0.625rem;
}

@media (max-width: 48.0625rem) {
  .c-form-group .c-field__label--checkbox {
    margin: 0.3125rem 0;
    padding: 0.1875rem 0 0 0.5rem;
  }
}

.c-form-group .c-field__label--checkbox [type=checkbox],
.c-form-group .c-field__label--checkbox [type=radio] {
  opacity: 0;
}

.c-form-group .c-field__label--checkbox [type=checkbox].focus-visible ~ svg,
.c-form-group .c-field__label--checkbox [type=radio].focus-visible ~ svg {
  stroke-width: 1px;
}

.c-form-group .c-field__label .c-icon {
  top: -0.25rem;
  left: -0.1875rem;
  position: absolute;
  display: inline-block;
  width: 2.25rem;
  height: 2.25rem;
  stroke-width: 0;
  stroke: #255cdd;
  fill: #255cdd;
}

.c-form-group .c-field__label .c-icon--checkbox-unchecked {
  top: -0.375rem;
  left: -0.3125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.c-form-group .c-field__label .c-icon--checkbox-checked, .c-form-group .c-field__label .c-icon--radio-checked {
  opacity: 0;
}

.c-form-group .c-field__label .c-icon--radio-unchecked {
  top: -0.0625rem;
  left: -0.125rem;
  width: 1.9375rem;
  height: 1.9375rem;
}

.c-form-group .c-field__label .c-icon--radio-checked {
  top: 0;
  left: -0.0625rem;
  width: 1.8125rem;
  height: 1.8125rem;
}

@media (max-width: 48.0625rem) {
  .c-form-group .c-field__label .c-icon {
    transform: scale(0.8);
  }
}

.c-form-group .c-field__label [type=checkbox]:checked ~ .c-icon--checkbox-unchecked,
.c-form-group .c-field__label [type=radio]:checked ~ .c-icon--radio-unchecked {
  opacity: 0;
}

.c-form-group .c-field__label [type=checkbox]:checked ~ .c-icon--checkbox-checked,
.c-form-group .c-field__label [type=radio]:checked ~ .c-icon--radio-checked {
  opacity: 1;
}

.c-form-group .c-field__label--clearfix {
  display: none;
}

.c-form-group .c-field__label--vertical + .c-field__label--clearfix {
  display: block;
}

.c-form-group .c-field__label--horizontal {
  display: inline-block;
  margin: 0.625rem 0.625rem 0.625rem 0;
}

.c-form-group legend {
  padding: 0;
  color: #255cdd;
}

.c-form-group fieldset {
  padding: 0;
}

.c-form__control.c-form__submit {
  height: auto !important;
  margin: 0 auto;
}

.c-form__control.c-form__textarea {
  height: 9.375rem !important;
}

.c-form__control.c-form__textarea--medium {
  height: 12.5rem !important;
}

.c-form__control.c-form__textarea--large {
  height: 15.625rem !important;
}

.c-form__control:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.c-field__error {
  padding-left: 0.1875rem;
  font-size: 0.875rem;
  color: #cc0000;
  display: none;
}

.c-field__error.show {
  display: block;
}

@media (max-width: 48.0625rem) {
  .c-field__error {
    font-size: 0.8125rem;
  }
}

.c-field__message {
  display: none;
  font-size: 1.125rem;
}

.c-field__message.show {
  display: block;
  width: 80%;
  margin: 0.625rem auto 0;
  padding: 0.625rem;
  text-align: center;
  color: #fff;
}

.c-field__message.error {
  background-color: #cc0000;
}

.c-field__message.success {
  background-color: #1cca98;
}

@media (max-width: 72rem) {
  .c-field__message {
    font-size: 1rem;
  }
  .c-field__message.show {
    padding: 0.5rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-field__message {
    font-size: 0.9375rem;
  }
  .c-field__message.show {
    width: 100%;
  }
}

.c-field__break {
  flex-basis: 100%;
  width: 0;
  height: 0;
  overflow: hidden;
}

.c-form__message {
  padding: 50px 0;
}

.c-newsletter__form .o-layout {
  position: relative;
  left: -0.8125rem;
  width: calc(100% + 26px);
  align-items: flex-start;
}

.c-newsletter__form .c-form__input,
.c-newsletter__form .c-btn,
.c-newsletter__form .c-content__restricted form input[type=submit],
.c-content__restricted form .c-newsletter__form input[type=submit] {
  width: 100%;
  height: 4.5rem;
  margin: 0;
  font-weight: 500;
}

@media (max-width: 85.4375rem) {
  .c-newsletter__form .c-form__input,
  .c-newsletter__form .c-btn,
  .c-newsletter__form .c-content__restricted form input[type=submit],
  .c-content__restricted form .c-newsletter__form input[type=submit] {
    height: 4.125rem;
  }
}

@media (max-width: 64rem) {
  .c-newsletter__form .c-form__input,
  .c-newsletter__form .c-btn,
  .c-newsletter__form .c-content__restricted form input[type=submit],
  .c-content__restricted form .c-newsletter__form input[type=submit] {
    height: 3.875rem;
    padding: 0.9375rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-newsletter__form .c-form__input,
  .c-newsletter__form .c-btn,
  .c-newsletter__form .c-content__restricted form input[type=submit],
  .c-content__restricted form .c-newsletter__form input[type=submit] {
    height: 3.125rem;
    padding: 0.625rem;
  }
}

@media (max-width: 48.0625rem) {
  
  .c-newsletter__form .c-btn,
  .c-newsletter__form .c-content__restricted form input[type=submit],
  .c-content__restricted form .c-newsletter__form input[type=submit] {
    font-size: 1.125rem;
  }
}

.c-newsletter__form .c-form__input {
  border-color: #fff;
  color: #2c2c2c;
}

@media (max-width: 64rem) {
  .c-newsletter__form .c-form__input {
    margin-bottom: 0;
  }
}

.c-newsletter__form .c-form__input:hover, .c-newsletter__form .c-form__input:focus {
  border-color: #ff005c;
}

@media (max-width: 48.0625rem) {
  .c-newsletter__form .c-form__input:hover, .c-newsletter__form .c-form__input:focus {
    border-width: 0.125rem;
  }
}

.c-newsletter__form .c-form__input::placeholder {
  color: #2c2c2c;
}

.c-galleries {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.c-galleries--list {
  padding-top: 1.875rem;
}

@media (max-width: 64rem) {
  .c-galleries--list {
    padding-top: 0;
  }
}

.c-galleries--list li {
  padding: 0.3125rem 0.625rem;
  border-bottom: 1px solid #eeeeee;
}

.c-gallery__title {
  margin: 0;
  font-size: 1.5rem;
}

@media (max-width: 80rem) {
  .c-gallery__title {
    font-size: 1.25rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-gallery__title {
    font-size: 1.125rem;
  }
}

.c-gallery__title a {
  color: inherit;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.c-gallery__title a:hover {
  color: #006ce5;
}

.c-galleries--list .c-gallery__title {
  font-size: 1.125rem;
}

.c-gallery__image {
  display: block;
  overflow: hidden;
}

.c-gallery img {
  transition: all 300ms ease-in-out;
}

.c-gallery:hover img {
  transform: scale(1.1) rotate(2deg);
}

.c-gallery__single {
  display: flex;
  flex-flow: row wrap;
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
  align-items: center;
}

.c-gallery__single-item-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.c-gallery__single-item .icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: #fff;
}

.c-gallery__single--cols-1 .c-gallery__single-item {
  width: 100%;
  flex-basis: 100%;
  padding: 0.3125rem;
}

.c-gallery__single--cols-1 .c-gallery__single-item .icon {
  width: 3.125rem;
  height: 3.125rem;
}

@media (max-width: 27.5rem) {
  .c-gallery__single--cols-1 .c-gallery__single-item {
    width: 100%;
    flex-basis: 100%;
  }
}

.c-gallery__single--cols-2 .c-gallery__single-item {
  width: 50%;
  flex-basis: 50%;
  padding: 0.3125rem;
}

.c-gallery__single--cols-2 .c-gallery__single-item .icon {
  width: 3.125rem;
  height: 3.125rem;
}

@media (max-width: 48.0625rem) {
  .c-gallery__single--cols-2 .c-gallery__single-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-gallery__single--cols-2 .c-gallery__single-item {
    width: 100%;
    flex-basis: 100%;
  }
}

.c-gallery__single--cols-3 .c-gallery__single-item {
  width: 33.33333%;
  flex-basis: 33.33333%;
  padding: 0.3125rem;
}

.c-gallery__single--cols-3 .c-gallery__single-item .icon {
  width: 3.125rem;
  height: 3.125rem;
}

@media (max-width: 64rem) {
  .c-gallery__single--cols-3 .c-gallery__single-item {
    width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 48.0625rem) {
  .c-gallery__single--cols-3 .c-gallery__single-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-gallery__single--cols-3 .c-gallery__single-item {
    width: 100%;
    flex-basis: 100%;
  }
}

.c-gallery__single--cols-4 .c-gallery__single-item {
  width: 25%;
  flex-basis: 25%;
  padding: 0.3125rem;
}

@media (max-width: 80rem) {
  .c-gallery__single--cols-4 .c-gallery__single-item {
    width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 64rem) {
  .c-gallery__single--cols-4 .c-gallery__single-item {
    width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 48.0625rem) {
  .c-gallery__single--cols-4 .c-gallery__single-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-gallery__single--cols-4 .c-gallery__single-item {
    width: 100%;
    flex-basis: 100%;
  }
}

.c-gallery__single--cols-5 .c-gallery__single-item {
  width: 20%;
  flex-basis: 20%;
  padding: 0.3125rem;
}

@media (max-width: 80rem) {
  .c-gallery__single--cols-5 .c-gallery__single-item {
    width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 64rem) {
  .c-gallery__single--cols-5 .c-gallery__single-item {
    width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 48.0625rem) {
  .c-gallery__single--cols-5 .c-gallery__single-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-gallery__single--cols-5 .c-gallery__single-item {
    width: 100%;
    flex-basis: 100%;
  }
}

.c-gallery__single--cols-6 .c-gallery__single-item {
  width: 16.66667%;
  flex-basis: 16.66667%;
  padding: 0.3125rem;
}

@media (max-width: 80rem) {
  .c-gallery__single--cols-6 .c-gallery__single-item {
    width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 64rem) {
  .c-gallery__single--cols-6 .c-gallery__single-item {
    width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (max-width: 48.0625rem) {
  .c-gallery__single--cols-6 .c-gallery__single-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-gallery__single--cols-6 .c-gallery__single-item {
    width: 100%;
    flex-basis: 100%;
  }
}

.c-galleries--thumbs .c-gallery__inner {
  max-width: 33.125rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;
  border: solid 1px #eeeeee;
}

@media (max-width: 64rem) {
  .c-galleries--thumbs .c-gallery__inner {
    max-width: 28.125rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-galleries--thumbs .c-gallery__inner {
    max-width: 21.875rem;
  }
}

.c-galleries--thumbs .c-gallery__inner .c-gallery__title {
  padding: 0.3125rem;
  text-align: center;
}

.c-header {
  display: block;
  position: relative;
  box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0);
  z-index: 99;
  transition: padding 0.3s ease-in-out;
  box-sizing: border-box;
}

.c-header--floating {
  position: absolute;
  padding: 0.625rem 0.625rem;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

@media (max-width: 64rem) {
  .c-header--floating-nomobile {
    padding: 0;
    position: relative;
  }
}

.c-header--floating.c-header--fixed {
  position: fixed;
}

.c-header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.c-header--shrink {
  padding: 0;
  box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.4);
}

.c-header__container {
  position: relative;
  width: 100%;
  margin: auto;
  padding: 0.625rem 0.625rem;
  transition: background 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.c-header__mobile {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: -1;
}

@media (max-width: 48.0625rem) {
  .c-header__mobile {
    display: block;
  }
}

.c-header--image:not(&--floating) .c-header__mobile {
  display: block;
}

.c-header__image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
}

.c-header__inner {
  width: 100%;
  height: 6.875rem;
  display: flex;
  margin: 0 auto;
  padding: 0.625rem 0.625rem;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 64rem) {
  .c-header__inner {
    height: 5rem;
    padding: 0.625rem 0.625rem;
  }
}

.c-header--shrink .c-header__inner {
  height: 5.625rem;
}

@media (max-width: 64rem) {
  .c-header--shrink .c-header__inner {
    height: 3.75rem;
    padding: 0 0.625rem;
  }
}

.c-header--shrink .c-header__container {
  padding: 0.625rem 1.25rem;
}

@media (max-width: 64rem) {
  .c-header--floating-nomobile.c-header--shrink .c-header__container {
    padding: 0.625rem 0.625rem;
  }
}

@media (min-width: 93.75rem) {
  .c-header--full.c-header--static .c-header__inner,
  .c-header--full.c-header--floating .c-header__inner {
    padding: 0.3125rem 3.75rem;
  }
}

.c-header--container .c-header__inner {
  max-width: 105rem;
}

.c-header--floating .c-header__inner {
  height: 5.625rem;
}

@media (max-width: 64rem) {
  .c-header--floating .c-header__inner {
    height: 3.75rem;
    padding: 0 0.625rem;
  }
}

.c-header--floating:not(.c-header--full) .c-header__container {
  max-width: 105rem;
  padding: 0.625rem 0;
}

.body__fixed-header--floating .c-header--floating:not(.c-header--full) .c-header__container {
  max-width: none;
}

.c-header--floating.c-header--shrink .c-header__container {
  max-width: none;
}

.c-header--static.c-header--image .c-header__container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.c-header--static.c-header--image .c-header__inner {
  height: 13.125rem;
}

@media (max-width: 85.4375rem) {
  .c-header--static.c-header--image .c-header__inner {
    height: 11.25rem;
  }
}

@media (max-width: 64rem) {
  .c-header--static.c-header--image .c-header__inner {
    height: 7.5rem;
    align-items: flex-start;
  }
}

.c-header__logo {
  position: relative;
  width: 25%;
  height: 100%;
}

.c-header__logo a {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 18.75rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-decoration: none;
}

.c-header__logo--color {
  padding: 5px;
}

@media (max-width: 85.4375rem) {
  .c-header__logo {
    width: 20%;
  }
}

@media (max-width: 80rem) {
  .c-header__logo {
    width: 17%;
  }
}

@media (max-width: 64rem) {
  .c-header__logo {
    max-width: 11.875rem;
    width: 100%;
  }
}

.c-header__nav {
  width: 75%;
}

@media (max-width: 85.4375rem) {
  .c-header__nav {
    width: 80%;
  }
}

@media (max-width: 80rem) {
  .c-header__nav {
    width: 83%;
  }
}

@media (max-width: 64rem) {
  .c-header__nav {
    width: auto;
  }
}

.c-header__inner--nologo .c-header__nav {
  width: 100%;
}

.c-header__mobile-actions {
  display: none;
}

@media (max-width: 64rem) {
  .c-header__mobile-actions {
    display: flex;
    align-items: center;
  }
}

.c-header--static.c-header--image .c-header__mobile-actions {
  margin-top: 0.625rem;
}

.c-search {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(69, 69, 69, 0.95);
  transition: all 300ms ease-in-out;
  z-index: 999;
}

.c-search__form {
  max-width: 60%;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  z-index: 3;
  border: 1.25rem solid #255cdd;
  transition: all 300ms ease-in-out;
}

@media (max-width: 64rem) {
  .c-search__form {
    border-width: 0.625rem;
    max-width: 31.25rem;
  }
}

.c-search__form:hover {
  border-color: #aaaaaa !important;
}

.c-search [type=text].c-search__input {
  height: 4.375rem;
  padding: 0.625rem;
  position: relative;
  border: none;
  margin: 0;
  border-radius: 0;
  flex: 1 1 0;
}

@media (max-width: 64rem) {
  .c-search [type=text].c-search__input {
    height: 3.125rem;
  }
}

.c-search__btn {
  height: 4.375rem;
  position: relative;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  cursor: pointer;
  border: none;
}

@media (max-width: 64rem) {
  .c-search__btn {
    height: 3.125rem;
  }
}

.c-search__close {
  position: absolute;
  display: block;
  top: 1.875rem;
  right: 1.875rem;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 3.75rem;
  line-height: 2rem;
  color: #255cdd;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

@media (max-width: 64rem) {
  .c-search__close {
    top: 1.25rem;
    right: 1.25rem;
  }
}

.c-search__close:hover {
  color: #fff !important;
  text-decoration: none !important;
}

/* ==========================================================================
   #HEADING
   ========================================================================== */
.c-heading {
  display: inline-block;
  border-bottom: 0.25rem solid #aaaaaa;
  padding-bottom: 0.25rem;
}

.c-heading--full {
  display: block;
}

.c-heading--secondary {
  border: none;
  padding-bottom: 0;
  color: #555555;
}

.c-heading--big {
  font-size: 1.25rem;
}

@media (min-width: 48.0625rem) {
  .c-heading--big {
    font-size: 1.625rem;
  }
}

.c-page__title {
  margin-bottom: 1.5625rem;
  font-size: 2.25rem;
}

@media (max-width: 85.4375rem) {
  .c-page__title {
    font-size: 2rem;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 80rem) {
  .c-page__title {
    font-size: 1.75rem;
    margin-bottom: 0.9375rem;
  }
}

@media (max-width: 64rem) {
  .c-page__title {
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
  }
}

/* ==========================================================================
   #HERO
   ========================================================================== */
.c-hero {
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: 1.875rem;
}

.c-hero--imageless {
  background-color: #dddddd;
  padding: 3.125rem;
}

.c-hero__content {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

@media (min-width: 48.0625rem) {
  .c-hero__content {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}

.c-hero--imageless .c-hero__content {
  position: relative;
  z-index: 3;
  top: auto;
  transform: none;
}

.c-hero__img {
  opacity: 0.4;
  margin-left: auto;
  margin-right: auto;
}

.c-hero__title {
  text-transform: uppercase;
  font-weight: bold;
}

.c-hero__meta {
  font-size: 0.875rem;
}

@media (min-width: 48.0625rem) {
  .c-hero__meta {
    font-size: 1.25rem;
  }
}

@media (min-width: 64rem) {
  .c-hero__meta {
    font-size: 1.5rem;
  }
}

.c-sf__entry {
  padding-top: 0.625rem;
  padding-bottom: 1.25rem;
}

.c-sf__form-submit-another {
  display: none;
  margin-top: 1.875rem;
}

.c-sf__form-submit-another.show {
  display: inline-block;
}

.c-sf__form-section {
  padding: 0 0 1.25rem;
}

.c-sf__form-section h3 {
  display: inline-block;
  margin-bottom: 1.875rem;
  border-bottom: 0.1875rem solid #aaaaaa;
  text-transform: uppercase;
}

.c-sf__form-section .wp-media-buttons {
  display: none !important;
}

.c-sf__file-field {
  margin: 0.9375rem 0 1.5625rem 0;
}

.c-sf__form-fields, .c-sf__msg {
  width: 100%;
  max-width: 56.25rem;
  margin: 0 auto;
}

.c-sf__msg {
  padding: 0.3125rem 0.625rem;
  text-align: center;
  font-size: 0.9375rem;
  color: #fff;
}

.c-sf__msg--error {
  background-color: #cc0000;
}

.c-sf__msg--success {
  background-color: #1cca98;
}

.mce-inline-toolbar-grp .mce-btn-group .mce-btn:last-child {
  display: none !important;
}

.c-job__detail {
  margin-top: 0.625rem;
}

.c-job__detail-info {
  margin-bottom: 1.875rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid #aaaaaa;
}

.c-job__detail-company h5, .c-job__detail-info h5 {
  margin: 0.3125rem 0;
}

.c-job__detail-company, .c-job__detail-application, .c-job__detail-contact {
  margin-top: 1.875rem;
  padding-top: 1.25rem;
  border-top: 1px solid #aaaaaa;
}

.c-job__detail-logo {
  margin: 1.25rem 0;
  max-width: 12.5rem;
}

/* stylelint-disable selector-max-id */
#baguetteBox-overlay {
  background-color: rgba(150, 176, 238, 0.9) !important;
}

#baguetteBox-overlay .full-image {
  padding: 1.5625rem;
}

@media (max-width: 48.0625rem) {
  #baguetteBox-overlay .full-image {
    padding: 0.625rem;
  }
}

#baguetteBox-overlay .full-image figcaption {
  padding: 0.3125rem;
  color: #eee;
}

#baguetteBox-overlay .baguetteBox-button {
  background-color: rgba(255, 255, 255, 0) !important;
}

#baguetteBox-overlay .baguetteBox-button svg polyline,
#baguetteBox-overlay .baguetteBox-button svg g {
  stroke: #255cdd !important;
  stroke-width: 0.5rem !important;
}

#baguetteBox-overlay .baguetteBox-button svg g {
  stroke-width: 0.375rem !important;
}

/* stylelint-enable */
/* ==========================================================================
   #LINKS
   ========================================================================== */
.c-link {
  border-bottom: 0.25rem solid #aaaaaa;
  color: #000;
  display: inline-block;
  text-decoration: none;
}

.c-link:hover {
  text-decoration: none;
  color: #255cdd;
  border-color: #255cdd;
}

.c-link--secondary {
  text-decoration: none;
  color: currentColor;
  border-bottom: none;
}

.c-link--secondary:hover {
  color: currentColor;
}

/* ==========================================================================
   #LISTING (News, Events, etc)
   ========================================================================== */
.c-listing__entry--above {
  margin: 1.25rem 0;
}

.c-listing__entry--within {
  margin: 3.125rem 0;
}

.c-listing__item {
  border-bottom: 0.125rem solid #dddddd;
  margin-bottom: 1.5625rem;
  padding-bottom: 1.5625rem;
  font-size: 1rem;
}

@media (max-width: 64rem) {
  .c-listing__item {
    text-align: center;
  }
  .c-listing__item .c-btn, .c-listing__item .c-content__restricted form input[type=submit], .c-content__restricted form .c-listing__item input[type=submit] {
    float: none !important;
  }
  .c-listing__item img,
  .c-listing__item .c-listing__title,
  .c-listing__item p {
    max-width: 26.25rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-listing__item p {
  font-size: 1rem;
  color: #000;
}

.c-listing__title {
  margin-bottom: 1.875rem;
  font-weight: 700;
  color: #555555;
  font-size: 1.25rem;
}

@media (max-width: 64rem) {
  .c-listing__title {
    margin-bottom: 1.25rem;
  }
}

.c-listing__title--right {
  margin-top: 0;
}

.c-listing__title:hover {
  text-decoration: underline;
}

.c-listing__link {
  text-decoration: none;
}

.c-listing__link:hover {
  text-decoration: none;
}

.c-main-nav {
  position: relative;
  display: block;
  text-align: right;
}

@media (max-width: 64rem) {
  .c-main-nav {
    display: none;
  }
}

.c-main-nav--closed {
  opacity: 0;
}

.c-main-nav__items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.c-header--static.c-header--image .c-main-nav__items {
  padding-top: 1.5625rem;
}

@media (max-width: 80rem) {
  .c-header--static.c-header--image .c-main-nav__items {
    padding-top: 0.625rem;
  }
}

.c-main-nav__item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.3125rem;
  padding: 2.0625rem 0;
  text-align: left;
}

@media (max-width: 72rem) {
  .c-main-nav__item {
    margin: 0 0.1875rem;
  }
}

.c-header--static:not(.c-header--shrink) .c-main-nav__item:not(.c-main-nav__item--transparent) {
  padding: 2.6875rem 0;
}

.c-header--static.c-header--image .c-main-nav__item,
.c-header--none .c-main-nav__item {
  padding: 0 !important;
}

.c-header--static.c-header--image .c-main-nav__item--bordered,
.c-header--none .c-main-nav__item--bordered {
  padding: 0.1875rem 0 !important;
}

.c-header--static.c-header--image .c-main-nav__item--bordered > .c-main-nav__dropdown,
.c-header--none .c-main-nav__item--bordered > .c-main-nav__dropdown {
  left: -0.1875rem;
}

.c-main-nav__item--transparent {
  padding: 0;
}

.c-main-nav__item:hover > .c-main-nav__dropdown, .c-main-nav__item:focus > .c-main-nav__dropdown, .c-main-nav__item.has-dropdown-open > .c-main-nav__dropdown {
  transform: rotate3d(0, 1, 0, 0deg);
}

.c-main-nav__item.menu-item-has-children {
  position: relative;
}

.c-main-nav__item.menu-item-has-children:after, .c-main-nav__item.menu-item-has-children:before {
  position: absolute;
  top: 50%;
  right: 0.625rem;
  transform: translateY(-50%);
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-top: 0.25rem solid;
  z-index: 2;
  transition: all 300ms ease-in-out;
}

@media (max-width: 85.4375rem) {
  .c-main-nav__item.menu-item-has-children:after, .c-main-nav__item.menu-item-has-children:before {
    right: 0.5rem;
  }
}

@media (max-width: 80rem) {
  .c-main-nav__item.menu-item-has-children:after, .c-main-nav__item.menu-item-has-children:before {
    right: 0.3125rem;
  }
}

.c-main-nav__item.menu-item-has-children:before {
  top: auto;
  bottom: -0.375rem;
  left: 0;
  right: 0;
  margin: auto;
  border-top-color: transparent;
}

.c-main-nav__item.menu-item-has-children:hover:before {
  border-top-color: #000;
}

.c-main-nav__item.menu-item-has-children:not(.c-main-nav__item--transparent):before {
  display: none;
}

.c-main-nav__link {
  position: relative;
  display: block;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.c-main-nav--cap-items .c-main-nav__link {
  text-transform: uppercase;
}

@media (max-width: 85.4375rem) {
  .c-main-nav__link {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}

@media (max-width: 80rem) {
  .c-main-nav__link {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

.c-main-nav__item--transparent .c-main-nav__link {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
  border-bottom: 0.25rem solid transparent;
}

.c-header--static:not(.c-header--shrink) .c-main-nav__item--transparent .c-main-nav__link {
  padding-top: 3.375rem;
  padding-bottom: 3.375rem;
}

.c-header--static.c-header--image .c-main-nav__item--transparent .c-main-nav__link,
.c-header--static.c-header--none .c-main-nav__item--transparent .c-main-nav__link,
.c-header--none .c-main-nav__item--transparent .c-main-nav__link {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
}

.c-main-nav__item--bordered .c-main-nav__link {
  outline: solid 3px;
}

.c-main-nav__item.menu-item-has-children .c-main-nav__link {
  padding-right: 1.5625rem;
}

@media (max-width: 85.4375rem) {
  .c-main-nav__item.menu-item-has-children .c-main-nav__link {
    padding-right: 1.25rem;
  }
}

@media (max-width: 80rem) {
  .c-main-nav__item.menu-item-has-children .c-main-nav__link {
    padding-right: 0.9375rem;
  }
}

.c-main-nav__dropdown {
  display: block;
  width: 12.5rem;
  position: absolute;
  top: calc(100%);
  left: 0;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  transform: rotate3d(1, 0, 0, 90deg) perspective(200px);
  transform-origin: top center;
  backface-visibility: hidden;
  transition: all 0.2s ease;
  box-shadow: 0 0.25rem 0.5625rem rgba(0, 0, 0, 0.4);
}

.c-main-nav__dropdown--inverse {
  left: auto;
  right: 0;
}

.c-main-nav__subitem .c-main-nav__dropdown {
  left: 100%;
  top: 0;
  transform: rotate3d(0, 1, 0, -90deg) perspective(200px);
  transform-origin: left center;
}

.c-main-nav__subitem .c-main-nav__dropdown--inverse {
  left: -100%;
  transform: rotate3d(0, 1, 0, 90deg) perspective(200px);
  transform-origin: right center;
}

.c-main-nav--dropdowns-full .c-main-nav__dropdown {
  display: none;
}

.c-main-nav__full-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 1px);
  margin: 0 auto;
  width: 100%;
  height: auto;
  opacity: 0;
  transform: rotate3d(1, 0, 0, 90deg) perspective(200px);
  transform-origin: top center;
  backface-visibility: hidden;
  transition: all 300ms ease-in-out;
}

@media (max-width: 64rem) {
  .c-main-nav__full-dropdown {
    display: none;
  }
}

.c-main-nav__full-dropdown.show {
  transform: rotate3d(0, 1, 0, 0deg);
  opacity: 1;
}

.c-main-nav__full-dropdown-inner {
  margin-bottom: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

@media (max-width: 85.4375rem) {
  .c-main-nav__full-dropdown-inner {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
}

.c-main-nav__full-dropdown--full-1 .c-main-nav__subitem {
  flex-basis: 100%;
  width: 100%;
}

.c-main-nav__full-dropdown--full-2 .c-main-nav__subitem {
  flex-basis: 50%;
  width: 50%;
}

.c-main-nav__full-dropdown--full-3 .c-main-nav__subitem {
  flex-basis: 33.33333%;
  width: 33.33333%;
}

.c-main-nav__full-dropdown--full-4 .c-main-nav__subitem {
  flex-basis: 25%;
  width: 25%;
}

.c-main-nav__full-dropdown .c-main-nav__sublink {
  padding-right: 3.125rem;
}

.c-main-nav__full-dropdown .c-main-nav__dropdown,
.c-main-nav__full-dropdown .c-main-nav__subitem:before,
.c-main-nav__full-dropdown .c-main-nav__subitem:after {
  display: none;
}

.c-main-nav__full-dropdown--show-submenus .c-main-nav__dropdown {
  display: block;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  width: auto;
  transform: none;
  transform-origin: center center;
  backface-visibility: visible;
  box-shadow: none;
}

.c-main-nav__full-dropdown--show-submenus .c-main-nav__dropdown--inverse {
  left: auto;
  right: auto;
}

.c-main-nav__subitem .c-main-nav__full-dropdown--show-submenus .c-main-nav__dropdown {
  width: auto;
  left: auto;
  top: auto;
  transform: none;
  transform-origin: center center;
}

.c-main-nav__subitem .c-main-nav__full-dropdown--show-submenus .c-main-nav__dropdown--inverse {
  left: auto;
  transform: none;
  transform-origin: center center;
}

.c-main-nav__full-dropdown--show-submenus .c-main-nav__dropdown .c-main-nav__subitem {
  width: auto;
  padding-left: 0.625rem;
}

.c-main-nav__full-dropdown--show-submenus .c-main-nav__dropdown .c-main-nav__sublink {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.c-main-nav__subitem {
  position: relative;
  display: block;
  margin-bottom: 0;
}

.c-main-nav--cap-subitems .c-main-nav__subitem {
  text-transform: uppercase;
}

.c-main-nav__subitem:hover > .c-main-nav__dropdown, .c-main-nav__subitem:focus > .c-main-nav__dropdown, .c-main-nav__subitem.has-dropdown-open > .c-main-nav__dropdown {
  transform: rotate3d(0, 1, 0, 0deg);
}

.c-main-nav__subitem--parent:after, .c-main-nav__subitem--parent:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-right: 0.25rem solid transparent;
  transition: all 300ms ease-in-out;
}

.c-main-nav__subitem--parent:before {
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  right: -0.5rem;
  border-bottom: 0.25rem solid transparent;
  border-top: 0.25rem solid transparent;
  border-left: 0.25rem solid transparent;
  z-index: 2;
}

.c-main-nav__subitem--parent:hover:after, .c-main-nav__subitem--parent:focus:after {
  border-right-color: #000;
}

.c-main-nav__subitem--parent:hover:before, .c-main-nav__subitem--parent:focus:before {
  border-left-color: #000;
}

.c-main-nav__subitem--inverse:after, .c-main-nav__subitem--inverse:before {
  right: auto;
  left: -0.25rem;
  border-left: 0.25rem solid transparent;
}

.c-main-nav__subitem--inverse:before {
  right: 0;
  left: -0.5rem;
  border-right-color: transparent;
}

.c-main-nav__subitem--inverse:hover:after, .c-main-nav__subitem--inverse:focus:after {
  border-left-color: transparent;
  border-right-color: #000;
}

.c-main-nav__subitem--inverse:hover:before, .c-main-nav__subitem--inverse:focus:before {
  border-left-color: transparent;
  border-right-color: #000;
}

.c-main-nav__sublink {
  position: relative;
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 0.625rem;
  border: none;
  transition: all 300ms ease-in-out;
}

.c-main-nav__search-trigger {
  position: relative;
  top: 0.125rem;
  border: none;
  background-color: transparent;
  margin-left: 0.9375rem;
  z-index: 7;
  transition: all 300ms ease-in-out;
  transform: scale(1);
}

.c-main-nav__search-trigger--mobile {
  top: 0;
}

@media (max-width: 80rem) {
  .c-main-nav__search-trigger {
    margin-left: 0.3125rem;
  }
}

.c-main-nav__search-trigger:hover, .c-main-nav__search-trigger:focus {
  cursor: pointer;
  transform: scale(1.2);
}

.c-main-nav__search-icon {
  fill: inherit;
  width: 1.5625rem;
  height: 1.5625rem;
}

/* ==========================================================================
   #MEMBERS
   ========================================================================== */
.c-members__categories-buttons li {
  flex: 0 1 auto;
  margin: 0;
}

@media (max-width: 64rem) {
  .c-members__categories-buttons li {
    padding-right: 0;
  }
}

@media (max-width: 48.0625rem) {
  .c-members__categories-buttons {
    display: none;
  }
}

.c-members__categories-dropdown.hide {
  display: none;
}

@media (max-width: 48.0625rem) {
  .c-members__categories-dropdown.hide {
    display: block;
  }
}

.c-member__inner {
  max-width: 420px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  border-bottom: 0.25rem solid #dddddd;
}

@media (max-width: 64rem) {
  .c-member__inner {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 48.0625rem) {
  .c-member__inner {
    max-width: 300px;
  }
}

.c-member__inner p {
  margin: 0;
}

.c-member__title {
  background-color: #dddddd;
  font-size: 1.375rem;
}

.c-member__dept-pos.notempty,
.c-member__address.notempty,
.c-member__hours.notempty,
.c-member__specialty.notempty,
.c-member__info.notempty,
.c-member__phone.notempty {
  padding-bottom: 0.9375rem;
}

.c-member__email.notempty {
  padding-bottom: 0.3125rem;
}

.c-member__content {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.c-member__link {
  display: block;
  color: #000;
  text-decoration: none;
  padding: 0.625rem 1.25rem;
}

.c-member__link:hover {
  color: #000;
}

.c-email__icon,
.c-phone__icon {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  vertical-align: top;
}

.c-member-list-item a {
  transition: all 300ms ease-in-out;
}

.c-member-list-item a:hover {
  color: #255cdd;
}

.c-member-list-item p {
  margin-bottom: 0.625rem;
}

.c-member-list-item__inner {
  padding-top: 0.625rem;
}

@media (max-width: 40rem) {
  .c-member-list-item__inner {
    max-width: 300px !important;
    margin: 0 auto !important;
  }
}

.c-member-list-item__inner:before {
  content: '';
  display: block;
  width: 100%;
  margin-bottom: 0.8125rem;
  margin-left: 0.8125rem;
  margin-right: 0.8125rem;
  border-top: 1px solid #aaaaaa;
}

@media (max-width: 40rem) {
  .c-member-list-item__inner:before {
    margin-left: 0;
    margin-right: 0;
  }
}

.c-member-list-item__image a {
  display: inline-block;
}

@media (max-width: 40rem) {
  .c-member-list-item__image, .c-member-list-item__data {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.c-member-list-item__title {
  padding-bottom: 0.3125rem;
  margin: 0;
  font-weight: 600;
}

@media (max-width: 40rem) {
  .c-member-list-item__title {
    margin-top: 0.625rem;
  }
}

.c-member-list-item__excerpt h2 {
  margin-top: 0;
}

.cform__loader {
  position: relative;
  top: 0;
  display: none;
  margin: auto;
  width: 40px;
  height: 40px;
  background-size: 100%;
}

.cform__loader.show {
  display: block;
}

@media (min-width: 85.4375rem) {
  .c-page-404 h3 {
    padding: 1.875rem 0;
  }
}

.c-skip-to-main-content {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}

.c-skip-to-main-content:focus {
  position: relative;
  opacity: 1;
}

.c-mobile-nav {
  position: fixed;
  top: 0;
  right: -15.625rem;
  bottom: 0;
  display: none;
  width: 15.625rem;
  padding: 0.625rem 0 1.25rem;
  text-align: left;
  z-index: 9;
  overflow-y: auto;
  transition: all 300ms ease-in-out;
  box-shadow: -3px 0 6px -1px rgba(0, 0, 0, 0);
}

@media (max-width: 64rem) {
  .c-mobile-nav {
    display: block;
  }
}

@media (max-width: 40rem) {
  .c-mobile-nav {
    width: 12.5rem;
    right: -12.5rem;
  }
}

.c-mobile-nav--open {
  right: 0;
  box-shadow: -3px 0 6px -1px rgba(0, 0, 0, 0.2);
}

.c-mobile-nav__items, .c-mobile-nav__dropdown {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.c-mobile-nav__item, .c-mobile-nav__subitem {
  margin: 0;
}

.c-mobile-nav__link, .c-mobile-nav__sublink {
  display: block;
  width: 100%;
  padding: 0.625rem;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.c-mobile-nav--cap-items .c-mobile-nav__link {
  text-transform: uppercase;
}

.c-mobile-nav--cap-subitems .c-mobile-nav__sublink {
  text-transform: uppercase;
}

.c-mobile-nav__dropdown .c-mobile-nav__sublink {
  padding-left: 1.25rem;
}

.c-mobile-nav__dropdown .c-mobile-nav__dropdown .c-mobile-nav__sublink {
  padding-left: 1.875rem;
}

.c-mobile-nav__dropdown .c-mobile-nav__dropdown .c-mobile-nav__dropdown .c-mobile-nav__sublink {
  padding-left: 2.5rem;
}

.c-mobile-nav__close {
  margin: 0.625rem;
}

.c-mobile-nav__trigger,
.c-main-nav__trigger,
.c-mobile-nav__close {
  display: block;
  width: 1.875rem;
  height: 20px;
  margin-right: 0.3125rem;
  border: 0;
  border-top-color: #255cdd;
  background: transparent;
  position: relative;
  cursor: pointer;
  transition: transform 0.1s ease;
}

.c-mobile-nav__trigger::after, .c-mobile-nav__trigger::before,
.c-main-nav__trigger::after,
.c-main-nav__trigger::before,
.c-mobile-nav__close::after,
.c-mobile-nav__close::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 0.25rem solid;
  border-top-color: #255cdd;
  transform: translateY(0.1875rem);
  transition: transform 0.1s ease;
}

.c-mobile-nav__trigger::after,
.c-main-nav__trigger::after,
.c-mobile-nav__close::after {
  transform: translateY(0.875rem);
}

.c-mobile-nav__trigger--open,
.c-main-nav--open .c-mobile-nav__trigger,
.c-main-nav__trigger--open,
.c-main-nav--open
.c-main-nav__trigger,
.c-mobile-nav__close--open,
.c-main-nav--open
.c-mobile-nav__close {
  transform: rotate(45deg) translateY(5px);
  border: none;
}

.c-mobile-nav__trigger--open::after,
.c-main-nav--open .c-mobile-nav__trigger::after,
.c-main-nav__trigger--open::after,
.c-main-nav--open
.c-main-nav__trigger::after,
.c-mobile-nav__close--open::after,
.c-main-nav--open
.c-mobile-nav__close::after {
  transform: rotate(-90deg) translateX(-3px);
}

.c-main-nav__trigger {
  width: 2.5rem;
}

.c-main-nav__trigger::after, .c-main-nav__trigger::before {
  border-top-width: 0.3125rem;
}

.c-blocks__main, .c-block__inner, .c-block__content, .c-block__content-inner, .c-block__columns--1 .c-block__columns-column, .c-block__columns--2 .c-block__columns-column, .c-block__columns--3 .c-block__columns-column, .c-block__columns--4 .c-block__columns-column, .c-block__boxes--1 .c-block__boxes-box, .c-block__boxes--2 .c-block__boxes-box, .c-block__boxes--3 .c-block__boxes-box, .c-block__boxes--4 .c-block__boxes-box, .c-block__social-media-widget--1, .c-block__social-media-widget--2, .c-block__social-media-widget--3, .c-block__social-media-widget--4, .c-block__social-media-widget-instafeed--1, .c-block__social-media-widget-instafeed--2, .c-block__social-media-widget-instafeed--3, .c-block__social-media-widget-instafeed--4, .c-block__social-media-widget-instafeed--5, .c-block__social-media-widget-instafeed--6, .c-block__social-media-widget-instafeed--7, .c-block__social-media-widget-instafeed--8, .c-block__social-media-widget-instafeed--9, .c-block__social-media-widget-instafeed--10, .c-block__newsletters-list--cols-1 .c-block__newsletters-list-item, .c-block__newsletters-list--cols-2 .c-block__newsletters-list-item, .c-block__newsletters-list--cols-3 .c-block__newsletters-list-item, .c-block__newsletters-list--cols-4 .c-block__newsletters-list-item {
  min-height: 1px;
  box-sizing: border-box;
  width: 100%;
}

.c-blocks {
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.c-blocks__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

@media (max-width: 64rem) {
  .c-blocks__wrapper {
    flex-flow: row wrap;
  }
}

.c-blocks__wrapper--container {
  max-width: 105rem;
}

.c-blocks--container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  max-width: 105rem;
}

.c-blocks-main, .c-blocks-sidebar {
  min-height: 1px;
}

.c-blocks__main {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: center;
  order: 0;
  width: 100%;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-blocks__main {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-blocks__main--8 {
    width: 66.66667%;
  }
}

.c-blocks__sidebar {
  width: 100%;
  max-width: 26.25rem;
  margin: 0 auto;
}

.c-blocks__sidebar--4 {
  max-width: 35rem;
}

@media (min-width: 64rem) {
  .c-blocks__sidebar {
    width: 25%;
    padding-left: 0.625rem;
  }
  .c-blocks__sidebar--4 {
    width: 33.33333%;
  }
}

@media (min-width: 64rem) {
  .c-blocks__sidebar--left {
    padding-right: 0.625rem;
    order: -1;
    margin: 0;
  }
}

.c-block {
  display: flex;
  position: relative;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  padding: 1.25rem 0;
}

.c-block--w-50 {
  align-items: flex-start;
}

@media (min-width: 48.0625rem) {
  .body__floating-header .c-block--floating-header:not(.c-block--type-slider) {
    padding: 6.25rem 0 3.125rem;
  }
}

@media (min-width: 72rem) {
  .body__floating-header .c-block--floating-header:not(.c-block--type-slider) {
    padding: 5.625rem 0 3.125rem;
  }
}

@media (min-width: 48.0625rem) {
  .body__transparent-header .c-block--floating-header:not(.c-block--type-slider) {
    padding: 3.125rem 0 3.125rem;
  }
}

@media (min-width: 72rem) {
  .body__transparent-header .c-block--floating-header:not(.c-block--type-slider) {
    padding: 3.125rem 0 3.125rem;
  }
}

@media (max-width: 72rem) {
  .c-block--bg-mobile:not(.c-block--floating-header) {
    padding: 0;
  }
}

.c-block--type-slider {
  padding: 0;
}

.c-blocks--has-sidebar .c-block {
  padding: 0 0 0.625rem;
}

.c-block--h-full {
  min-height: 100vh;
}

@media (max-width: 64rem) {
  .c-block--h-full {
    min-height: 0.0625rem;
  }
}

.c-block__header {
  width: 100%;
  position: relative;
  z-index: 3;
  padding: 1.25rem 0;
  margin-bottom: 1.25rem;
}

.c-block__header--block {
  padding: 0.625rem 0.8125rem;
}

.c-block__header--padding {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
}

.c-block__header span[style*="text-decoration"] {
  /* stylelint-disable-line string-quotes, selector-attribute-quotes */
  display: inline-block;
  margin-bottom: 0.3125rem;
  text-decoration: none !important;
  border-bottom: 0.25rem solid #18409d;
}

@media (max-width: 64rem) {
  .c-block__header * {
    text-align: center !important;
  }
}

.c-block--clear {
  width: 100%;
}

.c-block--container {
  max-width: 105rem;
}

@media (min-width: 85.4375rem) {
  .c-block--container {
    max-width: 108.125rem;
  }
}

.c-block__bg-under {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

@media (max-width: 72rem) {
  .c-block__bg-under--hide {
    display: none;
  }
}

.c-block__bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.c-block--container.c-block--no-sidebar .c-block__bg {
  width: calc(100% - 72px);
}

.c-blocks--has-sidebar .c-block__bg {
  width: calc(100% - 26px);
}

.c-block--container.c-block--no-sidebar.c-block--side-left .c-block__bg {
  width: calc(200% - 54px);
  left: 2.25rem;
}

.c-block--side-right.c-block--no-sidebar .c-block__bg {
  width: 150%;
  display: none;
}

.c-block__bg-overlay {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.c-block__bg--parallax {
  background-attachment: fixed;
}

@media (max-width: 72rem) {
  .c-block__bg--mobile {
    position: relative;
    flex-basis: 100%;
    width: 100%;
    max-width: 100%;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    min-height: 28.125rem;
    height: auto;
  }
  .c-block__bg--mobile-above {
    margin: -1.25rem 0 -0.625rem;
  }
}

@media (max-width: 72rem) and (min-width: 48.0625rem) {
  .c-block--floating-header .c-block__bg--mobile-above {
    margin-top: -4.375rem;
  }
}

@media (max-width: 72rem) {
  .c-block__bg--mobile-below {
    order: 9;
    margin: 0 0 -1.25rem;
  }
  .c-block--floating-header .c-block__bg--mobile-below {
    margin-top: -2.5rem;
  }
}

@media (max-width: 72rem) and (min-width: 48.0625rem) {
  .c-block--floating-header .c-block__bg--mobile-below {
    margin-bottom: -4.375rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__bg--mobile {
    min-height: 18.75rem;
  }
}

@media (max-width: 27.5rem) {
  .c-block__bg--mobile {
    min-height: 15.625rem;
  }
}

.c-block__side-bg-image {
  position: absolute;
  max-height: 100%;
  bottom: 0;
}

@media (max-width: 72rem) {
  .c-block__side-bg-image {
    max-height: 31.25rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__side-bg-image {
    max-height: 25rem;
  }
}

.c-block__side-bg--center .c-block__side-bg-image {
  margin: 0 auto;
}

@media (max-width: 64rem) {
  .c-block__side-bg--left {
    justify-content: flex-start;
  }
  .c-block__side-bg--right {
    justify-content: flex-end;
  }
  .c-block__side-bg-center {
    justify-content: center;
  }
}

@media (max-width: 64rem) {
  .c-block__side-bg--mobile .c-block__side-bg-image {
    position: relative;
    display: block;
    right: auto !important;
    left: auto !important;
    top: auto !important;
    bottom: auto !important;
  }
}

@media (max-width: 64rem) {
  .c-block__side-bg--mobile {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .c-block__side-bg--mobile-center {
    justify-content: center;
  }
  .c-block__side-bg--mobile-hide {
    display: none;
  }
  .c-block__side-bg--mobile-below {
    order: 9;
    margin: -1.875rem 0 -1.25rem;
  }
}

@media (max-width: 64rem) and (min-width: 48.0625rem) {
  .c-block--floating-header .c-block__side-bg--mobile-below {
    margin-bottom: -3.125rem;
  }
}

@media (max-width: 64rem) {
  .c-block__side-bg--mobile-above {
    margin: -1.25rem 0 0;
  }
}

@media (max-width: 64rem) and (min-width: 48.0625rem) {
  .c-block--floating-header .c-block__side-bg--mobile-above {
    margin-top: 0;
  }
}

.c-block__inner {
  max-width: 105rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  z-index: 3;
  width: 100%;
  margin: 0 auto;
  min-height: 100px;
  padding: 0.8125rem 0;
}

.c-blocks--has-sidebar .c-block__inner {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

@media (min-width: 105rem) {
  .c-block__inner {
    max-width: 108.125rem;
    padding: 0.8125rem 1.4375rem;
  }
}

@media (min-width: 85.4375rem) {
  .c-blocks__main--has-sidebar .c-block__inner {
    padding: 0.8125rem 0;
  }
}

@media (max-width: 72rem) {
  .c-block--bg-mobile .c-block__inner {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.c-block__inner--full {
  padding: 0;
  width: 100%;
  max-width: 128rem;
}

.c-block--type-slider .c-block__inner--full,
.c-block--type-gallery .c-block__inner--full,
.c-block--type-columns .c-block__inner--full,
.c-block--type-map .c-block__inner--full {
  max-width: 100%;
}

@media (min-width: 80rem) {
  .c-block__inner--align-left {
    justify-content: flex-start;
  }
}

@media (min-width: 80rem) {
  .c-block__inner--align-right {
    justify-content: flex-end;
  }
}

.c-block__inner--overlap {
  z-index: 9;
  transform: translateY(-50%);
  margin-top: -0.625rem;
}

@media (max-width: 64rem) {
  .c-block__inner--overlap {
    transform: none !important;
    margin-top: 0;
    margin-bottom: 0 !important;
  }
}

.c-block__content {
  position: relative;
  z-index: 3;
  padding: 0.8125rem;
}

.c-block__content-inner {
  padding-bottom: 0.625rem;
}

.c-block__content-inner--padding {
  padding: 2.5rem 3.75rem;
}

@media (max-width: 72rem) {
  .c-block__content-inner--padding {
    padding: 1.875rem 3.125rem;
  }
}

@media (max-width: 64rem) {
  .c-block__content-inner--padding {
    padding: 1.25rem 1.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__content-inner--padding {
    padding: 0.8125rem;
  }
}

.c-block__inner--full .c-block__content {
  padding: 0.8125rem 0;
}

.c-block--type-slider .c-block__content {
  padding: 0;
}

.c-block--type-slider .c-block__content .c-block__content-inner {
  padding-bottom: 0;
}

@media (max-width: 72rem) {
  .c-block--bg-mobile .c-block__content {
    padding-top: 0;
  }
}

@media (min-width: 72rem) {
  .c-block--w-75 .c-block__inner, .c-block__content--w-75 {
    max-width: 78.75rem;
    width: 75%;
  }
}

@media (min-width: 85.4375rem) {
  .c-block--w-75 .c-block__inner, .c-block__content--w-75 {
    max-width: 81.09375rem;
  }
}

@media (min-width: 72rem) {
  .c-block--w-66 .c-block__inner, .c-block__content--w-66 {
    max-width: 69.3rem;
    width: 66%;
  }
}

@media (min-width: 85.4375rem) {
  .c-block--w-66 .c-block__inner, .c-block__content--w-66 {
    max-width: 71.3625rem;
  }
}

.c-block--w-50 {
  width: 50%;
  max-width: 50%;
}

@media (max-width: 64rem) {
  .c-block--w-50 {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 72rem) {
  .c-block__content--w-50 {
    max-width: 78.75rem;
    width: 75%;
  }
}

@media (min-width: 80rem) {
  .c-block__content--w-50 {
    max-width: 52.5rem;
    width: 50%;
  }
}

@media (min-width: 85.4375rem) {
  .c-block__content--w-50 {
    max-width: 54.0625rem;
  }
}

div[class*='c-block--h-fixed-5'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-5'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-5'] {
    min-height: 25rem !important;
  }
}

div[class*='c-block--h-fixed-6'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-6'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-6'] {
    min-height: 25rem !important;
  }
}

div[class*='c-block--h-fixed-7'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 85.4375rem) {
  div[class*='c-block--h-fixed-7'] {
    min-height: 37.5rem !important;
  }
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-7'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-7'] {
    min-height: 25rem !important;
  }
}

div[class*='c-block--h-fixed-8'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 85.4375rem) {
  div[class*='c-block--h-fixed-8'] {
    min-height: 37.5rem !important;
  }
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-8'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-8'] {
    min-height: 25rem !important;
  }
}

.c-page__content-wrapper {
  position: relative;
}

.c-page__content-wrapper.c-page__content-wrapper--h-5.c-page__content-wrapper--dynamic {
  left: -0.07813rem;
  width: calc(100% + 2.5px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-5.c-page__content-wrapper--dynamic {
    left: -0.15625rem;
    width: calc(100% + 5px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-5.c-page__content-wrapper--dynamic {
    left: -0.15625rem;
    width: calc(100% + 5px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.15625rem;
  padding-right: 0.15625rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.15625rem;
    padding-right: 0.15625rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-5 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.15625rem;
    padding-right: 0.15625rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-5 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-5 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-5 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.15625rem;
  padding-bottom: 0.15625rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-5 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-5 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-5 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.15625rem;
    padding-bottom: 0.15625rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-5 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-5 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-5 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.15625rem;
    padding-bottom: 0.15625rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-10.c-page__content-wrapper--dynamic {
  left: -0.15625rem;
  width: calc(100% + 5px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-10.c-page__content-wrapper--dynamic {
    left: -0.3125rem;
    width: calc(100% + 10px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-10.c-page__content-wrapper--dynamic {
    left: -0.3125rem;
    width: calc(100% + 10px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-10 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-10 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-10 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-10 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-10 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-10 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-10 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-10 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-10 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-10 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-15.c-page__content-wrapper--dynamic {
  left: -0.23438rem;
  width: calc(100% + 7.5px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-15.c-page__content-wrapper--dynamic {
    left: -0.23438rem;
    width: calc(100% + 7.5px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-15.c-page__content-wrapper--dynamic {
    left: -0.46875rem;
    width: calc(100% + 15px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.23438rem;
  padding-right: 0.23438rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.23438rem;
    padding-right: 0.23438rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-15 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-15 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-15 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-15 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.23438rem;
  padding-bottom: 0.23438rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-15 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-15 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-15 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.23438rem;
    padding-bottom: 0.23438rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-15 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-15 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-15 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-20.c-page__content-wrapper--dynamic {
  left: -0.3125rem;
  width: calc(100% + 10px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-20.c-page__content-wrapper--dynamic {
    left: -0.3125rem;
    width: calc(100% + 10px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-20.c-page__content-wrapper--dynamic {
    left: -0.625rem;
    width: calc(100% + 20px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-20 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-20 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-20 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-20 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-20 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-20 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-20 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-20 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-20 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-20 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-25.c-page__content-wrapper--dynamic {
  left: -0.19531rem;
  width: calc(100% + 6.25px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-25.c-page__content-wrapper--dynamic {
    left: -0.39063rem;
    width: calc(100% + 12.5px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-25.c-page__content-wrapper--dynamic {
    left: -0.78125rem;
    width: calc(100% + 25px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.19531rem;
  padding-right: 0.19531rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.39063rem;
    padding-right: 0.39063rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-25 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.78125rem;
    padding-right: 0.78125rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-25 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-25 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-25 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.19531rem;
  padding-bottom: 0.19531rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-25 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-25 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-25 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.39063rem;
    padding-bottom: 0.39063rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-25 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-25 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-25 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.78125rem;
    padding-bottom: 0.78125rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-30.c-page__content-wrapper--dynamic {
  left: -0.23438rem;
  width: calc(100% + 7.5px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-30.c-page__content-wrapper--dynamic {
    left: -0.46875rem;
    width: calc(100% + 15px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-30.c-page__content-wrapper--dynamic {
    left: -0.9375rem;
    width: calc(100% + 30px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.23438rem;
  padding-right: 0.23438rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-30 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-30 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-30 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-30 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.23438rem;
  padding-bottom: 0.23438rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-30 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-30 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-30 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-30 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-30 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-30 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-35.c-page__content-wrapper--dynamic {
  left: -0.27344rem;
  width: calc(100% + 8.75px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-35.c-page__content-wrapper--dynamic {
    left: -0.54688rem;
    width: calc(100% + 17.5px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-35.c-page__content-wrapper--dynamic {
    left: -1.09375rem;
    width: calc(100% + 35px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.27344rem;
  padding-right: 0.27344rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.54688rem;
    padding-right: 0.54688rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-35 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 1.09375rem;
    padding-right: 1.09375rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-35 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-35 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-35 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.27344rem;
  padding-bottom: 0.27344rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-35 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-35 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-35 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.54688rem;
    padding-bottom: 0.54688rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-35 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-35 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-35 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 1.09375rem;
    padding-bottom: 1.09375rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-40.c-page__content-wrapper--dynamic {
  left: -0.3125rem;
  width: calc(100% + 10px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-40.c-page__content-wrapper--dynamic {
    left: -0.625rem;
    width: calc(100% + 20px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-40.c-page__content-wrapper--dynamic {
    left: -1.25rem;
    width: calc(100% + 40px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-40 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-40 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-40 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-40 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-40 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-40 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-40 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-40 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-40 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-40 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-45.c-page__content-wrapper--dynamic {
  left: -0.35156rem;
  width: calc(100% + 11.25px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-45.c-page__content-wrapper--dynamic {
    left: -0.70313rem;
    width: calc(100% + 22.5px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-45.c-page__content-wrapper--dynamic {
    left: -1.40625rem;
    width: calc(100% + 45px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.35156rem;
  padding-right: 0.35156rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.70313rem;
    padding-right: 0.70313rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-45 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 1.40625rem;
    padding-right: 1.40625rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-45 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-45 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-45 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.35156rem;
  padding-bottom: 0.35156rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-45 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-45 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-45 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.70313rem;
    padding-bottom: 0.70313rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-45 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-45 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-45 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 1.40625rem;
    padding-bottom: 1.40625rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-50.c-page__content-wrapper--dynamic {
  left: -0.39063rem;
  width: calc(100% + 12.5px);
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-50.c-page__content-wrapper--dynamic {
    left: -0.78125rem;
    width: calc(100% + 25px);
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-50.c-page__content-wrapper--dynamic {
    left: -1.5625rem;
    width: calc(100% + 50px);
  }
}

.c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__columns-btn,
.c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__boxes--feature .c-block__boxes-box,
.c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__boxes--feature .c-block__boxes-btn {
  padding-left: 0.39063rem;
  padding-right: 0.39063rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 0.78125rem;
    padding-right: 0.78125rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__columns-btn,
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__boxes--feature .c-block__boxes-box,
  .c-page__content-wrapper.c-page__content-wrapper--h-50 .c-block__boxes--feature .c-block__boxes-btn {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}

.c-page__content-wrapper.c-page__content-wrapper--v-50 .c-block__gallery-item,
.c-page__content-wrapper.c-page__content-wrapper--v-50 .c-block__columns-column,
.c-page__content-wrapper.c-page__content-wrapper--v-50 .c-block__boxes--feature .c-block__boxes-box {
  padding-top: 0.39063rem;
  padding-bottom: 0.39063rem;
}

@media (min-width: 72rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-50 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-50 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-50 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 0.78125rem;
    padding-bottom: 0.78125rem;
  }
}

@media (min-width: 80rem) {
  .c-page__content-wrapper.c-page__content-wrapper--v-50 .c-block__gallery-item,
  .c-page__content-wrapper.c-page__content-wrapper--v-50 .c-block__columns-column,
  .c-page__content-wrapper.c-page__content-wrapper--v-50 .c-block__boxes--feature .c-block__boxes-box {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
}

.owl-carousel .owl-item {
  -ms-transform: translateZ(0);
  /* stylelint-disable-line property-no-vendor-prefix */
  transform: translateZ(0);
}

.owl-nav {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 100%;
}

.owl-nav .icon {
  width: 1.5rem;
  height: 3rem;
  padding-top: 0.3125rem;
  fill: #fff;
}

@media (min-width: 40rem) {
  .owl-nav .icon {
    width: 2rem;
    height: 3.5rem;
  }
}

@media (min-width: 48.0625rem) {
  .owl-nav .icon {
    width: 2.5rem;
    height: 4rem;
    padding-top: 0.5rem;
  }
}

.c-block__columns-arrows--dark .owl-nav .icon,
.c-block__slider-arrows--dark .owl-nav .icon {
  fill: #255cdd;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  position: absolute;
  top: -2rem;
  transition: all 300ms ease-in-out;
}

.owl-nav .owl-prev {
  left: 0.625rem;
}

@media (min-width: 40rem) {
  .owl-nav .owl-prev {
    left: 1.25rem;
  }
}

@media (min-width: 48.0625rem) {
  .owl-nav .owl-prev {
    left: 1.875rem;
  }
}

.owl-nav .owl-prev:hover {
  left: 0.3125rem;
}

@media (min-width: 40rem) {
  .owl-nav .owl-prev:hover {
    left: 0.9375rem;
  }
}

@media (min-width: 48.0625rem) {
  .owl-nav .owl-prev:hover {
    left: 1.5625rem;
  }
}

.owl-nav .owl-next {
  right: 0.625rem;
}

@media (min-width: 40rem) {
  .owl-nav .owl-next {
    right: 1.25rem;
  }
}

@media (min-width: 48.0625rem) {
  .owl-nav .owl-next {
    right: 1.875rem;
  }
}

.owl-nav .owl-next:hover {
  right: 0.3125rem;
}

@media (min-width: 40rem) {
  .owl-nav .owl-next:hover {
    right: 0.9375rem;
  }
}

@media (min-width: 48.0625rem) {
  .owl-nav .owl-next:hover {
    right: 1.5625rem;
  }
}

.owl-dots {
  position: absolute;
  bottom: 0.3125rem;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}

.owl-dots .owl-dot {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin: 0 0.1875rem;
  background-color: #eeeeee;
  border: 0.0625rem solid #18409d;
}

.c-block__slider-dots--dark .owl-dots .owl-dot,
.c-block__gallery-dots--dark .owl-dots .owl-dot,
.c-block__columns-dots--dark .owl-dots .owl-dot {
  background-color: #255cdd;
  border-color: #18409d;
}

.c-block__slider-dots--circle .owl-dots .owl-dot,
.c-block__gallery-dots--circle .owl-dots .owl-dot,
.c-block__columns-dots--circle .owl-dots .owl-dot {
  border-radius: 50%;
}

.owl-dots .owl-dot.active {
  width: 1rem;
  height: 1rem;
  background-color: #255cdd;
  border-color: #18409d;
}

.c-block__slider-dots--dark .owl-dots .owl-dot.active,
.c-block__gallery-dots--dark .owl-dots .owl-dot.active,
.c-block__columns-dots--dark .owl-dots .owl-dot.active {
  background-color: #fff;
  border: 0.0625rem solid #18409d;
}

.c-block__slider--full,
.c-block__slider--full .c-block__slider-slide {
  height: 100vh;
  min-height: 25rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider--full,
  .c-block__slider--full .c-block__slider-slide {
    min-height: 31.25rem;
  }
}

@media (min-width: 64rem) {
  .c-block__slider--full,
  .c-block__slider--full .c-block__slider-slide {
    min-height: 37.5rem;
  }
}

@media (min-width: 85.4375rem) {
  .c-block__slider--full,
  .c-block__slider--full .c-block__slider-slide {
    min-height: 43.75rem;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-5'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-5'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-5'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-5'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-5'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-5'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-6'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-6'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-6'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-6'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-6'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-6'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-7'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-7'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-7'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-7'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-7'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-7'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-8'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-8'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-8'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-8'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-8'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-8'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-9'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-9'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-9'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-9'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-9'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-9'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-10'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-10'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-10'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-10'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-10'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-10'] {
    height: 25rem !important;
  }
}

.c-block__slider-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  display: block;
  width: 3.75rem;
  height: 3.75rem;
  z-index: 3;
}

.c-block__slider-spinner img {
  width: 3.75rem;
  height: 3.75rem;
}

.c-block__slider-slide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  padding: 2.5rem 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-block__slider-slide.item-video {
  display: block;
  padding: 0;
}

.c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
  min-height: 25rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 31.25rem;
  }
  .c-blocks--has-sidebar .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide,
  .c-page__content-shortcode .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 28.125rem;
  }
}

@media (min-width: 64rem) {
  .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 37.5rem;
  }
  .c-blocks--has-sidebar .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide,
  .c-page__content-shortcode .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 30rem;
  }
}

@media (min-width: 85.4375rem) {
  .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 43.75rem;
  }
  .c-blocks--has-sidebar .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide,
  .c-page__content-shortcode .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 31.25rem;
  }
}

.c-block__slider-slide-bg--top-left {
  background-position: top left;
}

.c-block__slider-slide-bg--top-right {
  background-position: top right;
}

.c-block__slider-slide-bg--center {
  background-position: center center;
}

.c-block__slider-slide-bg--btm-left {
  background-position: bottom left;
}

.c-block__slider-slide-bg--btm-right {
  background-position: bottom right;
}

.c-block__slider-slide-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-block__slider-slide-overlay--image {
  background-repeat: no-repeat;
}

.c-block__slider-slide-image-link {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}

.c-block__slider-slide-caption {
  display: flex;
  height: auto;
  width: 100%;
  flex: 0 1 100%;
  justify-content: center;
  padding: 0 2.8125rem;
  z-index: 2;
}

.c-block__slider-slide-caption--top {
  align-self: flex-start;
}

.c-block__slider-slide--full-height-caption .c-block__slider-slide-caption--top {
  align-self: auto;
}

.c-block__slider-slide-caption--bottom {
  align-self: flex-end;
}

.c-block__slider-slide--full-height-caption .c-block__slider-slide-caption--bottom {
  align-self: auto;
}

@media (min-width: 40rem) {
  .c-block__slider-slide-caption {
    padding: 0 5rem;
  }
}

@media (min-width: 48.0625rem) {
  .c-block__slider-slide-caption {
    padding: 0 6.25rem;
  }
}

.c-block__slider-slide-caption-container {
  display: flex;
  justify-content: center;
  max-width: 105rem;
  width: 100%;
  margin: 0 auto;
}

.c-block__slider-slide-caption--left .c-block__slider-slide-caption-container {
  justify-content: flex-start;
}

.c-block__slider-slide-caption--right .c-block__slider-slide-caption-container {
  justify-content: flex-end;
}

.c-block__slider-slide-caption-inner {
  padding: 0.625rem;
}

.c-block__slider--full .c-block__slider-slide-caption-inner {
  max-width: 103.375rem;
}

.c-block__slider-slide-caption--50 {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__slider-slide-caption--50 {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-block__slider-slide-caption--50 {
    width: 50%;
  }
  .c-blocks--has-sidebar .c-block__slider-slide-caption--50 {
    width: 75%;
  }
}

.c-block__slider-slide-caption--66 {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__slider-slide-caption--66 {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-block__slider-slide-caption--66 {
    width: 66%;
  }
  .c-blocks--has-sidebar .c-block__slider-slide-caption--66 {
    width: 75%;
  }
}

.c-block__slider-slide-caption--75 {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__slider-slide-caption--75 {
    width: 75%;
  }
}

.c-block__slider-slide--full-height-caption {
  align-items: stretch;
  justify-content: stretch;
  padding: 0;
}

.c-block__slider-slide--full-height-caption .c-block__slider-slide-caption-inner {
  display: flex;
  align-items: center;
  padding: 2.5rem 0.625rem;
}

.c-block__slider-slide--full-height-caption .c-block__slider-slide-caption--top .c-block__slider-slide-caption-inner {
  align-items: flex-start;
}

.c-block__slider-slide--full-height-caption .c-block__slider-slide-caption--bottom .c-block__slider-slide-caption-inner {
  align-items: flex-end;
}

.c-block__slider-slide-video {
  position: relative;
  height: 100%;
}

.c-block__slider-slide-video--mp4 {
  position: relative;
  height: 100%;
  background-color: #000;
}

.c-block__slider-slide-video--mp4 video {
  display: block;
  height: 100%;
  width: auto;
  max-width: 100%;
  margin: auto;
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}

.c-block__slider-slide-video--mp4 .video-play-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(0.9);
  left: 0;
  right: 0;
  margin: auto;
  width: 4.375rem;
  height: 4.375rem;
  z-index: 9;
  background-image: url("owl.video.play.png");
  background-size: 100% auto;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.c-block__slider-slide-video--mp4 .video-play-btn:hover {
  transform: translateY(-50%) scale(1);
}

.c-block__slider .owl-dots {
  bottom: 2.5rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider .owl-dots {
    bottom: 3.125rem;
  }
}

.c-block__slider-arrows--hover .owl-nav {
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.c-block__slider-arrows--hover:hover .owl-nav {
  opacity: 1;
}

.c-block__slider--carousel {
  padding: 1.25rem 2.1875rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider--carousel {
    padding: 1.25rem 3.125rem;
  }
}

.c-block__slider--carousel.c-block__slider--no-arrows {
  padding-left: 0;
  padding-right: 0;
}

.c-block--full .c-block__slider--carousel {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--2 .c-block__slider-slide {
  height: 20.625rem;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--2 .c-block__slider-slide {
    height: 20.625rem;
  }
}

.c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--3 .c-block__slider-slide {
  height: 18.75rem;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--3 .c-block__slider-slide {
    height: 17.8125rem;
  }
}

.c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--4 .c-block__slider-slide {
  height: 16.875rem;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--4 .c-block__slider-slide {
    height: 15rem;
  }
}

.c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--5 .c-block__slider-slide {
  height: 15rem;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--5 .c-block__slider-slide {
    height: 12.1875rem;
  }
}

.c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--6 .c-block__slider-slide {
  height: 13.125rem;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--6 .c-block__slider-slide {
    height: 9.375rem;
  }
}

.c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--7 .c-block__slider-slide {
  height: 11.25rem;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--7 .c-block__slider-slide {
    height: 6.5625rem;
  }
}

.c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--8 .c-block__slider-slide {
  height: 9.375rem;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-block__slider--carousel.c-block__slider--auto.c-block__slider-items--8 .c-block__slider-slide {
    height: 3.75rem;
  }
}

.c-block__slider--carousel .owl-dots {
  bottom: -0.625rem;
}

.c-block--full .c-block__slider--carousel .owl-dots {
  bottom: 0;
}

.c-block__slider--carousel.c-block__slider--full {
  padding: 0 3.125rem;
}

.c-block__slider--carousel.c-block__slider--full .owl-dots {
  bottom: 2.5rem;
}

.c-block__slider--carousel .owl-prev {
  left: -0.3125rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider--carousel .owl-prev {
    left: 0.3125rem;
  }
}

.c-block__slider--carousel .owl-prev:hover {
  left: -0.625rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider--carousel .owl-prev:hover {
    left: 0;
  }
}

.c-block__slider--carousel .owl-next {
  right: -0.3125rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider--carousel .owl-next {
    right: 0.3125rem;
  }
}

.c-block__slider--carousel .owl-next:hover {
  right: -0.625rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider--carousel .owl-next:hover {
    right: 0;
  }
}

.c-block__gallery--has-dots {
  padding-bottom: 40px;
}

@media (min-width: 48.0625rem) {
  .c-block__gallery .owl-stage-outer {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    margin-top: -3.125rem;
    margin-bottom: -3.125rem;
  }
  .c-block__gallery--cols-3 .owl-stage-outer {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }
  .c-block__gallery--cols-2 .owl-stage-outer {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
    margin-top: -4.375rem;
    margin-bottom: -4.375rem;
  }
}

.c-block__gallery-page {
  display: flex;
  flex-flow: row wrap;
}

.c-block__gallery--masonry .c-block__gallery-page {
  opacity: 0;
}

.c-block__gallery-item {
  z-index: 1;
  transition: all 300ms ease-in-out;
}

.c-block__gallery-item:hover {
  z-index: 2;
}

.c-block__gallery-item--right-edge .c-block__gallery-item-inner {
  transform-origin: right center;
}

.c-block__gallery-item--right-edge .c-block__gallery-item-inner:hover {
  box-shadow: -0.625rem 0 2.5rem -0.3125rem rgba(0, 0, 0, 0.8);
}

.c-block__gallery-item--left-edge .c-block__gallery-item-inner {
  transform-origin: left center;
}

.c-block__gallery-item--left-edge .c-block__gallery-item-inner:hover {
  box-shadow: 0.625rem 0 2.5rem -0.3125rem rgba(0, 0, 0, 0.8);
}

.c-block__gallery--cols-1 .c-block__gallery-item {
  width: 100%;
}

@media (max-width: 27.5rem) {
  .c-block__gallery--cols-1 .c-block__gallery-item {
    width: 100%;
  }
}

.c-block__gallery--cols-1 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 3rem;
  height: 3rem;
}

.c-block--w-50 .c-block__gallery--cols-1 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
.c-block__content--w-50 .c-block__gallery--cols-1 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (min-width: 64rem) {
  .c-block__gallery--cols-1 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .c-block--w-50 .c-block__gallery--cols-1 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-1 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}

@media (min-width: 80rem) {
  .c-block__gallery--cols-1 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 4.5rem;
    height: 4.5rem;
  }
  .c-block--w-50 .c-block__gallery--cols-1 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-1 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2.25rem;
    height: 2.25rem;
  }
}

.c-block__gallery--cols-2 .c-block__gallery-item {
  width: 50%;
}

@media (max-width: 48.0625rem) {
  .c-block__gallery--cols-2 .c-block__gallery-item {
    width: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-block__gallery--cols-2 .c-block__gallery-item {
    width: 100%;
  }
}

.c-block__gallery--cols-2 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 3rem;
  height: 3rem;
}

.c-block--w-50 .c-block__gallery--cols-2 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
.c-block__content--w-50 .c-block__gallery--cols-2 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (min-width: 64rem) {
  .c-block__gallery--cols-2 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .c-block--w-50 .c-block__gallery--cols-2 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-2 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}

@media (min-width: 80rem) {
  .c-block__gallery--cols-2 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 4rem;
    height: 4rem;
  }
  .c-block--w-50 .c-block__gallery--cols-2 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-2 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2rem;
    height: 2rem;
  }
}

.c-block__gallery--cols-3 .c-block__gallery-item {
  width: 33.33333%;
}

@media (max-width: 64rem) {
  .c-block__gallery--cols-3 .c-block__gallery-item {
    width: 33.33333%;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__gallery--cols-3 .c-block__gallery-item {
    width: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-block__gallery--cols-3 .c-block__gallery-item {
    width: 100%;
  }
}

.c-block__gallery--cols-3 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 3rem;
  height: 3rem;
}

.c-block--w-50 .c-block__gallery--cols-3 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
.c-block__content--w-50 .c-block__gallery--cols-3 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (min-width: 64rem) {
  .c-block__gallery--cols-3 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .c-block--w-50 .c-block__gallery--cols-3 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-3 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}

@media (min-width: 80rem) {
  .c-block__gallery--cols-3 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 3.5rem;
    height: 3.5rem;
  }
  .c-block--w-50 .c-block__gallery--cols-3 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-3 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.c-block__gallery--cols-4 .c-block__gallery-item {
  width: 25%;
}

@media (max-width: 80rem) {
  .c-block__gallery--cols-4 .c-block__gallery-item {
    width: 25%;
  }
}

@media (max-width: 64rem) {
  .c-block__gallery--cols-4 .c-block__gallery-item {
    width: 33.33333%;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__gallery--cols-4 .c-block__gallery-item {
    width: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-block__gallery--cols-4 .c-block__gallery-item {
    width: 100%;
  }
}

.c-block__gallery--cols-4 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 3rem;
  height: 3rem;
}

.c-block--w-50 .c-block__gallery--cols-4 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
.c-block__content--w-50 .c-block__gallery--cols-4 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (min-width: 64rem) {
  .c-block__gallery--cols-4 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .c-block--w-50 .c-block__gallery--cols-4 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-4 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}

@media (min-width: 80rem) {
  .c-block__gallery--cols-4 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 3rem;
    height: 3rem;
  }
  .c-block--w-50 .c-block__gallery--cols-4 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-4 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.c-block__gallery--cols-5 .c-block__gallery-item {
  width: 20%;
}

@media (max-width: 80rem) {
  .c-block__gallery--cols-5 .c-block__gallery-item {
    width: 25%;
  }
}

@media (max-width: 64rem) {
  .c-block__gallery--cols-5 .c-block__gallery-item {
    width: 33.33333%;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__gallery--cols-5 .c-block__gallery-item {
    width: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-block__gallery--cols-5 .c-block__gallery-item {
    width: 100%;
  }
}

.c-block__gallery--cols-5 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 3rem;
  height: 3rem;
}

.c-block--w-50 .c-block__gallery--cols-5 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
.c-block__content--w-50 .c-block__gallery--cols-5 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (min-width: 64rem) {
  .c-block__gallery--cols-5 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .c-block--w-50 .c-block__gallery--cols-5 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-5 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}

@media (min-width: 80rem) {
  .c-block__gallery--cols-5 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .c-block--w-50 .c-block__gallery--cols-5 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-5 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.c-block__gallery--cols-6 .c-block__gallery-item {
  width: 16.66667%;
}

@media (max-width: 80rem) {
  .c-block__gallery--cols-6 .c-block__gallery-item {
    width: 25%;
  }
}

@media (max-width: 64rem) {
  .c-block__gallery--cols-6 .c-block__gallery-item {
    width: 33.33333%;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__gallery--cols-6 .c-block__gallery-item {
    width: 50%;
  }
}

@media (max-width: 27.5rem) {
  .c-block__gallery--cols-6 .c-block__gallery-item {
    width: 100%;
  }
}

.c-block__gallery--cols-6 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 3rem;
  height: 3rem;
}

.c-block--w-50 .c-block__gallery--cols-6 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
.c-block__content--w-50 .c-block__gallery--cols-6 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (min-width: 64rem) {
  .c-block__gallery--cols-6 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .c-block--w-50 .c-block__gallery--cols-6 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-6 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1.3rem;
    height: 1.3rem;
  }
}

@media (min-width: 80rem) {
  .c-block__gallery--cols-6 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 2rem;
    height: 2rem;
  }
  .c-block--w-50 .c-block__gallery--cols-6 .c-block__gallery-item .c-block__gallery-item-overlay .icon,
  .c-block__content--w-50 .c-block__gallery--cols-6 .c-block__gallery-item .c-block__gallery-item-overlay .icon {
    width: 1rem;
    height: 1rem;
  }
}

.c-block__gallery-item-inner {
  display: block;
  position: relative;
  transition: all 300ms ease-in-out;
  transform: scale(1);
  box-shadow: 0 0 2.5rem -0.3125rem rgba(0, 0, 0, 0);
}

.c-block__gallery-item:hover .c-block__gallery-item-inner {
  transform: scale(1.15);
  box-shadow: 0 0 2.5rem -0.3125rem rgba(0, 0, 0, 0.8);
}

.c-block__gallery--cols-2 .c-block__gallery-item:hover .c-block__gallery-item-inner {
  transform: scale(1.1);
}

@media (max-width: 64rem) {
  .c-block__gallery-item:hover .c-block__gallery-item-inner {
    transform: scale(1.1);
  }
}

@media (max-width: 48.0625rem) {
  .c-block__gallery-item:hover .c-block__gallery-item-inner {
    transform: none;
    box-shadow: none;
  }
}

.c-block__gallery--cols-1 .c-block__gallery-item:hover .c-block__gallery-item-inner {
  transform: none;
}

.c-block__gallery-item-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: all 300ms ease-in-out;
  opacity: 0;
}

.c-block__gallery-item:hover .c-block__gallery-item-overlay {
  opacity: 1;
}

.c-block__columns {
  display: flex;
  flex-flow: row wrap;
}

.c-block__columns-column {
  margin: 0;
}

.c-block__columns--1 .c-block__columns-column {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__columns--1 .c-block__columns-column {
    width: 50%;
  }
}

@media (min-width: 64rem) {
  .c-block__columns--1 .c-block__columns-column {
    width: 100%;
  }
}

.owl-carousel .c-block__columns-column {
  width: 100%;
}

.c-block__columns--2 .c-block__columns-column {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__columns--2 .c-block__columns-column {
    width: 50%;
  }
}

@media (min-width: 64rem) {
  .c-block__columns--2 .c-block__columns-column {
    width: 50%;
  }
}

.owl-carousel .c-block__columns-column {
  width: 100%;
}

.c-block__columns--3 .c-block__columns-column {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__columns--3 .c-block__columns-column {
    width: 33.33333%;
  }
}

@media (min-width: 64rem) {
  .c-block__columns--3 .c-block__columns-column {
    width: 33.33333%;
  }
}

.owl-carousel .c-block__columns-column {
  width: 100%;
}

.c-block__columns--4 .c-block__columns-column {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__columns--4 .c-block__columns-column {
    width: 50%;
  }
}

@media (min-width: 64rem) {
  .c-block__columns--4 .c-block__columns-column {
    width: 25%;
  }
}

.owl-carousel .c-block__columns-column {
  width: 100%;
}

.c-block__columns-column-inner {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  padding-bottom: 0.625rem;
}

.c-block__columns--4 .c-block__columns-column-inner {
  padding-bottom: 0.3125rem;
}

.c-block__columns-column-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.c-block__columns-column-header {
  position: relative;
  z-index: 3;
  padding: 0 0 0.625rem;
}

.c-block__columns-column-header * {
  margin: 0;
}

.c-block__columns-column .c-book__cover {
  width: 35%;
  max-width: 10.625rem;
}

.c-block__columns-column-title, .c-block__columns-column-image, .c-block__columns-column-text, .c-block__columns-column-meta {
  position: relative;
  display: block;
  margin: 0;
  z-index: 3;
}

.c-block__columns-column-image {
  padding: 0 0 0.3125rem;
  text-align: center;
}

.c-block__columns--custom .c-block__columns-column-image {
  padding: 0;
}

.c-block__columns-column-header, .c-block__columns-column-title, .c-block__columns-column-btn, .c-block__columns-column-text, .c-block__columns-column-meta {
  padding: 0.625rem 0;
}

.c-block__columns-column-inner--has-bg .c-block__columns-column-header, .c-block__columns-column-inner--has-bg .c-block__columns-column-title, .c-block__columns-column-inner--has-bg .c-block__columns-column-btn, .c-block__columns-column-inner--has-bg .c-block__columns-column-text, .c-block__columns-column-inner--has-bg .c-block__columns-column-meta {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.c-block__columns--4 .c-block__columns-column-inner--has-bg .c-block__columns-column-header, .c-block__columns--4 .c-block__columns-column-inner--has-bg .c-block__columns-column-title, .c-block__columns--4 .c-block__columns-column-inner--has-bg .c-block__columns-column-btn, .c-block__columns--4 .c-block__columns-column-inner--has-bg .c-block__columns-column-text, .c-block__columns--4 .c-block__columns-column-inner--has-bg .c-block__columns-column-meta {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.c-block__columns-column-title {
  padding-top: 1.25rem;
  color: #255cdd;
}

.c-book__info .c-block__columns-column-title {
  padding-top: 0.625rem;
}

.c-block__columns-column-title * {
  margin: 0;
}

.c-block__columns-column-title a {
  color: inherit;
  text-decoration: none;
}

.c-block__columns-column-title img {
  margin: 0 !important;
}

.c-block__columns-column-meta {
  font-size: 0.9375rem;
}

.c-block__columns-column-video {
  margin: auto;
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
  background-color: #000;
}

.c-block__columns-column-video iframe,
.c-block__columns-column-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-block__columns-column-video video {
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}

.c-block__columns-column-video .video-play-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(0.9);
  left: 0;
  right: 0;
  margin: auto;
  width: 4.375rem;
  height: 4.375rem;
  z-index: 9;
  background-image: url("owl.video.play.png");
  background-size: 100% auto;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.c-block__columns-column-video .video-play-btn:hover {
  transform: translateY(-50%) scale(1);
}

.c-block__columns-column .c-text--bg {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

.c-block__columns-column .c-text--bg * {
  position: relative;
  z-index: 3;
}

.c-block__columns-column .c-text--bg::before {
  position: absolute;
  top: 0;
  right: -1.25rem;
  bottom: 0;
  left: -1.25rem;
  content: '';
  display: block;
  background-color: inherit;
}

.c-block__columns-column-inner-post--image-overlay {
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.c-block__columns-column-inner-post--animation {
  position: relative;
  overflow: hidden;
  margin-bottom: 1.25rem;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-title,
.c-block__columns-column-inner-post--animation .c-block__columns-column-text,
.c-block__columns-column-inner-post--animation .c-block__columns-column-btn {
  position: absolute;
  z-index: 6;
  width: 100%;
  left: 0;
  right: 0;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-title {
  bottom: 20px;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-title--light {
  color: #fff;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-text {
  top: 100%;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-text--light {
  color: #fff;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-text a {
  text-decoration: none;
  color: inherit;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-btn {
  bottom: -100%;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-btn--light .c-btn--arrow-secondary,
.c-block__columns-column-inner-post--animation .c-block__columns-column-btn--light .c-btn--arrow-primary {
  color: #fff;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-btn--light .c-icon {
  fill: #fff;
}

.c-block__columns-column-inner-post--animation .c-block__columns-column-image {
  position: relative;
  padding: 0;
}

.c-block__columns-btn {
  width: 100%;
  padding: 1.25rem 0;
}

.c-block__columns-btn a {
  margin: 0 0.625rem;
}

@media (max-width: 48.0625rem) {
  .c-block__columns-btn {
    text-align: center !important;
  }
}

.c-block__columns-mixed-btn--ajax {
  display: none;
}

.c-block__columns--has-nav {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
}

.c-block__columns--has-nav + .c-block__columns-btn a {
  margin: 0 2.1875rem;
}

@media (min-width: 40rem) {
  .c-block__columns--has-nav {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .c-block__columns--has-nav + .c-block__columns-btn a {
    margin: 0 2.5rem;
  }
}

@media (min-width: 48.0625rem) {
  .c-block__columns--has-nav {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .c-block__columns--has-nav + .c-block__columns-btn a {
    margin: 0 3.75rem;
  }
}

.c-block__columns--has-dots {
  padding-bottom: 2.5rem;
}

.c-block__columns.owl-carousel {
  position: relative;
}

.c-block__columns.owl-carousel .owl-stage,
.c-block__columns.owl-carousel .owl-item {
  display: flex;
  align-content: stretch;
}

.c-block__columns.owl-carousel.owl-show-nav .owl-nav {
  display: block !important;
}

.c-block__columns.owl-carousel .owl-nav .owl-prev {
  left: 0.3125rem;
}

@media (min-width: 48.0625rem) {
  .c-block__columns.owl-carousel .owl-nav .owl-prev {
    left: 1.25rem;
  }
}

.c-block__columns.owl-carousel .owl-nav .owl-prev:hover {
  left: 0.125rem;
}

@media (min-width: 48.0625rem) {
  .c-block__columns.owl-carousel .owl-nav .owl-prev:hover {
    left: 1rem;
  }
}

.c-block__columns.owl-carousel .owl-nav .owl-next {
  right: 0.3125rem;
}

@media (min-width: 48.0625rem) {
  .c-block__columns.owl-carousel .owl-nav .owl-next {
    right: 1.25rem;
  }
}

.c-block__columns.owl-carousel .owl-nav .owl-next:hover {
  right: 0.125rem;
}

@media (min-width: 48.0625rem) {
  .c-block__columns.owl-carousel .owl-nav .owl-next:hover {
    right: 1rem;
  }
}

.c-block__boxes {
  display: flex;
  flex-flow: row wrap;
}

.c-block__boxes--feature {
  justify-content: flex-start;
}

.c-block__boxes--square .c-block__boxes-box {
  padding: 1.25rem;
}

@media (min-width: 80rem) {
  .c-block__boxes--square .c-block__boxes-box {
    padding: 1.875rem;
  }
}

.c-block__boxes--1 .c-block__boxes-box {
  width: 100%;
}

@media (min-width: 85rem) {
  .c-block__boxes--1 .c-block__boxes-box {
    width: 100%;
  }
  .c-blocks--has-sidebar .c-block__boxes--1 .c-block__boxes-box {
    width: 100%;
  }
}

.c-block__boxes--feature.c-block__boxes--1 .c-block__boxes-box {
  text-align: center;
}

.c-block__boxes--2 .c-block__boxes-box {
  width: 100%;
}

@media (min-width: 53.75rem) {
  .c-block__boxes--2 .c-block__boxes-box {
    width: 50%;
  }
}

@media (min-width: 85rem) {
  .c-block__boxes--2 .c-block__boxes-box {
    width: 50%;
  }
  .c-blocks--has-sidebar .c-block__boxes--2 .c-block__boxes-box {
    width: 50%;
  }
}

.c-block__boxes--feature.c-block__boxes--2 .c-block__boxes-box {
  text-align: center;
}

@media (min-width: 72rem) {
  .c-block__boxes--feature.c-block__boxes--2 .c-block__boxes-box:nth-child(2n+1) {
    text-align: left;
  }
}

@media (min-width: 72rem) {
  .c-block__boxes--feature.c-block__boxes--2 .c-block__boxes-box:nth-child(2n+2) {
    text-align: right;
  }
}

.c-block__boxes--3 .c-block__boxes-box {
  width: 100%;
}

@media (min-width: 53.75rem) {
  .c-block__boxes--3 .c-block__boxes-box {
    width: 50%;
  }
}

@media (min-width: 72rem) {
  .c-block__boxes--3 .c-block__boxes-box {
    width: 33.33333%;
  }
  .c-blocks--has-sidebar .c-block__boxes--3 .c-block__boxes-box {
    width: 50%;
  }
}

@media (min-width: 85rem) {
  .c-block__boxes--3 .c-block__boxes-box {
    width: 33.33333%;
  }
  .c-blocks--has-sidebar .c-block__boxes--3 .c-block__boxes-box {
    width: 33.33333%;
  }
}

.c-block__boxes--feature.c-block__boxes--3 .c-block__boxes-box {
  text-align: center;
}

@media (min-width: 72rem) {
  .c-block__boxes--feature.c-block__boxes--3 .c-block__boxes-box:nth-child(3n+1) {
    text-align: left;
  }
}

@media (min-width: 72rem) {
  .c-block__boxes--feature.c-block__boxes--3 .c-block__boxes-box:nth-child(3n+3) {
    text-align: right;
  }
}

.c-block__boxes--4 .c-block__boxes-box {
  width: 100%;
}

@media (min-width: 53.75rem) {
  .c-block__boxes--4 .c-block__boxes-box {
    width: 50%;
  }
}

@media (min-width: 72rem) {
  .c-block__boxes--4 .c-block__boxes-box {
    width: 33.33333%;
  }
}

@media (min-width: 85rem) {
  .c-block__boxes--4 .c-block__boxes-box {
    width: 25%;
  }
  .c-blocks--has-sidebar .c-block__boxes--4 .c-block__boxes-box {
    width: 25%;
  }
}

@media (min-width: 64rem) {
  .c-block__boxes--square.c-block__boxes--4 .c-block__boxes-box {
    width: 25%;
  }
}

.c-block__boxes--feature.c-block__boxes--4 .c-block__boxes-box {
  text-align: center;
}

@media (min-width: 72rem) {
  .c-block__boxes--feature.c-block__boxes--4 .c-block__boxes-box:nth-child(4n+1) {
    text-align: left;
  }
}

@media (min-width: 72rem) {
  .c-block__boxes--feature.c-block__boxes--4 .c-block__boxes-box:nth-child(4n+4) {
    text-align: right;
  }
}

.c-block__boxes-box-inner {
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  transition: all 300ms ease-in-out;
}

.c-block__boxes--square .c-block__boxes-box-inner {
  max-width: 15.625rem;
  margin: auto;
}

@media (min-width: 64rem) {
  .c-block__boxes--square .c-block__boxes-box-inner {
    max-width: 18.75rem;
  }
}

.c-block__boxes--square .c-block__boxes-box-inner:before {
  content: '';
  display: block;
  padding-top: 100%;
  /* ratio of 1:1 */
  /* stylelint-disable-line comment-whitespace-inside */
}

.c-block__boxes--square .c-block__boxes-box-inner--small {
  max-width: 13.4375rem;
}

.c-block__boxes--square .c-block__boxes-box-inner--medium {
  max-width: 13.75rem;
}

@media (min-width: 64rem) {
  .c-block__boxes--square .c-block__boxes-box-inner--medium {
    max-width: 15.625rem;
  }
}

.c-block__boxes--feature .c-block__boxes-box-inner {
  height: 100%;
  max-width: 26.25rem;
  display: inline-block;
  text-align: left;
}

.c-block__boxes-box-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 300ms ease-in-out;
}

.c-block__boxes-box-content {
  z-index: 3;
}

.c-block__boxes--square .c-block__boxes-box-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 100%;
  padding: 0.625rem;
  text-align: center;
  font-size: 1.5rem;
}

.c-block__boxes--feature .c-block__boxes-box-content {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.c-block__boxes-box-icon, .c-block__boxes-box-header, .c-block__boxes-box-text {
  padding: 0.625rem 1.25rem 0;
}

.c-block__boxes-box-bg--none .c-block__boxes-box-icon, .c-block__boxes-box-bg--none .c-block__boxes-box-header, .c-block__boxes-box-bg--none .c-block__boxes-box-text {
  padding-left: 0;
  padding-right: 0;
}

.c-block__boxes-box-icon {
  display: flex;
  align-items: center;
  height: 2.6875rem;
  margin-bottom: 0.3125rem;
}

.c-block__boxes-box-icon img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
  height: auto;
}

.c-block__boxes-box--animate .c-block__boxes-box-header {
  padding: 0.625rem 1.25rem 0;
}

.c-block__boxes-box--animate .c-block__boxes-box-text {
  padding: 0 1.25rem 0.625rem;
}

.c-block__boxes-btn {
  width: 100%;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

@media (max-width: 72rem) {
  .c-block__boxes-btn {
    text-align: center !important;
  }
}

.c-page__block--overlap .c-block__boxes-btn {
  display: none;
}

.c-block__tabs-nav {
  margin-bottom: 0;
  padding: 0 1.875rem;
}

.c-block__tabs-nav li {
  margin-left: -0.3125rem;
  margin-bottom: 0;
  border: 0.0625rem solid #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav li {
  border-color: #2c2c2c;
}

.c-block__tabs-nav .active a {
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav .active a {
  background-color: #2c2c2c;
  color: #fff;
}

.c-block__tabs-nav a {
  display: block;
  padding: 1.25rem;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav a {
  background-color: #454545;
  color: #fff;
}

.c-block__tabs-nav a:hover {
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav a:hover {
  background-color: #2c2c2c;
  color: #fff;
}

.c-block__tabs-content {
  padding: 1.25rem 1.875rem 0.3125rem;
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-content {
  background-color: #2c2c2c;
  color: #fff;
}

.c-block__tabs-tab, .c-block__tabs-accordion-content {
  display: none;
}

.c-block__tabs-tab.in, .c-block__tabs-accordion-content.in {
  display: block;
}

.c-block__tabs-accordion-header {
  margin: 0;
}

.c-block__tabs-accordion-header a {
  display: block;
  padding: 0.9375rem;
  border-bottom: 0.0625rem solid #aaaaaa;
  background-color: #fff;
  color: inherit;
  text-decoration: none;
}

.c-block__tabs--dark .c-block__tabs-accordion-header a {
  background-color: #454545;
  color: #fff;
}

.c-block__tabs-accordion-header .c-arrow-icon {
  margin-top: 0.3125rem;
  display: block;
  width: 1rem;
  height: 1rem;
  fill: #2c2c2c;
}

.c-block__tabs--dark .c-block__tabs-accordion-header .c-arrow-icon {
  fill: #eeeeee;
}

.c-block__tabs-accordion-header .c-arrow-icon.c-arrow-up {
  display: none;
}

.c-block__tabs-accordion-header.active a {
  border-bottom: 1px solid #fff;
  background-color: #fff;
}

.c-block__tabs--dark .c-block__tabs-accordion-header.active a {
  background-color: #2c2c2c;
  color: #fff;
}

.c-block__tabs-accordion-header.active .c-arrow-down {
  display: none;
}

.c-block__tabs-accordion-header.active .c-arrow-up {
  display: block;
}

.c-block__tabs-accordion-content {
  padding: 0.9375rem 1.25rem 0.3125rem;
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-accordion-content {
  background-color: #2c2c2c;
  color: #fff;
}

.c-block__testimonials {
  position: relative;
  max-width: calc(100% - 70px);
  margin: auto;
  display: none;
}

.c-block__testimonials .owl-stage-outer {
  margin: auto;
}

.c-block__testimonials .owl-stage,
.c-block__testimonials .owl-item {
  display: flex;
}

.c-block__testimonials .owl-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: none;
  opacity: 1;
}

.c-block__testimonials .owl-nav .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5625rem;
  height: 3rem;
  padding: 0;
  margin-left: 0.25rem;
  fill: #fff;
}

.c-block__testimonials .owl-nav .owl-next .icon {
  margin-left: 0.5625rem;
}

.c-block__testimonials .owl-nav .owl-prev,
.c-block__testimonials .owl-nav .owl-next {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2.5rem;
  height: 100%;
  background-color: #eeeeee;
  transition: all 0.3s ease-in-out;
}

.c-block__testimonials .owl-nav .owl-prev:hover,
.c-block__testimonials .owl-nav .owl-next:hover {
  background-color: #aaaaaa;
}

.c-block__testimonials .owl-nav .owl-prev.invisible, .c-block__testimonials .owl-nav .owl-prev.invisible:hover,
.c-block__testimonials .owl-nav .owl-next.invisible,
.c-block__testimonials .owl-nav .owl-next.invisible:hover {
  background-color: #eeeeee;
}

.c-block__testimonials .owl-nav .owl-prev.invisible .icon, .c-block__testimonials .owl-nav .owl-prev.invisible:hover .icon,
.c-block__testimonials .owl-nav .owl-next.invisible .icon,
.c-block__testimonials .owl-nav .owl-next.invisible:hover .icon {
  display: none;
}

.c-block__testimonials .owl-nav .owl-prev,
.c-block__testimonials .owl-nav .owl-prev:hover {
  left: -2.1875rem;
}

.c-block__testimonials .owl-nav .owl-next,
.c-block__testimonials .owl-nav .owl-next:hover {
  right: -2.1875rem;
}

.c-block__testimonials-item {
  width: 100%;
  position: relative;
  padding: 3.125rem 5rem 1.875rem 5.3125rem;
  background-color: #eeeeee;
}

.c-block__testimonials-item .icon-quote {
  position: absolute;
  top: 2.1875rem;
  left: 1.25rem;
  width: 3rem;
  height: 3rem;
  fill: #d6d6d6;
}

.c-block__testimonials-meta {
  margin-top: 1.5625rem;
}

.c-block__testimonials-meta img {
  float: left;
  width: 3.75rem !important;
}

.c-block__testimonials-info {
  overflow: hidden;
}

.c-block__testimonials-info p {
  margin: 0;
  padding-left: 0.625rem;
  font-size: 0.875rem;
}

.c-block--h-full .c-block__map .js-google-map {
  height: 100vh !important;
}

.c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-3'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

.c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-4'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-4'] {
    height: 300px !important;
  }
}

.c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-5'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 72rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-5'] {
    height: 400px !important;
  }
}

@media (max-width: 64rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-5'] {
    height: 300px !important;
  }
}

.c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-6'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 80rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-6'] {
    height: 600px !important;
  }
}

@media (max-width: 72rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-6'] {
    height: 400px !important;
  }
}

@media (max-width: 64rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-6'] {
    height: 300px !important;
  }
}

.c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-7'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 80rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-7'] {
    height: 600px !important;
  }
}

@media (max-width: 72rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-7'] {
    height: 400px !important;
  }
}

@media (max-width: 64rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-7'] {
    height: 300px !important;
  }
}

.c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-8'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 80rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-8'] {
    height: 600px !important;
  }
}

@media (max-width: 72rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-8'] {
    height: 400px !important;
  }
}

@media (max-width: 64rem) {
  .c-block:not(.c-block--h-full) .c-block__map div[class*='map--h-8'] {
    height: 300px !important;
  }
}

.c-block__files-entry {
  padding: 0.9375rem 0;
}

.c-block__files-file img {
  display: inline-block;
  width: 1.25rem;
  margin-right: 0.625rem;
}

.c-block__call-to-action-content {
  width: 100%;
  box-sizing: border-box;
}

.c-block__call-to-action ul, .c-block__call-to-action ol {
  overflow: hidden;
  padding-left: 1.25rem;
  margin-left: 1.25rem;
}

.c-block__social-media {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.c-block__social-media--1 {
  justify-content: center;
}

.c-block__social-media--2 {
  justify-content: space-around;
}

.c-block__social-media-widget {
  text-align: center;
  padding: 0.625rem;
  margin: 0.9375rem 0;
}

.c-block__social-media-widget--1 {
  width: 100%;
}

@media (min-width: 40rem) {
  .c-block__social-media-widget--1 {
    width: 50%;
  }
}

@media (min-width: 72rem) {
  .c-block__social-media-widget--1 {
    width: 100%;
  }
}

.c-block__social-media-widget--2 {
  width: 100%;
}

@media (min-width: 40rem) {
  .c-block__social-media-widget--2 {
    width: 50%;
  }
}

@media (min-width: 72rem) {
  .c-block__social-media-widget--2 {
    width: 50%;
  }
}

.c-block__social-media-widget--3 {
  width: 100%;
}

@media (min-width: 40rem) {
  .c-block__social-media-widget--3 {
    width: 50%;
  }
}

@media (min-width: 72rem) {
  .c-block__social-media-widget--3 {
    width: 33.33333%;
  }
}

.c-block__social-media-widget--4 {
  width: 100%;
}

@media (min-width: 40rem) {
  .c-block__social-media-widget--4 {
    width: 50%;
  }
}

@media (min-width: 72rem) {
  .c-block__social-media-widget--4 {
    width: 25%;
  }
}

.c-block__social-media-widget-box {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.c-block__social-media-widget-video {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 1.875rem;
}

.c-block__social-media-widget-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
}

.c-block__social-media-widget-instafeed {
  display: flex;
  flex-flow: row wrap;
  margin: auto;
}

.c-block__social-media-widget-instafeed--1 a {
  flex-basis: 100%;
}

.c-block__social-media-widget-instafeed--1.c-block__social-media-widget-instafeed--small {
  max-width: 150px;
}

.c-block__social-media-widget-instafeed--1.c-block__social-media-widget-instafeed--medium {
  max-width: 300px;
}

.c-block__social-media-widget-instafeed--1.c-block__social-media-widget-instafeed--large {
  max-width: 600px;
}

.c-block__social-media-widget-instafeed--2 a {
  flex-basis: 50%;
}

.c-block__social-media-widget-instafeed--2.c-block__social-media-widget-instafeed--small {
  max-width: 300px;
}

.c-block__social-media-widget-instafeed--2.c-block__social-media-widget-instafeed--medium {
  max-width: 600px;
}

.c-block__social-media-widget-instafeed--2.c-block__social-media-widget-instafeed--large {
  max-width: 1200px;
}

.c-block__social-media-widget-instafeed--3 a {
  flex-basis: 33.33333%;
}

.c-block__social-media-widget-instafeed--3.c-block__social-media-widget-instafeed--small {
  max-width: 450px;
}

.c-block__social-media-widget-instafeed--3.c-block__social-media-widget-instafeed--medium {
  max-width: 900px;
}

.c-block__social-media-widget-instafeed--3.c-block__social-media-widget-instafeed--large {
  max-width: 1800px;
}

.c-block__social-media-widget-instafeed--4 a {
  flex-basis: 25%;
}

.c-block__social-media-widget-instafeed--4.c-block__social-media-widget-instafeed--small {
  max-width: 600px;
}

.c-block__social-media-widget-instafeed--4.c-block__social-media-widget-instafeed--medium {
  max-width: 1200px;
}

.c-block__social-media-widget-instafeed--4.c-block__social-media-widget-instafeed--large {
  max-width: 2400px;
}

.c-block__social-media-widget-instafeed--5 a {
  flex-basis: 20%;
}

.c-block__social-media-widget-instafeed--5.c-block__social-media-widget-instafeed--small {
  max-width: 750px;
}

.c-block__social-media-widget-instafeed--5.c-block__social-media-widget-instafeed--medium {
  max-width: 1500px;
}

.c-block__social-media-widget-instafeed--5.c-block__social-media-widget-instafeed--large {
  max-width: 3000px;
}

.c-block__social-media-widget-instafeed--6 a {
  flex-basis: 16.66667%;
}

.c-block__social-media-widget-instafeed--6.c-block__social-media-widget-instafeed--small {
  max-width: 900px;
}

.c-block__social-media-widget-instafeed--6.c-block__social-media-widget-instafeed--medium {
  max-width: 1800px;
}

.c-block__social-media-widget-instafeed--6.c-block__social-media-widget-instafeed--large {
  max-width: 3600px;
}

.c-block__social-media-widget-instafeed--7 a {
  flex-basis: 14.28571%;
}

.c-block__social-media-widget-instafeed--7.c-block__social-media-widget-instafeed--small {
  max-width: 1050px;
}

.c-block__social-media-widget-instafeed--7.c-block__social-media-widget-instafeed--medium {
  max-width: 2100px;
}

.c-block__social-media-widget-instafeed--7.c-block__social-media-widget-instafeed--large {
  max-width: 4200px;
}

.c-block__social-media-widget-instafeed--8 a {
  flex-basis: 12.5%;
}

.c-block__social-media-widget-instafeed--8.c-block__social-media-widget-instafeed--small {
  max-width: 1200px;
}

.c-block__social-media-widget-instafeed--8.c-block__social-media-widget-instafeed--medium {
  max-width: 2400px;
}

.c-block__social-media-widget-instafeed--8.c-block__social-media-widget-instafeed--large {
  max-width: 4800px;
}

.c-block__social-media-widget-instafeed--9 a {
  flex-basis: 11.11111%;
}

.c-block__social-media-widget-instafeed--9.c-block__social-media-widget-instafeed--small {
  max-width: 1350px;
}

.c-block__social-media-widget-instafeed--9.c-block__social-media-widget-instafeed--medium {
  max-width: 2700px;
}

.c-block__social-media-widget-instafeed--9.c-block__social-media-widget-instafeed--large {
  max-width: 5400px;
}

.c-block__social-media-widget-instafeed--10 a {
  flex-basis: 10%;
}

.c-block__social-media-widget-instafeed--10.c-block__social-media-widget-instafeed--small {
  max-width: 1500px;
}

.c-block__social-media-widget-instafeed--10.c-block__social-media-widget-instafeed--medium {
  max-width: 3000px;
}

.c-block__social-media-widget-instafeed--10.c-block__social-media-widget-instafeed--large {
  max-width: 6000px;
}

.c-block__social-media-widget-instafeed a {
  display: block;
  padding: 0;
  text-decoration: none;
  background-color: #eeeeee;
}

.c-block__social-media-widget-instafeed span {
  display: block;
  padding: 0.3125rem;
  color: #454545;
  font-size: 0.875rem;
}

.c-block__newsletters-entry {
  padding: 0.625rem 0.9375rem 0;
}

.c-block__newsletters-list {
  display: flex;
  flex-flow: row wrap;
  padding: 1.25rem 0;
}

.c-block__newsletters-list-item {
  display: flex;
  padding: 1.25rem 0.9375rem;
}

.c-block__newsletters-list--cols-1 .c-block__newsletters-list-item {
  width: 100%;
}

.c-block__newsletters-list--cols-1 .c-block__newsletters-list-item:nth-child(1n+2), .c-block__newsletters-list--cols-1 .c-block__newsletters-list-item:nth-child(1n+3) {
  justify-content: center;
}

.c-block__newsletters-list--cols-1 .c-block__newsletters-list-item:nth-child(1n+1) {
  justify-content: flex-start;
}

.c-block__newsletters-list--cols-1 .c-block__newsletters-list-item:nth-child(1n+1) {
  justify-content: flex-end;
  justify-content: center;
}

@media (min-width: 85rem) {
  .c-block__newsletters-list--cols-1 .c-block__newsletters-list-item {
    width: 100%;
    flex-basis: 100%;
  }
  .c-blocks--has-sidebar .c-block__newsletters-list--cols-1 .c-block__newsletters-list-item {
    width: 100%;
    flex-basis: 100%;
  }
}

.c-block__newsletters-list--cols-2 .c-block__newsletters-list-item {
  width: 100%;
}

.c-block__newsletters-list--cols-2 .c-block__newsletters-list-item:nth-child(2n+2), .c-block__newsletters-list--cols-2 .c-block__newsletters-list-item:nth-child(2n+3) {
  justify-content: center;
}

.c-block__newsletters-list--cols-2 .c-block__newsletters-list-item:nth-child(2n+1) {
  justify-content: flex-start;
}

.c-block__newsletters-list--cols-2 .c-block__newsletters-list-item:nth-child(2n+2) {
  justify-content: flex-end;
}

@media (min-width: 53.75rem) {
  .c-block__newsletters-list--cols-2 .c-block__newsletters-list-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (min-width: 85rem) {
  .c-block__newsletters-list--cols-2 .c-block__newsletters-list-item {
    width: 50%;
    flex-basis: 50%;
  }
  .c-blocks--has-sidebar .c-block__newsletters-list--cols-2 .c-block__newsletters-list-item {
    width: 50%;
    flex-basis: 50%;
  }
}

.c-block__newsletters-list--cols-3 .c-block__newsletters-list-item {
  width: 100%;
}

.c-block__newsletters-list--cols-3 .c-block__newsletters-list-item:nth-child(3n+2), .c-block__newsletters-list--cols-3 .c-block__newsletters-list-item:nth-child(3n+3) {
  justify-content: center;
}

.c-block__newsletters-list--cols-3 .c-block__newsletters-list-item:nth-child(3n+1) {
  justify-content: flex-start;
}

.c-block__newsletters-list--cols-3 .c-block__newsletters-list-item:nth-child(3n+3) {
  justify-content: flex-end;
}

@media (min-width: 53.75rem) {
  .c-block__newsletters-list--cols-3 .c-block__newsletters-list-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (min-width: 72rem) {
  .c-block__newsletters-list--cols-3 .c-block__newsletters-list-item {
    width: 33.33333%;
    flex-basis: 33.33333%;
  }
  .c-blocks--has-sidebar .c-block__newsletters-list--cols-3 .c-block__newsletters-list-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (min-width: 85rem) {
  .c-block__newsletters-list--cols-3 .c-block__newsletters-list-item {
    width: 33.33333%;
    flex-basis: 33.33333%;
  }
  .c-blocks--has-sidebar .c-block__newsletters-list--cols-3 .c-block__newsletters-list-item {
    width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

.c-block__newsletters-list--cols-4 .c-block__newsletters-list-item {
  width: 100%;
}

.c-block__newsletters-list--cols-4 .c-block__newsletters-list-item:nth-child(4n+2), .c-block__newsletters-list--cols-4 .c-block__newsletters-list-item:nth-child(4n+3) {
  justify-content: center;
}

.c-block__newsletters-list--cols-4 .c-block__newsletters-list-item:nth-child(4n+1) {
  justify-content: flex-start;
}

.c-block__newsletters-list--cols-4 .c-block__newsletters-list-item:nth-child(4n+4) {
  justify-content: flex-end;
}

@media (min-width: 53.75rem) {
  .c-block__newsletters-list--cols-4 .c-block__newsletters-list-item {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (min-width: 72rem) {
  .c-block__newsletters-list--cols-4 .c-block__newsletters-list-item {
    width: 33.33333%;
    flex-basis: 33.33333%;
  }
}

@media (min-width: 85rem) {
  .c-block__newsletters-list--cols-4 .c-block__newsletters-list-item {
    width: 25%;
    flex-basis: 25%;
  }
  .c-blocks--has-sidebar .c-block__newsletters-list--cols-4 .c-block__newsletters-list-item {
    width: 25%;
    flex-basis: 25%;
  }
}

.c-block__newsletters-list-item-image {
  display: block;
  margin: 0.3125rem 0;
}

.c-block__newsletters-list-item-file {
  padding: 0.9375rem 0 0.3125rem;
}

.c-block__newsletters-list-item-file svg, .c-block__newsletters-list-item-file span {
  display: inline-block;
  vertical-align: middle;
}

.c-block__newsletters-list-item-inner {
  max-width: 25.625rem;
  padding: 0.625rem;
  border: 1px solid #eeeeee;
}

.c-instagram__data {
  z-index: 9999;
  position: fixed;
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.c-instagram__data-inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  width: 100%;
  max-width: 31.25rem;
  padding: 1.25rem;
  background-color: #fff;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
}

.c-instagram__data h4 {
  text-align: center;
}

.c-instagram__data strong {
  display: block;
}

.c-instagram__data span {
  display: block;
  word-break: break-all;
}

.c-block__contact-form .cform-container {
  position: relative;
  left: -0.8125rem;
  width: calc(100% + 26px);
}

.c-page__hero {
  position: relative;
  margin: 0 auto;
  background-size: cover;
}

.c-page__hero--container {
  max-width: 105rem;
}

.c-page__hero .o-layout {
  position: relative;
  z-index: 2;
}

.c-page__hero .o-layout__item {
  box-sizing: border-box;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

@media (max-width: 64rem) {
  .c-page__hero .o-layout__item {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-page__hero .o-layout__item {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.c-page__hero-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-page__hero-scrolldown {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 3.125rem;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

@media (max-width: 64rem) {
  .c-page__hero-scrolldown {
    bottom: 1.875rem;
    width: 1.875rem;
    height: 1.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-page__hero-scrolldown {
    bottom: 1.25rem;
    width: 1.5625rem;
    height: 1.5625rem;
  }
}

div[class*='c-page__hero--h-2'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

div[class*='c-page__hero--h-3'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  div[class*='c-page__hero--h-3'] {
    min-height: 12.5rem !important;
  }
}

@media (max-width: 64rem) {
  div[class*='c-page__hero--h-3'] {
    min-height: 6.25rem !important;
  }
}

div[class*='c-page__hero--h-4'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  div[class*='c-page__hero--h-4'] {
    min-height: 12.5rem !important;
    min-height: 18.75rem !important;
  }
}

@media (max-width: 64rem) {
  div[class*='c-page__hero--h-4'] {
    min-height: 6.25rem !important;
    min-height: 12.5rem !important;
  }
}

div[class*='c-page__hero--h-5'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  div[class*='c-page__hero--h-5'] {
    min-height: 12.5rem !important;
    min-height: 18.75rem !important;
  }
}

@media (max-width: 64rem) {
  div[class*='c-page__hero--h-5'] {
    min-height: 6.25rem !important;
    min-height: 12.5rem !important;
  }
}

/* ==========================================================================
   #PAGINATION
   ========================================================================== */
.c-pagination {
  line-height: 2.125rem;
  display: flex;
  justify-content: space-between;
  flex-direction: inherit;
  align-items: center;
  padding: 0;
  margin: 0.625rem 0;
}

@media (max-width: 72rem) {
  .c-pagination {
    line-height: 1.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-pagination {
    justify-content: center;
    flex-direction: column;
  }
}

.c-pagination__items {
  display: flex;
  list-style: none;
  margin: 0;
  justify-content: center;
}

.c-pagination__item {
  margin-bottom: 0;
}

.c-pagination__link {
  border: 0.125rem solid transparent;
  margin: 0.3125rem;
  padding-left: 0.6875rem;
  padding-right: 0.6875rem;
  color: #255cdd;
  font-size: 1.25rem;
  display: inline-block;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

@media (max-width: 72rem) {
  .c-pagination__link {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    font-size: 1rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-pagination__link {
    margin: 0.3125rem 0.1875rem;
  }
}

.c-pagination__link .icon {
  position: relative;
  top: 0.3125rem;
  width: 1rem;
  height: 1.5rem;
  fill: #255cdd;
}

@media (max-width: 72rem) {
  .c-pagination__link .icon {
    top: 0.25rem;
    height: 1.3rem;
  }
}

.c-pagination__link--current, .c-pagination__link:hover {
  border-color: #255cdd;
}

.c-pagination__link--inactive {
  cursor: default;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post::before, .c-post::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.c-post::after {
  clear: both;
}

.c-page-content {
  padding-bottom: 1.875rem;
}

.c-content__more-text {
  display: none;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  display: block;
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

@media (max-width: 64rem) {
  .alignleft,
  .alignright,
  .alignnone {
    display: block;
    float: none;
    margin: 1em auto;
  }
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-text--bg {
  padding: 1.25rem;
  margin: 1.25rem 0;
}

.c-text--bg * {
  margin: 0;
}

.c-project {
  max-width: none !important;
  margin: 0 auto 50px !important;
  padding-bottom: 0.625rem;
  border-bottom: 0.125rem solid #ddd;
}

.c-project:last-child {
  border-bottom: none;
}

.c-project .o-layout__item {
  padding: 0;
}

.c-project .o-layout__item.u-padding-left {
  padding-left: 1.5625rem;
}

@media (max-width: 48.0625rem) {
  .c-project .o-layout__item.u-padding-left {
    /* stylelint-disable-line max-nesting-depth */
    padding-left: 0;
  }
}

@media (max-width: 64rem) {
  .c-project {
    text-align: center;
  }
  .c-project .c-btn, .c-project .c-content__restricted form input[type=submit], .c-content__restricted form .c-project input[type=submit] {
    float: none !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-project {
    max-width: 250px !important;
    margin: 0 auto 30px !important;
  }
}

.c-project__title {
  margin-bottom: 1.875rem;
}

@media (max-width: 64rem) {
  .c-project__title {
    margin-bottom: 1.25rem;
  }
}

.c-project__title a {
  color: inherit;
}

.c-project__title a:hover {
  color: #255cdd;
}

.c-project__category {
  color: #777777;
  margin-bottom: 2.5rem;
  text-align: left;
}

@media (max-width: 48.0625rem) {
  .c-project__category {
    text-align: center;
  }
}

.c-project__lead a {
  color: #255cdd;
}

@media (max-width: 64rem) {
  .c-project__lead {
    margin-top: 0.9375rem;
  }
}

.c-project__link {
  display: inline-block;
  margin-top: 1.5625rem;
  padding: 0 3.125rem 3.125rem 0;
}

@media (max-width: 48.0625rem) {
  .c-project__link {
    padding: 0 1.875rem 1.875rem 0;
  }
}

.c-project__gallery-image p {
  display: block;
  padding: 0.3125rem;
  font-weight: 600;
  border: 1px solid #dddddd;
}

.c-project__video-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.c-project__video-container iframe,
.c-project__video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-content__restricted form {
  max-width: 31.25rem;
  margin: auto;
  text-align: center;
}

.c-content__restricted form p:last-child {
  text-align: left;
}

.c-content__restricted form input[type=submit] {
  display: block;
  margin: auto;
}

/* ==========================================================================
   #SECTION
   ========================================================================== */
.c-section {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

@media (max-width: 64rem) {
  .c-section {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
}

.c-section--primary {
  background-color: #678de7;
}

.c-section--secondary {
  background-color: #eeeeee;
}

.c-section--inner {
  padding: 1.875rem;
}

.c-section--overlap {
  margin-top: -12.5rem;
}

@media (max-width: 64rem) {
  .c-section--overlap {
    margin-top: -7.5rem;
  }
}

.c-section-below {
  padding-top: 0;
}

.c-section--teased {
  padding-top: 0;
  margin-top: -10rem;
}

@media (max-width: 64rem) {
  .c-section--teased {
    margin-top: -7.5rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-section--teased {
    margin-top: 0;
    padding-top: 2.5rem;
  }
}

.select2-container .select2-selection--single {
  height: 4.375rem;
  border: 0.1875rem solid #255cdd;
  border-radius: 0;
}

@media (max-width: 85.4375rem) {
  .select2-container .select2-selection--single {
    height: 4.125rem;
  }
}

@media (max-width: 64rem) {
  .select2-container .select2-selection--single {
    height: 3.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-container .select2-selection--single {
    height: 3.125rem;
    border-width: 0.125rem;
  }
}

.select2-container .select2-selection--single.select2--small {
  height: 3.125rem;
}

.select2-container .select2-selection--single.select2--xsmall {
  height: 2.625rem;
  border: 0.125rem solid #255cdd;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 3.9375rem;
  color: #255cdd;
}

@media (max-width: 85.4375rem) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 3.5rem;
  }
}

@media (max-width: 64rem) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 3.25rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 2.25rem;
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
  line-height: 2.6875rem;
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
    line-height: 2.25rem;
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
  line-height: 2.375rem;
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
    line-height: 1.875rem;
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 4.375rem;
}

@media (max-width: 85.4375rem) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 4.125rem;
  }
}

@media (max-width: 64rem) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 3.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 3.125rem;
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__arrow {
  height: 3.125rem;
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__arrow {
  height: 2.625rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #255cdd transparent transparent transparent;
  border-style: solid;
  border-width: 0.4375rem 0.375rem 0 0.375rem;
  height: 0;
  left: 50%;
  margin-left: -0.9375rem;
  margin-top: -0.25rem;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #255cdd transparent;
  border-width: 0 0.375rem 0.4375rem 0.375rem;
}

.select2-dropdown {
  background-color: #fff;
  border: 0.1875rem solid #255cdd;
  border-radius: 0;
}

.select2-dropdown.select2--xsmall {
  border-width: 0.125rem;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  font-size: 1.125rem;
  font-weight: 600;
}

@media (max-width: 48.0625rem) {
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0.3125rem 0.625rem;
    font-size: 1rem;
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.select2-results__option {
  padding: 0.5rem 0.9375rem;
  margin: 0;
}

@media (max-width: 48.0625rem) {
  .select2-results__option {
    padding: 0.4375rem 0.625rem;
    font-size: 0.9375rem;
  }
}

.select2-results__options.first-as-label .select2-results__option:first-child {
  padding: 0.8125rem 0.9375rem;
  background-color: #678de7;
  color: #fff;
}

@media (max-width: 48.0625rem) {
  .select2-results__options.first-as-label .select2-results__option:first-child {
    padding: 0.625rem 0.9375rem;
  }
}

.select2--xsmall .select2-results__option {
  padding: 0.4375rem 0.625rem;
}

@media (max-width: 48.0625rem) {
  .select2--xsmall .select2-results__option {
    padding: 0.375rem 0.625rem;
  }
}

.select2--xsmall .select2-results__options.first-as-label .select2-results__option:first-child {
  padding: 0.625rem 0.625rem;
}

@media (max-width: 48.0625rem) {
  .select2--xsmall .select2-results__options.first-as-label .select2-results__option:first-child {
    padding: 0.5rem 0.625rem;
  }
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #eeeeee;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ff005c;
  color: #fff;
}

/* ==========================================================================
   #SIDEBAR
   ========================================================================== */
@media (min-width: 64rem) {
  .c-sidebar--left {
    order: -1;
  }
}

.c-sidebar__custom-content {
  max-width: 26.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 0;
}

.c-sidebar--4 .c-sidebar__custom-content {
  max-width: 35rem;
}

.c-sidebar--left .c-sidebar__custom-content,
.c-blocks__sidebar--left .c-sidebar__custom-content {
  padding-right: 1.25rem;
  padding-left: 0;
}

@media (max-width: 64rem) {
  .c-sidebar__custom-content {
    max-width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.c-sidebar__custom-content--below {
  padding-top: 1.875rem;
}

.c-sidebar__custom-content--above {
  padding-bottom: 1.25rem;
}

.c-sidebar__widget {
  max-width: 26.25rem;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 0;
  margin-bottom: 0.625rem;
}

.c-sidebar--4 .c-sidebar__widget {
  max-width: 35rem;
}

.c-sidebar--left .c-sidebar__widget,
.c-blocks__sidebar--left .c-sidebar__widget {
  padding-right: 1.25rem;
  padding-left: 0;
}

.c-footer__sidebar .c-sidebar__widget {
  max-width: none;
}

@media (max-width: 64rem) {
  .c-sidebar__widget {
    margin: 0 auto;
    padding-left: 0;
  }
}

.c-page-block__sidebar {
  position: relative;
  z-index: 2;
}

.c-sidebar__header {
  display: inline-block;
  padding: 0.625rem 0;
  margin: 0.625rem 0.625rem 1.5625rem;
  border-bottom: 0.25rem solid #255cdd;
  color: #454545;
}

.c-sidebar__header a {
  color: inherit;
  text-decoration: none;
}

.widget_media_audio {
  overflow: hidden;
}

.widget_nav_menu ul,
.widget_pages ul,
.c-side-nav ul,
.widget_recent_entries ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.widget_nav_menu ul ul,
.widget_pages ul ul,
.c-side-nav ul ul,
.widget_recent_entries ul ul {
  padding: 0 0 10px 0;
}

.widget_nav_menu ul li,
.widget_pages ul li,
.c-side-nav ul li,
.widget_recent_entries ul li {
  margin: 0;
  /* stylelint-disable selector-no-qualifying-type */
  /* stylelint-enable selector-no-qualifying-type */
}

.widget_nav_menu ul li .c-side-nav__sublink,
.widget_pages ul li .c-side-nav__sublink,
.c-side-nav ul li .c-side-nav__sublink,
.widget_recent_entries ul li .c-side-nav__sublink {
  padding: 0.625rem 2.1875rem;
}

.widget_nav_menu ul li .c-side-nav__sublink--level2,
.widget_pages ul li .c-side-nav__sublink--level2,
.c-side-nav ul li .c-side-nav__sublink--level2,
.widget_recent_entries ul li .c-side-nav__sublink--level2 {
  padding: 0.625rem 2.8125rem;
}

.widget_nav_menu ul li a,
.widget_pages ul li a,
.c-side-nav ul li a,
.widget_recent_entries ul li a {
  display: block;
  padding: 0.625rem 1.5625rem;
  margin-bottom: -0.0625rem;
  border: 0.0625rem solid #eeeeee;
  color: #2c2c2c;
  text-decoration: none;
  transition: all 300ms ease-in-out;
  /* stylelint-disable selector-no-qualifying-type */
  /* stylelint-enable selector-no-qualifying-type */
}

.widget_nav_menu ul li a:hover, .widget_nav_menu ul li a.c-side-nav__link--current, .widget_nav_menu ul li a.c-side-nav__sublink--current,
.widget_pages ul li a:hover,
.widget_pages ul li a.c-side-nav__link--current,
.widget_pages ul li a.c-side-nav__sublink--current,
.c-side-nav ul li a:hover,
.c-side-nav ul li a.c-side-nav__link--current,
.c-side-nav ul li a.c-side-nav__sublink--current,
.widget_recent_entries ul li a:hover,
.widget_recent_entries ul li a.c-side-nav__link--current,
.widget_recent_entries ul li a.c-side-nav__sublink--current {
  background-color: #eeeeee;
}

.widget_nav_menu ul li.current-menu-item > a,
.widget_nav_menu ul li.current_page_item > a,
.widget_nav_menu ul li .c-side-nav__link--current > a,
.widget_nav_menu ul li .c-side-nav__link--current:hover > a,
.widget_pages ul li.current-menu-item > a,
.widget_pages ul li.current_page_item > a,
.widget_pages ul li .c-side-nav__link--current > a,
.widget_pages ul li .c-side-nav__link--current:hover > a,
.c-side-nav ul li.current-menu-item > a,
.c-side-nav ul li.current_page_item > a,
.c-side-nav ul li .c-side-nav__link--current > a,
.c-side-nav ul li .c-side-nav__link--current:hover > a,
.widget_recent_entries ul li.current-menu-item > a,
.widget_recent_entries ul li.current_page_item > a,
.widget_recent_entries ul li .c-side-nav__link--current > a,
.widget_recent_entries ul li .c-side-nav__link--current:hover > a {
  background-color: #eeeeee;
}

.widget_nav_menu ul li .c-side-nav__link--month > a,
.widget_pages ul li .c-side-nav__link--month > a,
.c-side-nav ul li .c-side-nav__link--month > a,
.widget_recent_entries ul li .c-side-nav__link--month > a {
  padding: 0.625rem 1.5625rem 0.625rem 2.1875rem;
}

.widget_nav_menu ul li .c-side-nav__icon,
.widget_pages ul li .c-side-nav__icon,
.c-side-nav ul li .c-side-nav__icon,
.widget_recent_entries ul li .c-side-nav__icon {
  position: relative;
  top: -0.125rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.widget_nav_menu ul li .post-date,
.widget_pages ul li .post-date,
.c-side-nav ul li .post-date,
.widget_recent_entries ul li .post-date {
  display: none;
}

.widget_rss ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.widget_rss ul li {
  margin: 0;
  display: block;
  padding: 0.625rem 1.5625rem;
  margin-bottom: -0.0625rem;
  border: 0.0625rem solid #eeeeee;
}

.widget_rss ul li a {
  display: block;
  color: #2c2c2c;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.widget_rss ul li a:hover {
  color: #255cdd;
}

.widget_rss ul .rss-date {
  display: block;
  font-size: 1rem;
  padding: 0.3125rem 0;
  font-style: italic;
  font-weight: 700;
}

.widget_rss ul .rssSummary {
  font-size: 0.875rem;
}

.widget__content-box-inner {
  position: relative;
  max-width: 26.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.c-sidebar--4 .widget__content-box-inner {
  max-width: 35rem;
}

@media (max-width: 64rem) {
  .widget__content-box-inner {
    max-width: 26.25rem;
  }
}

@media (max-width: 48.0625rem) {
  .widget__content-box-inner {
    max-width: 23.75rem;
  }
}

.widget__content-box-inner a {
  color: inherit !important;
  text-decoration: none;
}

.widget__content-box-inner-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 300ms ease-in-out;
}

.widget__content-box-inner:hover .widget__content-box-inner-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.widget__content-box-content {
  position: relative;
  bottom: 0;
  height: 16.875rem;
  z-index: 2;
  overflow: hidden;
}

.widget__content-box-content * {
  color: #fff;
}

@media (max-width: 64rem) {
  .widget__content-box-content {
    height: 13.125rem;
  }
}

.widget__content-box-content p,
.widget__content-box-content h3 {
  position: absolute;
  left: 1.875rem;
  right: 1.875rem;
  display: block;
  transition: all 0.3s ease-in-out;
  transition-delay: 0s;
}

@media (max-width: 85.4375rem) {
  .widget__content-box-content p,
  .widget__content-box-content h3 {
    left: 1.25rem;
    right: 1.25rem;
  }
}

@media (max-width: 72rem) {
  .widget__content-box-content p,
  .widget__content-box-content h3 {
    left: 0.9375rem;
    right: 0.9375rem;
  }
}

.widget__content-box-content p {
  height: 7.5rem;
  margin-bottom: 1.25rem;
  overflow: hidden;
  bottom: 0;
}

@media (max-width: 64rem) {
  .widget__content-box-content p {
    height: 5.625rem;
    margin-bottom: 0.625rem;
  }
}

@media (max-width: 27.5rem) {
  .widget__content-box-content p {
    height: 6.875rem;
  }
}

.widget__content-box-content h3 {
  top: 1.25rem;
  bottom: 50%;
  line-height: 2rem;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

@media (max-width: 72rem) {
  .widget__content-box-content h3 {
    top: 0.625rem;
  }
}

@media (max-width: 64rem) {
  .widget__content-box-content h3 {
    bottom: 53%;
    line-height: 1.5rem;
  }
}

@media (min-width: 48.0625rem) {
  .widget__content-box-content--hover p {
    bottom: -9.375rem;
  }
  .widget__content-box-inner:hover .widget__content-box-content--hover p {
    bottom: 0;
    transition-delay: 0.1s;
  }
}

@media (min-width: 48.0625rem) {
  .widget__content-box-content--hover h3 {
    top: auto;
    bottom: 0;
  }
}

@media (min-width: 48.0625rem) {
  .widget__content-box-inner:hover .widget__content-box-content--hover h3 {
    bottom: 53%;
    transition-delay: 0s;
  }
}

@media (min-width: 64rem) {
  .widget__content-box-inner:hover .widget__content-box-content--hover h3 {
    bottom: 50%;
  }
}

.widget__html-content,
.widget_text .textwidget {
  padding: 0.625rem 1.5625rem;
}

.widget__html-content .c-btn, .widget__html-content .c-content__restricted form input[type=submit], .c-content__restricted form .widget__html-content input[type=submit],
.widget_text .textwidget .c-btn,
.widget_text .textwidget .c-content__restricted form input[type=submit],
.c-content__restricted form .widget_text .textwidget input[type=submit] {
  margin-bottom: 1.25rem;
}

.widget__html-content ul li,
.widget_text .textwidget ul li {
  list-style: none;
}

.widget__html-content ul li:before,
.widget_text .textwidget ul li:before {
  display: inline-block;
  margin: 0 0.3125rem 0 0;
  padding: 0;
  content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10' width='10'><circle cx='5' cy='5' r='4' stroke='black' stroke-width='1' fill='#ffffff' fill-opacity='0' /></svg>");
  /* stylelint-disable-line string-quotes */
  width: 0.75rem;
}

.widget__html-content ul li a,
.widget_text .textwidget ul li a {
  padding: 0.3125rem 0;
  display: inline-block;
}

.widget_x5_widget_upcoming_events .c-widget__events-item {
  border: 0.0625rem solid #eeeeee;
  text-align: center;
  display: block;
  text-decoration: none;
  color: #000;
  transition: all 300ms ease-in-out;
}

.widget_x5_widget_upcoming_events .c-widget__events-item:hover {
  background-color: #eeeeee;
}

.widget_x5_widget_upcoming_events .c-widget__events-image {
  display: block;
  margin: 0 auto;
}

.widget_x5_widget_upcoming_events p {
  padding: 0 0.625rem;
  margin: 0.625rem 0;
}

.widget_x5_widget_upcoming_events .c-widget__events-start-date,
.widget_x5_widget_upcoming_events .c-widget__events-location {
  display: inline-block;
  padding: 0;
}

.widget_x5_widget_upcoming_events .c-widget__events-start-date,
.widget_x5_widget_upcoming_events .c-widget__events-location,
.widget_x5_widget_upcoming_events .c-widget__events-speaker {
  font-size: 1rem;
}

.widget__newsletter {
  padding: 0.625rem 0.625rem;
  background-color: #eeeeee;
}

.widget__newsletter p,
.widget__newsletter a {
  display: block;
  margin: 0;
  padding: 0.625rem;
}

.widget__newsletter-link {
  color: #2c2c2c;
  font-size: 0.9375rem;
  line-height: normal;
  text-decoration: none;
}

.widget__newsletter-link:hover .widget__newsletter-label {
  text-decoration: underline;
  color: #2c2c2c;
}

.widget__newsletter-link svg {
  display: inline-block;
  margin-right: 0.625rem;
  vertical-align: top;
}

.c-footer__sidebar {
  padding: 1.875rem 1.625rem;
}

@media (max-width: 64rem) {
  .c-footer__sidebar {
    padding: 0.625rem 1.625rem;
  }
}

.c-footer__sidebar-column {
  padding: 0 1.625rem 0.625rem;
}

@media (max-width: 80rem) {
  .c-footer__sidebar-column {
    padding: 0 0.9375rem 0.625rem;
  }
}

@media (max-width: 72rem) {
  .c-footer__sidebar-column {
    padding: 0 0.625rem 0.625rem;
  }
}

@media (max-width: 64rem) {
  .c-footer__sidebar-column {
    padding: 0 0 0.625rem;
  }
}

.c-footer__sidebar-column:nth-child(1) {
  padding-right: 2.5rem;
  padding-left: 0.8125rem;
}

@media (max-width: 80rem) {
  .c-footer__sidebar-column:nth-child(1) {
    padding-right: 1.875rem;
  }
}

@media (max-width: 72rem) {
  .c-footer__sidebar-column:nth-child(1) {
    padding-right: 1.25rem;
  }
}

@media (max-width: 64rem) {
  .c-footer__sidebar-column:nth-child(1) {
    padding-right: 0;
    padding-left: 0;
  }
}

.c-footer__sidebar-column:nth-child(3) {
  padding-right: 0.8125rem;
  padding-left: 2.5rem;
}

@media (max-width: 80rem) {
  .c-footer__sidebar-column:nth-child(3) {
    padding-left: 1.875rem;
  }
}

@media (max-width: 72rem) {
  .c-footer__sidebar-column:nth-child(3) {
    padding-left: 1.25rem;
  }
}

@media (max-width: 64rem) {
  .c-footer__sidebar-column:nth-child(3) {
    padding-right: 0;
    padding-left: 0;
  }
}

.c-footer__sidebar .c-sidebar__widget {
  max-width: none;
  margin: auto;
}

@media (max-width: 64rem) {
  .c-footer__sidebar .c-sidebar__widget {
    max-width: 26.25rem;
  }
  .c-sidebar--4 .c-footer__sidebar .c-sidebar__widget {
    max-width: 35rem;
  }
}

.c-footer__sidebar img {
  max-width: 100%;
  height: auto;
}

/* ==========================================================================
   #SOCIAL MEDIA
   ========================================================================== */
.c-social-media {
  list-style: none;
  margin: 0;
}

.c-social-media::before, .c-social-media::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.c-social-media::after {
  clear: both;
}

.c-social-media__item {
  display: inline-block;
  margin: 0 1.25rem 0 0;
}

.c-social-media__item:last-child {
  margin-right: 0;
}

.c-social-media__link {
  border: 0.125rem solid #000;
  border-radius: 50%;
  display: block;
  height: 2.25rem;
  width: 2.25rem;
  padding: 0.5rem;
}

.c-social-media__link:hover {
  border-color: #255cdd;
}

.c-social-media__link:hover .c-social-media__icon {
  fill: #255cdd;
}

.c-social-media__link--square {
  border-radius: 0;
}

.c-social-media__icon {
  display: block;
  height: 1rem;
  width: 1rem;
}

.gallery {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.gallery .wp-caption-text {
  text-align: center;
  font-size: 1.125rem;
}

@media (max-width: 40rem) {
  .gallery .wp-caption-text {
    font-size: 1rem;
  }
}

@media (max-width: 27.5rem) {
  .gallery .wp-caption-text {
    font-size: 0.9375rem;
  }
}

.gallery-item {
  padding: 0.3125rem;
  margin: 0;
}

.gallery-item img {
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
  border: none;
  transition: all 300ms ease-in-out;
  transform: scale(1);
}

.gallery-item .wp-gallery-link:hover img {
  transform: scale(1.1);
}

.gallery-columns-1 .gallery-item {
  width: 100%;
  flex-basis: 100%;
}

.gallery-columns-2 .gallery-item {
  width: 50%;
  flex-basis: 50%;
}

.gallery-columns-3 .gallery-item {
  width: 33.33333%;
  flex-basis: 33.33333%;
}

@media (max-width: 27.5rem) {
  .gallery-columns-3 .gallery-item {
    width: 50%;
    flex-basis: 50%;
  }
}

.gallery-columns-4 .gallery-item {
  width: 25%;
  flex-basis: 25%;
}

@media (max-width: 40rem) {
  .gallery-columns-4 .gallery-item {
    width: 33.333%;
    flex-basis: 33.333%;
  }
}

@media (max-width: 27.5rem) {
  .gallery-columns-4 .gallery-item {
    width: 50%;
    flex-basis: 50%;
  }
}

.gallery-columns-5 .wp-caption-text {
  font-size: 1rem;
}

.gallery-columns-5 .gallery-item {
  width: 20%;
  flex-basis: 20%;
}

@media (max-width: 48.0625rem) {
  .gallery-columns-5 .gallery-item {
    width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 40rem) {
  .gallery-columns-5 .gallery-item {
    width: 33.333%;
    flex-basis: 33.333%;
  }
}

@media (max-width: 27.5rem) {
  .gallery-columns-5 .gallery-item {
    width: 50%;
    flex-basis: 50%;
  }
}

.gallery-columns-6 .wp-caption-text {
  font-size: 1rem;
}

.gallery-columns-6 .gallery-item {
  width: 16.66667%;
  flex-basis: 16.66667%;
}

@media (max-width: 64rem) {
  .gallery-columns-6 .gallery-item {
    width: 20%;
    flex-basis: 20%;
  }
}

@media (max-width: 48.0625rem) {
  .gallery-columns-6 .gallery-item {
    width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 40rem) {
  .gallery-columns-6 .gallery-item {
    width: 33.333%;
    flex-basis: 33.333%;
  }
}

@media (max-width: 27.5rem) {
  .gallery-columns-6 .gallery-item {
    width: 50%;
    flex-basis: 50%;
  }
}

.gallery-columns-7 .wp-caption-text {
  font-size: 1rem;
}

.gallery-columns-7 .gallery-item {
  width: 14.28571%;
  flex-basis: 14.28571%;
}

@media (max-width: 64rem) {
  .gallery-columns-7 .gallery-item {
    width: 20%;
    flex-basis: 20%;
  }
}

@media (max-width: 48.0625rem) {
  .gallery-columns-7 .gallery-item {
    width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 40rem) {
  .gallery-columns-7 .gallery-item {
    width: 33.333%;
    flex-basis: 33.333%;
  }
}

@media (max-width: 27.5rem) {
  .gallery-columns-7 .gallery-item {
    width: 50%;
    flex-basis: 50%;
  }
}

.gallery-columns-8 .wp-caption-text {
  font-size: 1rem;
}

.gallery-columns-8 .gallery-item {
  width: 12.5%;
  flex-basis: 12.5%;
}

@media (max-width: 64rem) {
  .gallery-columns-8 .gallery-item {
    width: 20%;
    flex-basis: 20%;
  }
}

@media (max-width: 48.0625rem) {
  .gallery-columns-8 .gallery-item {
    width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 40rem) {
  .gallery-columns-8 .gallery-item {
    width: 33.333%;
    flex-basis: 33.333%;
  }
}

@media (max-width: 27.5rem) {
  .gallery-columns-8 .gallery-item {
    width: 50%;
    flex-basis: 50%;
  }
}

.gallery-columns-9 .wp-caption-text {
  font-size: 1rem;
}

.gallery-columns-9 .gallery-item {
  width: 11.11111%;
  flex-basis: 11.11111%;
}

@media (max-width: 64rem) {
  .gallery-columns-9 .gallery-item {
    width: 20%;
    flex-basis: 20%;
  }
}

@media (max-width: 48.0625rem) {
  .gallery-columns-9 .gallery-item {
    width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 40rem) {
  .gallery-columns-9 .gallery-item {
    width: 33.333%;
    flex-basis: 33.333%;
  }
}

@media (max-width: 27.5rem) {
  .gallery-columns-9 .gallery-item {
    width: 50%;
    flex-basis: 50%;
  }
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

@media (min-width: 48.0625rem) {
  .u-text-right-on-large {
    text-align: right;
  }
}

.u-text-center-on-small {
  text-align: center;
}

@media (min-width: 48.0625rem) {
  .u-text-center-on-small {
    text-align: left;
  }
}

.alignleft {
  display: block;
  float: none;
  margin: 0.3125rem auto;
}

@media (min-width: 48.0625rem) {
  .alignleft {
    float: left;
    margin: 0 1em 1em 0;
  }
}

.alignright {
  display: block;
  float: none;
  margin: 0.3125rem auto;
}

@media (min-width: 48.0625rem) {
  .alignright {
    float: right;
    margin: 0 0 1em 1em;
  }
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::before, .u-clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.u-clearfix::after {
  clear: both;
}

/* ==========================================================================
   #COLOR
   ========================================================================== */
.u-fill {
  background: #fafafa;
}

/* ==========================================================================
   #DISPLAY
   ========================================================================== */
.u-flex {
  display: flex;
}

.u-flex-center-vertically {
  align-items: center;
}

.u-inline-block {
  display: inline-block;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 48.0625rem) {
  .u-hidden\@small {
    display: block;
  }
}

/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */
.u-margin {
  margin: 1.5625rem;
}

.u-margin-top {
  margin-top: 1.5625rem;
}

.u-margin-top--small {
  margin-top: 0.78125rem;
}

.u-margin-right {
  margin-right: 1.5625rem;
}

.u-margin-right-small {
  margin-right: 0.78125rem;
}

.u-margin-bottom {
  margin-bottom: 1.5625rem;
}

.u-margin-bottom-small {
  margin-bottom: 0.78125rem;
}

.u-margin-bottom-large {
  margin-bottom: 3.125rem;
}

.u-margin-bottom-md {
  margin-bottom: 2.25rem;
}

.u-margin-left {
  margin-left: 1.5625rem;
}

.u-no-margin {
  margin: 0 !important;
}

.u-no-margin-top {
  margin-top: 0 !important;
}

.u-no-margin-bottom {
  margin-bottom: 0 !important;
}

.u-margin-nbsp {
  margin-bottom: 3px;
}

.u-padding {
  padding: 1.5625rem;
}

.u-no-padding {
  padding: 0;
}

.u-padding-top {
  padding-top: 1.5625rem;
}

.u-padding-top--large {
  padding-top: 3.125rem;
}

.u-padding-right {
  padding-right: 1.5625rem;
}

.u-padding-bottom {
  padding-bottom: 1.5625rem;
}

.u-padding-left {
  padding-left: 1.5625rem;
}

.u-padding-left--small {
  padding-left: 0.78125rem;
}

@media (max-width: 64rem) {
  .u-margin-bottom-up-md {
    margin-bottom: 1.5625rem;
  }
}

body {
  font-size: 1.125rem;
}

@media (max-width: 64rem) {
  body {
    font-size: 1rem;
  }
}

p {
  font-size: inherit;
  line-height: 1.35;
}

p .u-text--enlarge {
  font-size: 1.625rem;
}

p .u-text--ensmall {
  font-size: 1rem;
}

@media (max-width: 105rem) {
  p .u-text--enlarge {
    font-size: 1.5rem;
  }
}

@media (max-width: 85.4375rem) {
  p .u-text--enlarge {
    font-size: 1.375rem;
  }
}

@media (max-width: 72rem) {
  p .u-text--enlarge {
    font-size: 1.25rem;
  }
}

@media (max-width: 48.0625rem) {
  p {
    font-size: 1rem;
  }
  p .u-text--enlarge {
    font-size: 1.125rem;
  }
  p .u-text--ensmall {
    font-size: 0.875rem;
  }
}

.u-text--light {
  font-weight: 300;
}
