.c-main-nav {
  position: relative;
  display: block;
  text-align: right;

  @include bp-large('max') {
    display: none;
  }

  &--closed {
    opacity: 0;
  }
}

.c-main-nav__items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;

  .c-header--static.c-header--image & {
    padding-top: rem_calc(25);

    @include bp-xlarge-medium('max') {
      padding-top: rem_calc(10);
    }
  }
}

.c-main-nav__item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: rem_calc(0 5);
  padding: rem_calc(33 0);
  text-align: left;

  @include bp-xlarge('max') {
    margin: rem_calc(0 3);
  }

  .c-header--static:not(.c-header--shrink) &:not(&--transparent) {
    padding: rem_calc(43 0);
  }

  .c-header--static.c-header--image &,
  .c-header--none & {
    padding: rem_calc(0) !important;
  }

  .c-header--static.c-header--image &--bordered,
  .c-header--none &--bordered {
    padding: rem_calc(3 0) !important;

    > .c-main-nav__dropdown {
      left: - rem_calc(3);
    }
  }

  &--transparent {
    padding: 0;
  }

  &:hover,
  &:focus,
  &.has-dropdown-open {
    > .c-main-nav__dropdown {
      transform: rotate3d(0, 1, 0, 0deg);
    }
  }

  &.menu-item-has-children {
    position: relative;

    &:after,
    &:before {
      position: absolute;
      top: 50%;
      right: rem_calc(10);
      transform: translateY(-50%);
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-left: rem-calc(4) solid transparent;
      border-right: rem-calc(4) solid transparent;
      border-top: rem-calc(4) solid;
      z-index: 2;
      transition: $global-transition;

      @include bp-xxlarge('max') {
        right: rem_calc(8);
      }

      @include bp-xlarge-medium('max') {
        right: rem_calc(5);
      }
    }

    &:before {
      top: auto;
      bottom: - rem_calc(6);
      left: 0;
      right: 0;
      margin: auto;
      border-top-color: transparent;
    }

    &:hover {
      &:before {
        border-top-color: $color-black;
      }
    }
  }

  &.menu-item-has-children:not(&--transparent) {
    &:before {
      display: none;
    }
  }

  // .c-main-nav--dropdowns-full &:hover {
  //   > .c-main-nav__dropdown {
  //     transform: rotate3d(0, 1, 0, 0deg) translateX(50%);
  //   }
  // }
}

.c-main-nav__link {
  position: relative;
  display: block;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  text-decoration: none;
  transition: $global-transition;

  .c-main-nav--cap-items & {
    text-transform: uppercase;
  }

  @include bp-xxlarge('max') {
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
  }

  @include bp-xlarge-medium('max') {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
  }

  .c-main-nav__item--transparent & {
    padding-top: rem-calc(44);
    padding-bottom: rem-calc(44);
    border-bottom: rem_calc(4) solid transparent;

    .c-header--static:not(.c-header--shrink) & {
      padding-top: rem-calc(54);
      padding-bottom: rem-calc(54);
    }

    .c-header--static.c-header--image &,
    .c-header--static.c-header--none &,
    .c-header--none & {
      padding-top: rem-calc(11);
      padding-bottom: rem-calc(11);
    }
  }

  .c-main-nav__item--bordered & {
    outline: solid 3px;
  }

  .c-main-nav__item.menu-item-has-children & {
    padding-right: rem-calc(25);

    @include bp-xxlarge('max') {
      padding-right: rem-calc(20);
    }

    @include bp-xlarge-medium('max') {
      padding-right: rem-calc(15);
    }
  }
}

.c-main-nav__dropdown {
  display: block;
  width: rem-calc(200);
  position: absolute;
  top: calc(100%);
  left: 0;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  transform: rotate3d(1, 0, 0, 90deg) perspective(200px);
  transform-origin: top center;
  backface-visibility: hidden;
  transition: all 0.2s ease;
  box-shadow: 0 rem-calc(4) rem-calc(9) rgba(0, 0, 0, 0.4);

  &--inverse {
    left: auto;
    right: 0;
  }

  .c-main-nav__subitem & {
    left: 100%;
    top: 0;
    transform: rotate3d(0, 1, 0, -90deg) perspective(200px);
    transform-origin: left center;

    &--inverse {
      left: -100%;
      transform: rotate3d(0, 1, 0, 90deg) perspective(200px);
      transform-origin: right center;
    }
  }

  // .c-main-nav--dropdowns-full &--level1 {
  //   width: $global-max-width;
  //   left: auto;
  //   right: 50%;
  //   transform: rotate3d(1, 0, 0, 90deg) perspective(200px) translateX(50%);
  // }

  .c-main-nav--dropdowns-full & {
    display: none;
  }
}

.c-main-nav__full-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 1px);
  margin: 0 auto;
  width: 100%;
  height: auto;
  //z-index: 99;
  opacity: 0;
  transform: rotate3d(1, 0, 0, 90deg) perspective(200px);
  transform-origin: top center;
  backface-visibility: hidden;
  transition: $global-transition;

  @include bp-large('max') {
    display: none;
  }

  &.show {
    //top: 100%;
    transform: rotate3d(0, 1, 0, 0deg);
    opacity: 1;
  }

  &-inner {
    margin-bottom: 0;
    padding-top: rem_calc(20);
    padding-bottom: rem_calc(20);

    @include bp-xxlarge('max') {
      padding-top: rem_calc(10);
      padding-bottom: rem_calc(10);
    }
  }

  @for $i from 1 through 4 {
    &--full-#{$i} .c-main-nav__subitem {
      flex-basis: ( 100 / $i) * 1%;
      width: ( 100 / $i) * 1%;
    }
  }

  .c-main-nav__sublink {
    padding-right: rem_calc(50);
  }

  .c-main-nav__dropdown,
  .c-main-nav__subitem:before,
  .c-main-nav__subitem:after {
    display: none;
  }
}

.c-main-nav__full-dropdown--show-submenus {
  .c-main-nav__dropdown {
    display: block;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    width: auto;
    transform: none;
    transform-origin: center center;
    backface-visibility: visible;
    box-shadow: none;

    &--inverse {
      left: auto;
      right: auto;
    }

    .c-main-nav__subitem & {
      width: auto;
      left: auto;
      top: auto;
      transform: none;
      transform-origin: center center;

      &--inverse {
        left: auto;
        transform: none;
        transform-origin: center center;
      }
    }

    .c-main-nav__subitem {
      width: auto;
      padding-left: rem-calc(10);
    }

    .c-main-nav__sublink {
      padding-top: rem-calc(5);
      padding-bottom: rem-calc(5);
      font-size: rem-calc(18);
      line-height: rem-calc(22);
    }
  }
}

.c-main-nav__subitem {
  position: relative;
  display: block;
  margin-bottom: 0;

  .c-main-nav--cap-subitems & {
    text-transform: uppercase;
  }

  &:hover,
  &:focus,
  &.has-dropdown-open {
    > .c-main-nav__dropdown {
      transform: rotate3d(0, 1, 0, 0deg);
    }
  }

  &--parent {
    &:after,
    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      border-right: rem_calc(4) solid transparent;
      transition: $global-transition;
    }

    &:before {
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      right: - rem_calc(8);
      border-bottom: rem-calc(4) solid transparent;
      border-top: rem-calc(4) solid transparent;
      border-left: rem-calc(4) solid transparent;
      z-index: 2;
    }

    &:hover,
    &:focus {
      &:after {
        border-right-color: $color-black;
      }

      &:before {
        border-left-color: $color-black;
      }
    }
  }

  &--inverse {
    &:after,
    &:before {
      right: auto;
      left: - rem_calc(4);
      border-left: rem_calc(4) solid transparent;
    }

    &:before {
      right: 0;
      left: - rem_calc(8);
      border-right-color: transparent;
    }

    &:hover,
    &:focus {
      &:after {
        border-left-color: transparent;
        border-right-color: $color-black;
      }

      &:before {
        border-left-color: transparent;
        border-right-color: $color-black;
      }
    }
  }
}

.c-main-nav__sublink {
  position: relative;
  text-decoration: none;
  color: inherit;
  display: block;
  padding: rem-calc(10);
  border: none;
  transition: $global-transition;
}

.c-main-nav__search-trigger {
  position: relative;
  top: rem-calc(2);
  border: none;
  background-color: transparent;
  margin-left: rem-calc(15);
  z-index: 7;
  transition: $global-transition;
  transform: scale(1);

  &--mobile {
    top: 0;
  }

  @include bp-xlarge-medium('max') {
    margin-left: rem-calc(5);
  }

  &:hover,
  &:focus {
    cursor: pointer;
    transform: scale(1.2);
  }
}

.c-main-nav__search-icon {
  fill: inherit;
  width: rem-calc(25);
  height: rem-calc(25);
}
