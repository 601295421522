$columns: 12;
$page-blocks-width: $global-max-width + rem_calc(50);

%columns-styles {
  min-height: 1px;
  box-sizing: border-box;
  width: 100%;
}

.c-blocks {
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;

  &__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;

    @include bp-large('max') {
      flex-flow: row wrap;
    }

    &--container {
      max-width: $global-max-width;
    }
  }

  &--container {
    display: flex;
    flex-flow: row wrap; // flex-direction | flex-wrap
    align-items: flex-start;
    max-width: $global-max-width;
  }

  &-main,
  &-sidebar {
    min-height: 1px;
  }

  &__main {
    display: flex;
    flex-flow: row wrap; // flex-direction | flex-wrap
    align-items: stretch;
    align-content: flex-start;
    justify-content: center;
    order: 0;
    @extend %columns-styles;
    width: 100%;

    .c-blocks--has-sidebar & {
      @include bp-large {
        width: 75%;
      }
    }

    .c-blocks--has-sidebar &--8 {
      @include bp-large {
        width: 66.66667%;
      }
    }
  }

  &__sidebar {
    width: 100%;
    max-width: rem_calc($sidebar_max_width);
    margin: 0 auto;

    &--4 {
      max-width: rem_calc($sidebar_max_width_lg);
    }

    @include bp-large {
      width: 25%;
      padding-left: rem_calc(10);

      &--4 {
        width: 33.33333%;
      }
    }

    &--left {
      @include bp-large {
        padding-right: rem_calc(10);
        order: -1;
        margin: 0;
      }
    }
  }
}

.c-block {
  display: flex;
  position: relative;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  padding: rem_calc(20 0);

  &--w-50 {
    align-items: flex-start;
  }

  //&--floating-header {

  .body__floating-header &--floating-header:not(.c-block--type-slider) {
    @include bp-medium() {
      padding: rem_calc(100 0 50);
    }

    @include bp-xlarge() {
      padding: rem_calc(90 0 50);
    }
  }

  .body__transparent-header &--floating-header:not(.c-block--type-slider) {
    @include bp-medium() {
      padding: rem_calc(50 0 50);
    }

    @include bp-xlarge() {
      padding: rem_calc(50 0 50);
    }
  }

  @include bp-xlarge('max') {
    &--bg-mobile:not(.c-block--floating-header) {
      padding: 0;
    }
  }

  &--type-slider {
    padding: 0;
  }

  .c-blocks--has-sidebar & {
    padding: rem_calc(0 0 10);
  }

  &--h-full {
    min-height: 100vh;

    @include bp-large('max') {
      min-height: rem_calc(1);
    }
  }

  &__header {
    width: 100%;
    position: relative;
    z-index: 3;
    padding: rem_calc(20 0);
    margin-bottom: rem_calc(20);

    &--block {
      padding: rem_calc(10 13);
    }

    &--padding {
      padding-left: rem_calc(26);
      padding-right: rem_calc(26);
    }

    span[style*="text-decoration"] { /* stylelint-disable-line string-quotes, selector-attribute-quotes */
      display: inline-block;
      margin-bottom: rem_calc(5);
      text-decoration: none !important;
      border-bottom: rem_calc(4) solid $color-theme-dark;
    }

    @include bp-large('max') {
      * {
        text-align: center !important;
      }
    }
  }

  &--clear {
    width: 100%;
  }

  &--container {
    max-width: $global-max-width;

    @include bp-xxlarge {
      max-width: $page-blocks-width;
    }
  }

  &__bg-under {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 0;

    @include bp-xlarge('max') {
      &--hide {
        display: none;
      }
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .c-block--container.c-block--no-sidebar & {
      width: calc(100% - 72px);
    }

    .c-blocks--has-sidebar & {
      width: calc(100% - 26px);
    }

    .c-block--container.c-block--no-sidebar.c-block--side-left & {
      width: calc(200% - 54px);
      left: rem_calc(36);
    }

    .c-block--side-right.c-block--no-sidebar & {
      width: 150%;
      display: none;
    }

    &-overlay {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    &--parallax {
      background-attachment: fixed;
    }

    @include bp-xlarge('max') {
      &--mobile {
        position: relative;
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        min-height: rem_calc(450);
        height: auto;

        &-above {
          margin: rem_calc(-20 0 -10);

          .c-block--floating-header & {
            @include bp-medium {
              margin-top: rem_calc(-70);
            }
          }
        }

        &-below {
          order: 9;
          margin: rem_calc(0 0 -20);

          .c-block--floating-header & {
            margin-top: rem_calc(-40);

            @include bp-medium {
              margin-bottom: rem_calc(-70);
            }
          }
        }
      }
    }

    @include bp-medium('max') {
      &--mobile {
        min-height: rem_calc(300);
      }
    }

    @include bp-small('max') {
      &--mobile {
        min-height: rem_calc(250);
      }
    }
  }

  &__side-bg {
    &-image {
      position: absolute;
      max-height: 100%;
      bottom: 0;

      @include bp-xlarge('max') {
        max-height: rem_calc(500);
      }

      @include bp-medium('max') {
        max-height: rem_calc(400);
      }

      .c-block__side-bg--center & {
        margin: 0 auto;
      }
    }

    @include bp-large('max') {
      &--left {
        justify-content: flex-start;
      }

      &--right {
        justify-content: flex-end;
      }

      &-center {
        justify-content: center;
      }
    }

    &--mobile {
      @include bp-large('max') {
        .c-block__side-bg-image {
          position: relative;
          display: block;
          right: auto !important;
          left: auto !important;
          top: auto !important;
          bottom: auto !important;
        }
      }

      @include bp-large('max') {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        //justify-content: center;

        &-center {
          justify-content: center;
        }

        &-hide {
          display: none;
        }

        &-below {
          order: 9;
          margin: rem_calc(-30 0 -20);

          .c-block--floating-header & {
            @include bp-medium {
              margin-bottom: rem_calc(-50);
            }
          }
        }

        &-above {
          margin: rem_calc(-20 0 0);

          .c-block--floating-header & {
            @include bp-medium {
              margin-top: rem_calc(0);
            }
          }
        }
      }
    }
  }

  &__inner {
    max-width: $global-max-width;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    position: relative;
    z-index: 3;
    width: 100%;
    margin: 0 auto;
    @extend %columns-styles;
    min-height: 100px;
    padding: rem_calc(13 0);

    .c-blocks--has-sidebar & {
      padding-top: rem_calc(5);
      padding-bottom: rem_calc(5);
    }

    @include bp-custom($global-max-width) {
      @if($global-max-width > ($bp-xxlarge - 1)) {
        max-width: $page-blocks-width;
        padding: rem_calc(13 23);
      }
    }


    @include bp-xxlarge {
      @if($global-max-width <= ($bp-xxlarge - 1)) {
        max-width: $page-blocks-width;
        padding: rem_calc(13 23);
      }

      .c-blocks__main--has-sidebar & {
        padding: rem_calc(13 0);
      }
    }

    @include bp-xlarge('max') {
      .c-block--bg-mobile & {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &--full {
      padding: rem_calc(0);
      width: 100%;
      max-width: rem_calc(2048);

      .c-block--type-slider &,
      .c-block--type-gallery &,
      .c-block--type-columns &,
      .c-block--type-map & {
        max-width: 100%;
      }
    }

    &--align-left {
      @include bp-xlarge-medium {
        justify-content: flex-start;
      }
    }

    &--align-right {
      @include bp-xlarge-medium {
        justify-content: flex-end;
      }
    }

    &--overlap {
      z-index: 9;
      transform: translateY(-50%);
      margin-top: rem_calc(-10);

      @include bp-large('max') {
        transform: none !important;
        margin-top: 0;
        margin-bottom: 0 !important;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 3;
    padding: rem_calc(13);
    @extend %columns-styles;

    &-inner {
      @extend %columns-styles;
      padding-bottom: rem_calc(10);

      &--padding {
        padding: rem_calc(40 60);

        @include bp-xlarge('max') {
          padding: rem_calc(30 50);
        }

        @include bp-large('max') {
          padding: rem_calc(20 30);
        }

        @include bp-medium('max') {
          padding: rem_calc(13);
        }
      }
    }

    .c-block__inner--full & {
      padding: rem_calc(13 0);
    }

    .c-block--type-slider & {
      padding: 0;

      .c-block__content-inner {
        padding-bottom: 0;
      }
    }

    @include bp-xlarge('max') {
      .c-block--bg-mobile & {
        padding-top: 0;
      }
    }
  }

  &--w-75 .c-block__inner,
  &__content--w-75 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.75;
      width: 75%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.75;
    }
  }

  &--w-66 .c-block__inner,
  &__content--w-66 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.66;
      width: 66%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.66;
    }
  }

  &--w-50 {
    width: 50%;
    max-width: 50%;

    @include bp-large('max') {
      width: 100%;
      max-width: 100%;
    }
  }

  &__content--w-50 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.75;
      width: 75%;
    }

    @include bp-xlarge-medium {
      max-width: $global-max-width * 0.5;
      width: 50%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.5;
    }
  }
}

@for $i from 5 through 8 {
  div[class*='c-block--h-fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
    @include bp-xxlarge('max') {
      @if( $i >= 7) {
        min-height: rem_calc(600) !important;
      }
    }

    @include bp-large('max') {
      min-height: rem_calc(500) !important;
    }

    @include bp-medium('max') {
      min-height: rem_calc(400) !important;
    }
  }
}

.c-page__content-wrapper {
  position: relative;

  @for $i from 1 through 10 {
    $value: $i * 5;
    $space: $value / 2;
    $spaceHalf: $space/2;
    $spaceQuater: $space/4;

    &.c-page__content-wrapper--h-#{$value} {
      &.c-page__content-wrapper--dynamic {
        @if( $value <= 20) {
          left: - rem_calc($spaceHalf);
          width: calc(100% + #{$spaceHalf * 2}px);
        }
        @else if( $value <= 10) {
          left: - rem_calc($space);
          width: calc(100% + #{$space * 2}px);
        }
        @else {
          left: - rem_calc($spaceQuater);
          width: calc(100% + #{$spaceQuater * 2}px);
        }

        @include bp-xlarge {
          @if( $value <= 10) {
            left: - rem_calc($space);
            width: calc(100% + #{$space * 2}px);
          }
          @else {
            left: - rem_calc($spaceHalf);
            width: calc(100% + #{$spaceHalf * 2}px);
          }
        }

        @include bp-xlarge-medium {
          left: - rem_calc($space);
          width: calc(100% + #{$space * 2}px);
        }
      }

      .c-block__gallery-item,
      .c-block__columns-column,
      .c-block__columns-btn,
      .c-block__boxes--feature .c-block__boxes-box,
      .c-block__boxes--feature .c-block__boxes-btn {
        @if( $value > 10 and $value <= 20) {
          padding-left: rem_calc($spaceHalf);
          padding-right: rem_calc($spaceHalf);
        }
        @else if( $value <= 10) {
          padding-left: rem_calc($space);
          padding-right: rem_calc($space);
        }
        @else {
          padding-left: rem_calc($spaceQuater);
          padding-right: rem_calc($spaceQuater);
        }

        @include bp-xlarge {
          @if( $value <= 10) {
            padding-left: rem_calc($space);
            padding-right: rem_calc($space);
          }
          @else {
            padding-left: rem_calc($spaceHalf);
            padding-right: rem_calc($spaceHalf);
          }
        }

        @include bp-xlarge-medium {
          padding-left: rem_calc($space);
          padding-right: rem_calc($space);
        }
      }
    }

    &.c-page__content-wrapper--v-#{$value} {
      .c-block__gallery-item,
      .c-block__columns-column,
      .c-block__boxes--feature .c-block__boxes-box {
        @if( $value > 10 and $value <= 20) {
          padding-top: rem_calc($spaceHalf);
          padding-bottom: rem_calc($spaceHalf);
        }
        @else if( $value <= 10) {
          padding-top: rem_calc($space);
          padding-bottom: rem_calc($space);
        }
        @else {
          padding-top: rem_calc($spaceQuater);
          padding-bottom: rem_calc($spaceQuater);
        }

        @include bp-xlarge {
          @if( $value <= 10) {
            padding-top: rem_calc($space);
            padding-bottom: rem_calc($space);
          }
          @else {
            padding-top: rem_calc($spaceHalf);
            padding-bottom: rem_calc($spaceHalf);
          }
        }

        @include bp-xlarge-medium {
          padding-top: rem_calc($space);
          padding-bottom: rem_calc($space);
        }
      }
    }
  }
}

.owl-carousel .owl-item {
  -ms-transform: translateZ(0); /* stylelint-disable-line property-no-vendor-prefix */
  transform: translateZ(0);
}

.owl-nav {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 100%;

  .icon {
    width: 1.5rem;
    height: 3rem;
    padding-top: rem_calc(5);
    fill: $color-white;

    @include bp-small-medium {
      width: 2rem;
      height: 3.5rem;
    }

    @include bp-medium {
      width: 2.5rem;
      height: 4rem;
      padding-top: rem_calc(8);
    }

    .c-block__columns-arrows--dark &,
    .c-block__slider-arrows--dark & {
      fill: $color-theme;
    }
  }

  .owl-prev,
  .owl-next {
    position: absolute;
    top: -2rem;
    transition: $global-transition;
  }

  .owl-prev {
    left: rem_calc(10);

    @include bp-small-medium {
      left: rem_calc(20);
    }

    @include bp-medium {
      left: rem_calc(30);
    }
  }

  .owl-prev:hover {
    left: rem_calc(5);

    @include bp-small-medium {
      left: rem_calc(15);
    }

    @include bp-medium {
      left: rem_calc(25);
    }
  }

  .owl-next {
    right: rem_calc(10);

    @include bp-small-medium {
      right: rem_calc(20);
    }

    @include bp-medium {
      right: rem_calc(30);
    }
  }

  .owl-next:hover {
    right: rem_calc(5);

    @include bp-small-medium {
      right: rem_calc(15);
    }

    @include bp-medium {
      right: rem_calc(25);
    }
  }
}

.owl-dots {
  position: absolute;
  bottom: rem_calc(5);
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;

  .c-block__gallery & {
    //bottom: - rem_calc(30);
  }

  .owl-dot {
    width: rem_calc(16);
    height: rem_calc(16);
    display: inline-block;
    margin: 0 rem_calc(3);
    background-color: $color-grey-lighter;
    border: rem_calc(1) solid $color-theme-dark;

    .c-block__slider-dots--dark &,
    .c-block__gallery-dots--dark &,
    .c-block__columns-dots--dark & {
      background-color: $color-theme;
      border-color: $color-theme-dark;
    }

    .c-block__slider-dots--circle &,
    .c-block__gallery-dots--circle &,
    .c-block__columns-dots--circle & {
      border-radius: 50%;
    }
  }

  .owl-dot.active {
    width: rem_calc(16);
    height: rem_calc(16);
    background-color: $color-theme;
    border-color: $color-theme-dark;

    .c-block__slider-dots--dark &,
    .c-block__gallery-dots--dark &,
    .c-block__columns-dots--dark & {
      background-color: $color-white;
      border: rem_calc(1) solid $color-theme-dark;
    }
  }
}

.c-block__slider {
  &--full,
  &--full &-slide {
    height: 100vh;
    min-height: rem_calc(400);

    @include bp-medium {
      min-height: rem_calc(500);
    }

    @include bp-large {
      min-height: rem_calc(600);
    }

    @include bp-xxlarge {
      min-height: rem_calc(700);
    }
  }

  @for $i from 5 through 10 {
    div[class*='c-block__slider-slide--fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
      @media (max-width: $bp-large) {
        min-height: rem_calc(500) !important;
      }

      @media (max-width: $bp-medium) {
        min-height: rem_calc(400) !important;
      }
    }

    .item-video[class*='c-block__slider-slide--fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
      @media (max-width: $bp-large) {
        height: rem_calc(500) !important;
      }

      @media (max-width: $bp-medium) {
        height: rem_calc(400) !important;
      }
    }
  }

  &-spinner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    display: block;
    width: rem_calc(60);
    height: rem_calc(60);
    z-index: 3;

    img {
      width: rem_calc(60);
      height: rem_calc(60);
    }
  }

  &-slide {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    padding: rem_calc(40 0);
    background-repeat: no-repeat;
    background-size: cover;

    &.item-video {
      display: block;
      padding: rem_calc(0);
    }

    .c-block__slider--auto:not(.c-block__slider--carousel) & {
      min-height: rem_calc(400);

      @include bp-medium {
        min-height: rem_calc(500);

        .c-blocks--has-sidebar &,
        .c-page__content-shortcode & {
          min-height: rem_calc(450);
        }
      }

      @include bp-large {
        min-height: rem_calc(600);

        .c-blocks--has-sidebar &,
        .c-page__content-shortcode & {
          min-height: rem_calc(480);
        }
      }

      @include bp-xxlarge {
        min-height: rem_calc(700);

        .c-blocks--has-sidebar &,
        .c-page__content-shortcode & {
          min-height: rem_calc(500);
        }
      }
    }

    &-bg--top-left {
      background-position: top left;
    }

    &-bg--top-right {
      background-position: top right;
    }

    &-bg--center {
      background-position: center center;
    }

    &-bg--btm-left {
      background-position: bottom left;
    }

    &-bg--btm-right {
      background-position: bottom right;
    }

    &-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &--image {
        background-repeat: no-repeat;
      }
    }

    &-image-link {
      position: relative;
      width: 100%;
      height: 100%;
      display: block;
    }

    &-caption {
      display: flex;
      height: auto;
      width: 100%;
      flex: 0 1 100%;
      justify-content: center;
      padding: 0 rem_calc(45);
      z-index: 2;

      &--top {
        align-self: flex-start;

        .c-block__slider-slide--full-height-caption & {
          align-self: auto;
        }
      }

      &--bottom {
        align-self: flex-end;

        .c-block__slider-slide--full-height-caption & {
          align-self: auto;
        }
      }

      @include bp-small-medium {
        padding: 0 rem_calc(80);
      }

      @include bp-medium {
        padding: 0 rem_calc(100);
      }

      &-container {
        display: flex;
        justify-content: center;
        max-width: $global-max-width;
        width: 100%;
        margin: 0 auto;
      }

      &--left {
        .c-block__slider-slide-caption-container {
          justify-content: flex-start;
        }
      }

      &--right {
        .c-block__slider-slide-caption-container {
          justify-content: flex-end;
        }
      }

      &-inner {
        padding: rem_calc(10);

        .c-block__slider--full & {
          max-width: $global-max-width - rem_calc(26);
        }
      }

      &--50 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }

        @include bp-large {
          width: 50%;

          .c-blocks--has-sidebar & {
            width: 75%;
          }
        }
      }

      &--66 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }

        @include bp-large {
          width: 66%;

          .c-blocks--has-sidebar & {
            width: 75%;
          }
        }
      }

      &--75 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }
      }
    }

    &--full-height-caption {
      align-items: stretch;
      justify-content: stretch;
      padding: 0;

      .c-block__slider-slide-caption-inner {
        display: flex;
        align-items: center;
        padding: rem_calc(40 10);
      }

      .c-block__slider-slide-caption--top {
        .c-block__slider-slide-caption-inner {
          align-items: flex-start;
        }
      }

      .c-block__slider-slide-caption--bottom {
        .c-block__slider-slide-caption-inner {
          align-items: flex-end;
        }
      }
    }

    &-video {
      position: relative;
      height: 100%;
    }

    &-video--mp4 {
      position: relative;
      height: 100%;
      background-color: $color-black;

      video {
        display: block;
        height: 100%;
        width: auto;
        max-width: 100%;
        margin: auto;
        background-size: cover;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
      }

      @include video_play_btn();
    }
  }

  .owl-dots {
    bottom: rem_calc(40);

    @include bp-medium {
      bottom: rem_calc(50);
    }
  }

  &-arrows--hover {
    .owl-nav {
      opacity: 0;
      transition: $global-transition;
    }

    &:hover {
      .owl-nav {
        opacity: 1;
      }
    }
  }

  &--carousel {
    padding: rem_calc(20 35);

    @include bp-medium {
      padding: rem_calc(20 50);
    }

    &.c-block__slider--no-arrows {
      padding-left: 0;
      padding-right: 0;
    }

    .c-block--full & {
      padding-top: rem_calc(30);
      padding-bottom: rem_calc(30);
    }

    &.c-block__slider--auto {
      $maxH: 330;
      @for $i from 2 through 8 {
        &.c-block__slider-items--#{$i} .c-block__slider-slide {
          $j: $i - 2;
          $h1: $maxH - ($j * 30);
          $h2: $maxH - ($j * 45);

          height: rem_calc($h1);

          .c-blocks--has-sidebar & {
            @include bp-large {
              height: rem_calc($h2);
            }
          }
        }
      }
    }

    .owl-dots {
      bottom: rem_calc(-10);

      .c-block--full & {
        bottom: rem_calc(0);
      }
    }

    &.c-block__slider--full {
      padding: rem_calc(0 50);

      .owl-dots {
        bottom: rem_calc(40);
      }
    }

    .owl-prev {
      left: rem_calc(-5);

      @include bp-medium {
        left: rem_calc(5);
      }
    }

    .owl-prev:hover {
      left: rem_calc(-10);

      @include bp-medium {
        left: rem_calc(0);
      }
    }

    .owl-next {
      right: rem_calc(-5);

      @include bp-medium {
        right: rem_calc(5);
      }
    }

    .owl-next:hover {
      right: rem_calc(-10);

      @include bp-medium {
        right: rem_calc(0);
      }
    }
  }
}

.c-block__gallery {
  &--has-dots {
    padding-bottom: 40px;
  }

  @include bp-medium {
    .owl-stage-outer {
      padding-top: rem_calc(50);
      padding-bottom: rem_calc(50);
      margin-top: rem_calc(-50);
      margin-bottom: rem_calc(-50);
    }

    &--cols-3 .owl-stage-outer {
      padding-top: rem_calc(60);
      padding-bottom: rem_calc(60);
      margin-top: rem_calc(-60);
      margin-bottom: rem_calc(-60);
    }

    &--cols-2 .owl-stage-outer {
      padding-top: rem_calc(70);
      padding-bottom: rem_calc(70);
      margin-top: rem_calc(-70);
      margin-bottom: rem_calc(-70);
    }
  }

  &-page {
    display: flex;
    flex-flow: row wrap;

    .c-block__gallery--masonry & {
      opacity: 0;
    }
  }

  &-item {
    z-index: 1;
    transition: $global-transition;

    &:hover {
      z-index: 2;
    }

    &--right-edge {
      .c-block__gallery-item-inner {
        transform-origin: right center;

        &:hover {
          box-shadow: rem_calc(-10) 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0.8);
        }
      }
    }

    &--left-edge {
      .c-block__gallery-item-inner {
        transform-origin: left center;

        &:hover {
          box-shadow: rem_calc(10) 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0.8);
        }
      }
    }

    $t: 2.5;
    @for $i from 1 through 6 {
      $n: $i - 1;
      $s: 2 + ($t - $n*0.5);

      .c-block__gallery--cols-#{$i} & {
        width: ( (100) / $i) * 1%;

        @include bp-xlarge-medium('max') {
          @if $i >= 4 {
            width: 25%;
          }
        }

        @include bp-large('max') {
          @if $i >= 3 {
            width: 33.33333%;
          }
        }

        @include bp-medium('max') {
          @if $i >= 2 {
            width: 50%;
          }
        }

        @include bp-small('max') {
          width: 100%;
        }

        @include bp-small {
          // @if $i >= 2 {
          //   width: 50%;
          // }
          // @else {
          //   width: ( (100) / $i) * 1%;
          // }
        }

        @include bp-medium {
          // @if $i >= 3 {
          //   width: 33.33333%;
          // }
          // @else {
          //   width: ( (100) / $i) * 1%;
          // }
        }

        // @media (min-width: $bp-medium) and (max-width: $bp-large) {
        //   @if $i >= 3 {
        //     &:nth-child(3n+1) {
        //       .c-block__gallery-item-inner {
        //         transform-origin: left center;

        //         &:hover {
        //           box-shadow: rem_calc(10) 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0.8);
        //         }
        //       }
        //     }

        //     &:nth-child(3n+3) {
        //       .c-block__gallery-item-inner {
        //         transform-origin: right center;

        //         &:hover {
        //           box-shadow: rem_calc(-10) 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0.8);
        //         }
        //       }
        //     }
        //   }
        //   // @else {
        //   //   width: ( (100) / $i) * 1%;
        //   // }
        // }

        // @media (min-width: $bp-large) and (max-width: $bp-xlarge-medium) {
        //   @if $i >= 4 {
        //     &:nth-child(4n+1) {
        //       .c-block__gallery-item-inner {
        //         transform-origin: left center;

        //         &:hover {
        //           box-shadow: rem_calc(10) 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0.8);
        //         }
        //       }
        //     }

        //     &:nth-child(4n+4) {
        //       .c-block__gallery-item-inner {
        //         transform-origin: right center;

        //         &:hover {
        //           box-shadow: rem_calc(-10) 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0.8);
        //         }
        //       }
        //     }
        //   }
        //   // @else {
        //   //   width: ( (100) / $i) * 1%;
        //   // }
        // }

        // @include bp-xlarge-medium {
        //   //width: ( (100) / $i) * 1%;

        //   &:nth-child(#{$i}n+1) {
        //     .c-block__gallery-item-inner {
        //       transform-origin: left center;

        //       &:hover {
        //         box-shadow: rem_calc(10) 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0.8);
        //       }
        //     }
        //   }

        //   &:nth-child(#{$i}n+#{$i}) {
        //     .c-block__gallery-item-inner {
        //       transform-origin: right center;

        //       &:hover {
        //         box-shadow: rem_calc(-10) 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0.8);
        //       }
        //     }
        //   }
        // }

        .c-block__gallery-item-overlay {
          .icon {
            width: 3rem;
            height: 3rem;

            .c-block--w-50 &,
            .c-block__content--w-50 & {
              width: 1.5rem;
              height: 1.5rem;
            }

            @include bp-large {
              width: 2.5rem;
              height: 2.5rem;

              .c-block--w-50 &,
              .c-block__content--w-50 & {
                width: 1.3rem;
                height: 1.3rem;
              }
            }

            @include bp-xlarge-medium {
              width: #{$s}rem;
              height: #{$s}rem;

              .c-block--w-50 &,
              .c-block__content--w-50 & {
                width: #{$s / 2}rem;
                height: #{$s / 2}rem;
              }
            }
          }
        }
      }
    }

    &-inner {
      display: block;
      position: relative;
      transition: $global-transition;
      transform: scale(1);
      box-shadow: 0 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0);

      .c-block__gallery-item:hover & {
        transform: scale(1.15);
        box-shadow: 0 0 rem_calc(40) rem_calc(-5) rgba(0, 0, 0, 0.8);

        .c-block__gallery--cols-2 & {
          transform: scale(1.1);
        }

        @include bp-large('max') {
          transform: scale(1.1);
        }

        @include bp-medium('max') {
          transform: none;
          box-shadow: none;
        }

        .c-block__gallery--cols-1 & {
          transform: none;
        }
      }
    }

    &-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: $global-transition;
      opacity: 0;

      .icon {
        // width: 2rem;
        // height: 2rem;
      }

      .c-block__gallery-item:hover & {
        opacity: 1;
      }
    }
  }
}

.c-block__columns {
  display: flex;
  flex-flow: row wrap;

  &-column {
    margin: rem_calc(0);

    @for $i from 1 through 4 {
      .c-block__columns--#{$i} & {
        @extend %columns-styles;
      }

      .c-block__columns--#{$i} & {
        width: 100%;

        @include bp-medium {
          @if( $i != 3 ) {
            width: 50%;
          }
          @else {
            width: ( 100 / $i) * 1%;
          }
        }

        @include bp-large {
          width: ( 100 / $i) * 1%;
        }
      }

      .owl-carousel & {
        width: 100%;
      }
    }

    // @for $i from 1 through 10 {
    //   &--rows-#{$i} {
    //     .c-block__columns-column-inner--equal {
    //       height: (( 100 / $i ) - 1) * 1%;
    //     }
    //   }
    // }

    &-inner {
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      overflow: hidden;
      padding-bottom: rem_calc(10);

      .c-block__columns--4 & {
        padding-bottom: rem_calc(5);
      }

      &--equal {
        //height: 100%;
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &-header {
      position: relative;
      z-index: 3;
      padding: rem_calc(0 0 10);

      * {
        margin: 0;
      }
    }

    .c-book__cover {
      width: 35%;
      max-width: rem_calc(170);
    }

    &-title,
    &-image,
    &-text,
    &-meta {
      position: relative;
      display: block;
      margin: rem_calc(0);
      z-index: 3;
      //transition: $global-transition;
    }

    &-image {
      padding: rem_calc(0 0 5);
      text-align: center;

      .c-block__columns--custom & {
        padding: 0;
      }
    }

    &-header,
    &-title,
    &-btn,
    &-text,
    &-meta {
      padding: rem_calc(10 0);

      .c-block__columns-column-inner--has-bg & {
        padding-left: rem_calc(20);
        padding-right: rem_calc(20);

        .c-block__columns--4 & {
          padding-left: rem_calc(15);
          padding-right: rem_calc(15);
        }
      }
    }

    &-title {
      padding-top: rem_calc(20);
      color: $color-theme;

      .c-book__info & {
        padding-top: rem_calc(10);
      }

      * {
        margin: 0;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      img {
        margin: 0 !important;
      }
    }

    &-meta {
      font-size: rem_calc(15);
    }

    &-video {
      margin: auto;
      @include video_box();
    }

    .c-text--bg {
      position: relative;
      padding-left: 0;
      padding-right: 0;

      * {
        position: relative;
        z-index: 3;
      }

      &::before {
        position: absolute;
        top: 0;
        right: rem_calc(-20);
        bottom: 0;
        left: rem_calc(-20);
        content: '';
        display: block;
        background-color: inherit;
      }
    }

    &-inner-post--image-overlay {
      position: absolute;
      z-index: 5;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    &-inner-post--animation {
      position: relative;
      overflow: hidden;
      margin-bottom: rem-calc(20);

      .c-block__columns-column-title,
      .c-block__columns-column-text,
      .c-block__columns-column-btn {
        position: absolute;
        z-index: 6;
        width: 100%;
        left: 0;
        right: 0;
      }

      .c-block__columns-column-title {
        bottom: 20px;

        &--light {
          color: $color-white;
        }
      }

      .c-block__columns-column-text {
        top: 100%;

        &--light {
          color: $color-white;
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      }

      .c-block__columns-column-btn {
        bottom: -100%;

        &--light .c-btn--arrow-secondary,
        &--light .c-btn--arrow-primary {
          color: $color-white;
        }

        &--light .c-icon {
          fill: $color-white;
        }
      }

      .c-block__columns-column-image {
        position: relative;
        padding: 0;
      }
    }
  }

  &-btn {
    width: 100%;
    padding: rem_calc(20 0);

    a {
      margin: rem_calc(0 10);
    }

    @include bp-medium('max') {
      text-align: center !important;
    }
  }

  &-mixed-btn {
    &--ajax {
      display: none;
    }
  }

  &--has-nav {
    padding-left: rem_calc(35);
    padding-right: rem_calc(35);

    & + .c-block__columns-btn a {
      margin: rem_calc(0 35);
    }

    @include bp-small-medium {
      padding-left: rem_calc(40);
      padding-right: rem_calc(40);

      & + .c-block__columns-btn a {
        margin: rem_calc(0 40);
      }
    }

    @include bp-medium {
      padding-left: rem_calc(60);
      padding-right: rem_calc(60);

      & + .c-block__columns-btn a {
        margin: rem_calc(0 60);
      }
    }
  }

  &--has-dots {
    padding-bottom: rem_calc(40);
  }

  &.owl-carousel {
    position: relative;

    .owl-stage,
    .owl-item {
      display: flex;
      align-content: stretch;
    }

    &.owl-show-nav {
      .owl-nav {
        display: block !important;
      }
    }

    .owl-nav {
      .owl-prev {
        left: rem_calc(5);

        @include bp-medium {
          left: rem_calc(20);
        }
      }

      .owl-prev:hover {
        left: rem_calc(2);

        @include bp-medium {
          left: rem_calc(16);
        }
      }

      .owl-next {
        right: rem_calc(5);

        @include bp-medium {
          right: rem_calc(20);
        }
      }

      .owl-next:hover {
        right: rem_calc(2);

        @include bp-medium {
          right: rem_calc(16);
        }
      }
    }
  }
}

.c-block__boxes {
  display: flex;
  flex-flow: row wrap;

  &--feature {
    justify-content: flex-start;
  }

  &-box {
    .c-block__boxes--square & {
      padding: rem_calc(20);

      @include bp-xlarge-medium {
        padding: rem_calc(30);
      }
    }

    @for $i from 1 through 4 {
      .c-block__boxes--#{$i} & {
        @extend %columns-styles;
        width: 100%;

        @include bp-custom(860) {
          @if($i>1) {
            width: 50%;
          }
        }

        @include bp-xlarge {
          @if($i>2) {
            width: 33.33333%;

            .c-blocks--has-sidebar & {
              @if($i==3) {
                width: 50%;
              }
            }
          }
        }

        @include bp-custom(1360) {
          width: ( 100 / $i) * 1%;

          .c-blocks--has-sidebar & {
            width: ( 100 / $i) * 1%;
          }
        }
      }

      .c-block__boxes--square.c-block__boxes--#{$i} & {
        @include bp-large {
          @if($i==4) {
            width: ( 100 / $i) * 1%;
          }
        }
      }

      .c-block__boxes--feature.c-block__boxes--#{$i} & {
        text-align: center;

        @if( $i > 1 ) {
          &:nth-child(#{$i}n+1) {
            @include bp-xlarge {
              text-align: left;
            }
          }

          &:nth-child(#{$i}n+#{$i}) {
            @include bp-xlarge {
              text-align: right;
            }
          }
        }
      }
    }

    &-inner {
      width: 100%;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      box-sizing: border-box;
      transition: $global-transition;

      .c-block__boxes--square & {
        max-width: rem_calc(250);
        margin: auto;

        @include bp-large {
          max-width: rem_calc(300);
        }

        &:before {
          content: '';
          display: block;
          padding-top: 100%; /* ratio of 1:1 */ /* stylelint-disable-line comment-whitespace-inside */
        }

        &--small {
          max-width: rem_calc(215);
        }

        &--medium {
          max-width: rem_calc(220);

          @include bp-large {
            max-width: rem_calc(250);
          }
        }
      }

      .c-block__boxes--feature & {
        height: 100%;
        max-width: rem_calc(420);
        display: inline-block;
        text-align: left;
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: $global-transition;
    }

    &-content {
      z-index: 3;

      .c-block__boxes--square & {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        width: 100%;
        padding: rem_calc(10);
        text-align: center;
        font-size: rem_calc(24);
      }

      .c-block__boxes--feature & {
        position: relative;
        overflow: hidden;
        height: 100%;
      }
    }

    &-icon,
    &-header,
    &-text {
      padding: rem_calc(10 20 0);

      .c-block__boxes-box-bg--none & {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      height: rem_calc(43);
      margin-bottom: rem_calc(5);

      img {
        max-height: 100%;
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }

    &--animate {
      .c-block__boxes-box-header {
        padding: rem_calc(10 20 0);
      }

      .c-block__boxes-box-text {
        padding: rem_calc(0 20 10);
      }
    }
  }

  &-btn {
    width: 100%;
    padding-top: rem_calc(15);
    padding-bottom: rem_calc(15);

    @include bp-xlarge('max') {
      text-align: center !important;
    }

    .c-page__block--overlap & {
      display: none;
    }
  }
}

.c-block__tabs {
  &-nav {
    margin-bottom: 0;
    padding: 0 rem_calc(30);

    li {
      margin-left: - rem_calc(5);
      margin-bottom: 0;
      border: rem_calc(1) solid $color-grey-lighter;

      .c-block__tabs--dark & {
        border-color: $color-grey-dark;
      }
    }

    .active {
      a {
        background-color: $color-grey-lighter;

        .c-block__tabs--dark & {
          background-color: $color-grey-dark;
          color: $color-white;
        }
      }
    }

    a {
      display: block;
      padding: rem_calc(20);
      color: inherit;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      background-color: $color-grey-lighter;

      .c-block__tabs--dark & {
        background-color: $color-grey;
        color: $color-white;
      }

      &:hover {
        background-color: $color-grey-lighter;

        .c-block__tabs--dark & {
          background-color: $color-grey-dark;
          color: $color-white;
        }
      }
    }
  }

  &-content {
    padding: rem_calc(20) rem_calc(30) rem_calc(5);
    background-color: $color-grey-lighter;

    .c-block__tabs--dark & {
      background-color: $color-grey-dark;
      color: $color-white;
    }
  }

  &-tab,
  &-accordion-content {
    display: none;

    &.in {
      display: block;
    }
  }

  &-accordion {
    &-header {
      margin: 0;

      a {
        display: block;
        padding: rem_calc(15);
        border-bottom: rem_calc(1) solid $color-grey-light;
        background-color: $color-white;
        color: inherit;
        text-decoration: none;

        .c-block__tabs--dark & {
          background-color: $color-grey;
          color: $color-white;
        }
      }

      .c-arrow-icon {
        margin-top: rem_calc(5);
        display: block;
        width: 1rem;
        height: 1rem;
        fill: $color-grey-dark;

        .c-block__tabs--dark & {
          fill: $color-grey-lighter;
        }

        &.c-arrow-up {
          display: none;
        }
      }

      &.active {
        a {
          border-bottom: 1px solid $color-white;
          background-color: $color-white;

          .c-block__tabs--dark & {
            background-color: $color-grey-dark;
            color: $color-white;
          }
        }

        .c-arrow-down {
          display: none;
        }

        .c-arrow-up {
          display: block;
        }
      }
    }

    &-content {
      padding: rem_calc(15 20 5);
      background-color: $color-grey-lighter;

      .c-block__tabs--dark & {
        background-color: $color-grey-dark;
        color: $color-white;
      }
    }
  }
}

.c-block__testimonials {
  position: relative;
  max-width: calc(100% - 70px);
  margin: auto;
  display: none;

  .owl-stage-outer {
    margin: auto;
  }

  .owl-stage,
  .owl-item {
    display: flex;
  }

  .owl-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: none;
    opacity: 1;

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: rem_calc(25);
      height: 3rem;
      padding: 0;
      margin-left: rem_calc(4);
      fill: #fff;
    }

    .owl-next .icon {
      margin-left: rem_calc(9);
    }

    .owl-prev,
    .owl-next {
      position: absolute;
      top: 0;
      bottom: 0;
      width: rem_calc(40);
      height: 100%;
      background-color: $color-grey-lighter;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $color-grey-light;
      }

      &.invisible,
      &.invisible:hover {
        background-color: $color-grey-lighter;

        .icon {
          display: none;
        }
      }
    }

    .owl-prev,
    .owl-prev:hover {
      left: - rem_calc(35);
    }

    .owl-next,
    .owl-next:hover {
      right: - rem_calc(35);
    }
  }

  &-item {
    width: 100%;
    position: relative;
    padding: rem_calc(50 80 30 85);
    background-color: $color-grey-lighter;

    .icon-quote {
      position: absolute;
      top: rem_calc(35);
      left: rem_calc(20);
      width: 3rem;
      height: 3rem;
      fill: #d6d6d6;
    }
  }

  &-meta {
    margin-top: rem_calc(25);

    img {
      float: left;
      width: rem_calc(60) !important; // to override default owl carousel 100% width
    }
  }

  &-info {
    overflow: hidden;

    p {
      margin: 0;
      padding-left: rem_calc(10);
      font-size: rem_calc(14);
    }
  }
}

.c-block__map {
  .js-google-map {
    .c-block--h-full & {
      height: 100vh !important;
    }
  }

  .c-block:not(.c-block--h-full) & {
    @for $i from 3 through 8 {
      div[class*='map--h-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
        @include bp-xlarge-medium('max') {
          @if( $i > 5 ) {
            height: 600px !important;
          }
        }

        @include bp-xlarge('max') {
          @if( $i > 4 ) {
            height: 400px !important;
          }
        }

        @include bp-large('max') {
          @if( $i > 3 ) {
            height: 300px !important;
          }
        }
      }
    }
  }
}

.c-block__files {
  &-entry {
    padding: rem_calc(15 0);
  }

  &-file {
    img {
      display: inline-block;
      width: rem_calc(20);
      margin-right: rem_calc(10);
    }
  }
}

.c-block__call-to-action {
  &-content {
    width: 100%;
    box-sizing: border-box;
  }

  ul, ol {
    overflow: hidden;
    padding-left: rem_calc(20);
    margin-left: rem_calc(20);
  }
}

.c-block__social-media {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &--1 {
    justify-content: center;
  }

  &--2 {
    justify-content: space-around;
  }

  &-widget {
    text-align: center;
    padding: rem_calc(10);
    margin: rem_calc(15 0);

    @for $i from 1 through 4 {
      &--#{$i} {
        @extend %columns-styles;
      }

      &--#{$i} {
        width: 100%;

        @include bp-custom(640) {
          width: 50%;
        }

        @include bp-xlarge {
          width: ( 100 / $i) * 1%;
        }
      }
    }

    &-box {
      display: inline-block;
      width: 100%;
      overflow: hidden;
    }

    &-video {
      position: relative;
      overflow: hidden;
      height: 0;
      padding-bottom: 56.25%;
      padding-top: rem_calc(30);

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100%;
      }
    }

    &-instafeed {
      display: flex;
      flex-flow: row wrap;
      margin: auto;

      $small : 150;
      $medium : 300;
      $large : 600;

      @for $i from 1 through 10 {
        &--#{$i} {
          @extend %columns-styles;
        }

        &--#{$i} {
          a {
            flex-basis: ( 100 / $i) * 1%;
          }
        }

        @at-root &--#{$i}#{&}--small {
          max-width: ($i * $small) + px;
        }

        @at-root &--#{$i}#{&}--medium {
          max-width: ($i * $medium) + px;
        }

        @at-root &--#{$i}#{&}--large {
          max-width: ($i * $large) + px;
        }
      }

      a {
        display: block;
        padding: 0;
        text-decoration: none;
        background-color: $color-grey-lighter;
      }

      span {
        display: block;
        padding: rem_calc(5);
        color: $color-grey;
        font-size: rem_calc(14);
      }
    }
  }
}

.c-block__newsletters-entry {
  padding: rem_calc(10 15 0);
}

.c-block__newsletters-list {
  display: flex;
  flex-flow: row wrap;
  padding: rem_calc(20 0);

  &-item {
    display: flex;
    padding: rem_calc(20 15);

    @for $i from 1 through 4 {
      .c-block__newsletters-list--cols-#{$i} & {
        @extend %columns-styles;
        width: 100%;

        &:nth-child(#{$i}n+2),
        &:nth-child(#{$i}n+3) {
          justify-content: center;
        }

        &:nth-child(#{$i}n+1) {
          justify-content: flex-start;
        }

        &:nth-child(#{$i}n+#{$i}) {
          justify-content: flex-end;

          @if($i==1) {
            justify-content: center;
          }
        }

        @include bp-custom(860) {
          @if($i>1) {
            width: 50%;
            flex-basis: 50%;
          }
        }

        @include bp-xlarge {
          @if($i>2) {
            width: 33.33333%;
            flex-basis: 33.33333%;

            .c-blocks--has-sidebar & {
              @if($i==3) {
                width: 50%;
                flex-basis: 50%;
              }
            }
          }
        }

        @include bp-custom(1360) {
          width: ( 100 / $i) * 1%;
          flex-basis: ( 100 / $i) * 1%;

          .c-blocks--has-sidebar & {
            width: ( 100 / $i) * 1%;
            flex-basis: ( 100 / $i) * 1%;
          }
        }
      }
    }

    &-image {
      display: block;
      margin: rem_calc(5 0);
    }

    &-file {
      padding: rem_calc(15 0 5);

      svg, span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  &-item-inner {
    max-width: rem_calc(410);
    padding: rem_calc(10);
    border: 1px solid $color-grey-lighter;
  }
}

.c-instagram__data {
  z-index: 9999;
  position: fixed;
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  &-inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    width: 100%;
    max-width: rem_calc(500);
    padding: rem_calc(20);
    background-color: $color-white;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  }

  h4 {
    text-align: center;
  }

  strong {
    display: block;
  }

  span {
    display: block;
    word-break: break-all;
  }
}

.c-block__contact-form {
  .cform-container {
    position: relative;
    left: - rem_calc(13);
    width: calc(100% + 26px);
  }
}
